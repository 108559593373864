import React from 'react';

import DocLayout from 'components/DocLayout';

const Unstructured = () => (
  <DocLayout>
    <DocLayout.Title>
      Unstructured Knowledge Graph
    </DocLayout.Title>
    <DocLayout.Body>
      Build and query a knowledge graph from your own
      custom documents in natural language.
    </DocLayout.Body>

    <DocLayout.Header>
      Natural Language Question Answering
    </DocLayout.Header>
    <DocLayout.ComingSoon />
  </DocLayout>
);

export default Unstructured;
