import React from 'react';
import PropTypes from 'prop-types';

import { THEME_DEFAULT_PROPS, THEME_PROP_TYPES } from 'constants/themes';

import globalStyles from 'css/global.module.scss';
import styles from './styles.module.scss';

const Tag = ({
  theme, flavor, accent, className, children, ...rest
}) => (
  <span
    className={[
      globalStyles[`theme-${theme}`],
      globalStyles[`flavor-${flavor}`],
      globalStyles[`accent-${accent}`],
      styles.tag,
      className,
    ].join(' ')}
    {...rest}
  >
    {children}
  </span>
);

Tag.defaultProps = {
  ...THEME_DEFAULT_PROPS,
  className: '',
  children: null,
};

Tag.propTypes = {
  ...THEME_PROP_TYPES,
  className: PropTypes.string,
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Tag;
