import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const DescriptionHelmet = ({ description }) => (
  <Helmet>
    <meta
      name="description"
      content={description}
    />
    <meta
      property="og:description"
      content={description}
    />
    <meta
      name="twitter:description"
      content={description}
    />
  </Helmet>
);

DescriptionHelmet.propTypes = {
  description: PropTypes.string.isRequired,
};

export default DescriptionHelmet;
