import React from 'react';

import BlogPostLayout from 'components/BlogPostLayout';
import Link from 'components/Link';

import { __ } from 'constants/routes';

export const meta = {
  date: new Date('Jun 14 2022'),
  title: 'Brian Kolo Joins Advisory Board Of Executive 1 Holding Company',
  shortDescription: 'NLP technologist and Business Value Strategist to lead growth mission.',
  longDescription: 'Executive 1 Holding Company, LLC (EX1) is pleased to announce that Dr. Brian Kolo will join its Advisory Board this week. Dr. Kolo is the Managing Director of Magnus Partners, Inc. He is a former career executive & technologist with McKinsey Digital Labs, Stroz Friedberg, Opera Solutions, Zifian, and Acxiom Government, specializing in data analytics, AI & data monetization.',
  category: 'company',
  tags: [
    'people',
  ],
};

const imageFolder = `${process.env.PUBLIC_URL}/blog/posts/brian-kolo-joins-board/images`;

const BrianKoloJoinsBoard = () => (
  <BlogPostLayout {...meta}>
    <BlogPostLayout.Body>
      <Link to="https://www.executive1holding.com/">Executive 1 Holding Company, LLC</Link>
      {' '}
      (EX1) is pleased to announce that
      {' '}
      <Link to="https://www.linkedin.com/in/brian-kolo-9785893/">Dr. Brian Kolo</Link>
      {' '}
      will join its Advisory Board this week.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      Dr. Kolo is the Managing Director of
      {' '}
      <Link to="https://magnus.partners/">Magnus Partners, Inc</Link>
      . He is a former
      career executive &amp; technologist with McKinsey Digital Labs,
      Stroz Friedberg, Opera Solutions, Zifian, and Acxiom Government,
      specializing in data analytics, AI &amp; data monetization. For over 23
      years, Dr. Kolo has applied academic-level mathematics to software
      development, creating solutions for Fortune 100/500 companies and
      government agencies to solve real-world problems.
    </BlogPostLayout.Body>

    <BlogPostLayout.Image
      src={`${imageFolder}/briankolo.png`}
      placeholderSrc={`${imageFolder}/briankolo_low.png`}
      alt="Brian Kolo"
      caption="Brian Kolo"
    />

    <BlogPostLayout.Quote
      text="Executive 1 is in an ideal position to grow and I am excited to advise CORAS, HumanTouch, and Plasticity in creating and optimizing business value and leveraging their NLP offerings in new fields and novel ways. As Advisory Board members we work together to support these innovative companies and build strategies for securing key intellectual property patents and software."
      attribution="Brian Kolo"
    />

    <BlogPostLayout.Body>
      EX1 holds and invests in leading consulting and technology solutions
      companies collaborating with the federal government, working at the
      forefront of organizational transformation using artificial intelligence
      (AI), machine learning (ML), and natural language processing. EX1 CEO
      Moe Jafari said, &ldquo;We look to Dr. Kolo&apos;s expertise in AI and
      NLP as well as his business leadership and experience to guide Executive 1
      as we grow our emerging technology portfolio of solutions for Federal
      civilian and defense agencies.&rdquo;
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      Dr. Kolo has a Ph.D. in Computational Physics from the University of
      Virginia and a J.D. from George Mason University. He is also a
      registered patent agent and holds certifications as an ITIL Expert,
      PMP, and PgMP. Dr. Kolo is also a peer-reviewed and seasoned author of
      publications and patents that impact the academic mathematics and
      software community. He is also an antiquarian book and comic book
      collector, golfer, husband, and father.
    </BlogPostLayout.Body>

    <BlogPostLayout.Header>
      About EX1
    </BlogPostLayout.Header>

    <BlogPostLayout.Body>
      EX1 is a private holding firm that invests in AI/ML/NLP and
      software-as-a-service (SaaS). EX1&apos;s current investments in
      {' '}
      <Link to={__.path}>Plasticity Inc.</Link>
      ,
      {' '}
      <Link to="http://www.humantouchllc.com/">HumanTouch, LLC</Link>
      ,
      {' '}
      <Link to="http://www.coras.com/">CORAS</Link>
      {' '}
      (SaaS), and
      {' '}
      <Link to="https://www.docugraph.com/">DocuGraph</Link>
      {' '}
      are positioned to lead in these technologies.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      Consumers, businesses, and the federal Government are all embracing AI
      in the marketplace. According to Gartner, Government and private firms
      are forecasted to spend over $125 billion by 2025 on AI products and
      services. The EX1 family of companies find solutions to complex business
      challenges, with ML, AI and NLP becoming an increasingly significant
      part of that formula. Our strategic solutions and applications bring
      together innovators, experts and data scientists in solving the federal
      Government&apos;s business challenges. EX1&apos;s mission is to provide
      superior human interactions and client support, using service and
      technology that leverages cutting-edge data science applications to work
      smarter. This growing family of companies serves the Department of
      Defense, civilian federal Government agencies and commercial
      organizations with pride. For more information, visit
      {' '}
      <Link to="https://www.executive1holding.com/">https://www.executive1holding.com/</Link>
    </BlogPostLayout.Body>
  </BlogPostLayout>
);

export default BrianKoloJoinsBoard;
