import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { uniqueId, isEmpty } from 'lodash';

import styles from './styles.module.scss';

const SentenceElement = ({
  prefix, text, suffix, type, relationshipType, argumentIndex, tooltipData,
}) => {
  const [hovered, setHovered] = useState(false);
  const randomId = uniqueId('tooltip_');
  const hasTooltip = !isEmpty(tooltipData);

  /**
   * Set the label value
   * @type {String}
   */
  let label = '';
  if (type === 'preposition') {
    label = 'Preposition';
  } else if (type === 'argument') {
    if (argumentIndex > -1) {
      label = `Argument ${argumentIndex}`;
    } else {
      label = 'Argument';
    }
  } else if (type === 'question') {
    label = 'Question';
  } else if (type === 'qualifier') {
    label = 'Qualifier';
  } else if (type === 'subject') {
    label = 'Subject';
  } else if (type === 'predicate') {
    label = 'Predicate';
  } else if (type === 'object') {
    label = 'Object';
  } else if (type === 'qualified-object') {
    label = 'Qualified Object';
  } else if (type === 'relationship') {
    if (relationshipType === 'subject') {
      label = 'Subject Relationship';
    } else if (relationshipType === 'object') {
      label = 'Object Relationship';
    } else if (relationshipType === 'inferred') {
      label = 'Inferred Relationship';
    } else if (relationshipType === 'nested') {
      label = 'Nested Relationship';
    } else if (relationshipType === 'qualified') {
      label = 'Qualified Relationship';
    } else if (relationshipType === 'artificial') {
      label = 'Artificial Relationship';
    } else {
      label = 'Relationship';
    }
  }

  return (
    <>
      <span
        className={[
          styles.element,
          styles[type],
          label ? styles['has-label'] : '',
          hovered ? styles.hovered : '',
        ].join(' ')}
      >
        <div className={styles.text}>
          <span className={styles['text-prefix']}>{prefix}</span>
          <span className={styles['text-body']}>{text}</span>
          <span className={styles['text-suffix']}>{suffix}</span>
        </div>
        {label
          && (
          <div className={styles.label}>
            <span
              className={styles['label-body']}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              {...(hasTooltip && {
                'data-tip': true,
                'data-for': randomId,
              })}
            >
              {label}
            </span>
          </div>
          )}
      </span>

      {/* Tooltip */}
      {hasTooltip
        && (
        <ReactTooltip
          id={randomId}
          place="bottom"
          className={styles.tooltip}
        >
          {Object.keys(tooltipData).map((key, index) => (
            <div
              key={index}
              className={styles['tooltip-data']}
            >
              <span className={styles['tooltip-data-key']}>
                {key}
              </span>
              <span>
                :&nbsp;
              </span>
              <span className={styles['tooltip-data-value']}>
                {Array.isArray(tooltipData[key])
                  ? tooltipData[key].map((item) => (
                    <span key={item}>
                      <br />
                      {item}
                    </span>
                  ))
                  : (tooltipData[key] || 'None')}
              </span>
            </div>
          ))}
        </ReactTooltip>
        )}
    </>
  );
};

SentenceElement.defaultProps = {
  prefix: null,
  text: null,
  suffix: null,
  type: '',
  relationshipType: '',
  argumentIndex: -1,
  tooltipData: {},
};

SentenceElement.propTypes = {
  prefix: PropTypes.node,
  text: PropTypes.node,
  suffix: PropTypes.node,
  type: PropTypes.oneOf([
    '',
    'relationship',
    'preposition',
    'argument',
    'question',
    'qualifier',
    'subject',
    'predicate',
    'object',
    'qualified-object',
  ]),
  relationshipType: PropTypes.oneOf([
    '',
    'subject',
    'object',
    'inferred',
    'nested',
    'qualified',
    'artificial',
  ]),
  argumentIndex: PropTypes.number,
  tooltipData: PropTypes.object,
};

export default SentenceElement;
