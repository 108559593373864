const cleanPhoneNumber = (phone) => phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

export const CONTACT_EMAIL = 'contact@plasticity.ai';
export const SUPPORT_EMAIL = 'support@plasticity.ai';
export const PRESS_EMAIL = 'press@plasticity.ai';
export const SALES_EMAIL = 'sales@plasticity.ai';
export const CAREERS_EMAIL = 'careers@plasticity.ai';
export const OPEN_SOURCE_EMAIL = 'opensource@plasticity.ai';
export const CONTRACTS_EMAIL = 'contracts@plasticity.ai';
export const EX1_PRIVACY_EMAIL = 'privacy@ex1holding.com';
export const EX1_DPO_EMAIL = 'dpo@ex1holding.com';

export const PHONE = '(703) 592-8040';
export const PHONE_CLEAN = cleanPhoneNumber(PHONE);
export const EX1_PHONE = '(703) 910-5090';
export const EX1_PHONE_CLEAN = cleanPhoneNumber(PHONE);

export const ADDRESS = `
Plasticity Inc.\n
7918 Jones Branch Drive, Suite 850\n
McLean, VA 22102
`;
export const EX1_ADDRESS = `
Executive 1 Holding Company, LLC\n
7918 Jones Branch Drive, Suite 800\n
McLean, VA 22102
`;
