import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import {
  __docsSapienCoreTokenization,
  __docsSapienCorePartsOfSpeech,
  __docsSapienCoreLemmatization,
  __docsSapienCoreSyntaxDependencyTrees,
  __docsSapienCoreSentenceGraph,
  __docsSapienCoreNamedEntityRecognition,
  __docsSapienCoreDisambiguation,
  __docsSapienCoreCoreferenceResolution,
  __docsSapienCoreSentimentAnalysis,
  __docsCortexStructuredEntitySearch,
} from 'pages/docs/routes';

import { aaSapienCore } from 'constants/routes';

const Core = () => (
  <DocLayout>
    <DocLayout.Title>
      Core
    </DocLayout.Title>
    <DocLayout.Body>
      Performs various natural language tasks over
      raw text input.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>text</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <DocLayout.InlineCode>String</DocLayout.InlineCode>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>text</DocLayout.InlineCode>
            {' '}
            parameter is the sentence or sentences to analyze.
          </>,
        ],
        [
          <DocLayout.InlineCode>graph</DocLayout.InlineCode>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Controls whether the
            {' '}
            <Link to={__docsSapienCoreSentenceGraph.path}>
              graph of entities and relationships
            </Link>
            {' '}
            is
            returned for each sentence. The
            {' '}
            <DocLayout.InlineCode>graph</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <DocLayout.InlineCode>ner</DocLayout.InlineCode>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Controls whether
            {' '}
            <Link to={__docsSapienCoreNamedEntityRecognition.path}>
              named entity recognition
            </Link>
            {' '}
            is returned for each sentence. The
            {' '}
            <DocLayout.InlineCode>ner</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <DocLayout.InlineCode>pretty</DocLayout.InlineCode>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns the output of various natural language tasks on
      the input text (see below for more information).
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="sapien-core-1"
      url={aaSapienCore.path}
      methods={['GET', 'POST']}
    />

    <DocLayout.Header id={__docsSapienCoreTokenization.hash}>
      Tokenization
    </DocLayout.Header>
    <DocLayout.Body>
      Tokens appear under the
      {' '}
      <DocLayout.InlineCode>tokens</DocLayout.InlineCode>
      {' '}
      key for each sentence
      as the first element in the array.
    </DocLayout.Body>

    <DocLayout.Body>
      Note that multi-word entities like the song &ldquo;Let It Be&rdquo;
      will be tokenized to be treated as a single word. To turn this off,
      set the
      {' '}
      <DocLayout.InlineCode>ner</DocLayout.InlineCode>
      {' '}
      parameter to false. In cases where it
      is ambiguous as to whether multiple words should be interpreted
      as a multi-word entity, &ldquo;alternatives&rdquo; for each sentence
      parse are returned in the response.
    </DocLayout.Body>

    <DocLayout.Header id={__docsSapienCorePartsOfSpeech.hash}>
      Parts of Speech
    </DocLayout.Header>
    <DocLayout.Body>
      Parts of speech tags appear under the
      {' '}
      <DocLayout.InlineCode>tokens</DocLayout.InlineCode>
      {' '}
      key
      for each sentence as the second element in the array.
    </DocLayout.Body>
    <DocLayout.Table
      headers={[
        'Part of Speech Tag',
        'Meaning',
      ]}
      rows={[
        [
          <DocLayout.InlineCode>NN</DocLayout.InlineCode>,
          <>Singular Noun</>,
        ],
        [
          <DocLayout.InlineCode>NNS</DocLayout.InlineCode>,
          <>Plural Noun</>,
        ],
        [
          <DocLayout.InlineCode>POS</DocLayout.InlineCode>,
          <>Possessive Ending</>,
        ],
        [
          <DocLayout.InlineCode>NNP</DocLayout.InlineCode>,
          <>Singular Proper Noun</>,
        ],
        [
          <DocLayout.InlineCode>NNPS</DocLayout.InlineCode>,
          <>Plural Proper Noun</>,
        ],
        [
          <DocLayout.InlineCode>PRP</DocLayout.InlineCode>,
          <>Personal Pronoun</>,
        ],
        [
          <DocLayout.InlineCode>PRPS</DocLayout.InlineCode>,
          <>Personal Pronoun Possessive</>,
        ],
        [
          <DocLayout.InlineCode>VB</DocLayout.InlineCode>,
          <>Verb Base From</>,
        ],
        [
          <DocLayout.InlineCode>VBD</DocLayout.InlineCode>,
          <>Verb Past Tense</>,
        ],
        [
          <DocLayout.InlineCode>VBG</DocLayout.InlineCode>,
          <>Verb Gerund</>,
        ],
        [
          <DocLayout.InlineCode>VBN</DocLayout.InlineCode>,
          <>Verb Past Participle</>,
        ],
        [
          <DocLayout.InlineCode>VBP</DocLayout.InlineCode>,
          <>Verb Non-Third Person Singular Present</>,
        ],
        [
          <DocLayout.InlineCode>VBZ</DocLayout.InlineCode>,
          <>Verb Third Person Singular Present</>,
        ],
        [
          <DocLayout.InlineCode>JJ</DocLayout.InlineCode>,
          <>Adjective</>,
        ],
        [
          <DocLayout.InlineCode>JJR</DocLayout.InlineCode>,
          <>Adjective Comparative</>,
        ],
        [
          <DocLayout.InlineCode>JJS</DocLayout.InlineCode>,
          <>Adjective Superlative</>,
        ],
        [
          <DocLayout.InlineCode>DT</DocLayout.InlineCode>,
          <>Determiner</>,
        ],
        [
          <DocLayout.InlineCode>IN</DocLayout.InlineCode>,
          <>Preposition</>,
        ],
        [
          <DocLayout.InlineCode>RB</DocLayout.InlineCode>,
          <>Adverb</>,
        ],
        [
          <DocLayout.InlineCode>RBR</DocLayout.InlineCode>,
          <>Adverb Comparative</>,
        ],
        [
          <DocLayout.InlineCode>RBS</DocLayout.InlineCode>,
          <>Adverb Superlative</>,
        ],
        [
          <DocLayout.InlineCode>CC</DocLayout.InlineCode>,
          <>Coordinating Conjunction</>,
        ],
        [
          <DocLayout.InlineCode>CD</DocLayout.InlineCode>,
          <>Cardinal Number</>,
        ],
        [
          <DocLayout.InlineCode>RP</DocLayout.InlineCode>,
          <>Particle</>,
        ],
        [
          <DocLayout.InlineCode>EX</DocLayout.InlineCode>,
          <>Existential There</>,
        ],
        [
          <DocLayout.InlineCode>TO</DocLayout.InlineCode>,
          <><i>to</i></>,
        ],
        [
          <DocLayout.InlineCode>MD</DocLayout.InlineCode>,
          <>Modal</>,
        ],
        [
          <DocLayout.InlineCode>WP</DocLayout.InlineCode>,
          <>WH-Pronoun</>,
        ],
        [
          <DocLayout.InlineCode>WPS</DocLayout.InlineCode>,
          <>Possessive WH-Pronoun</>,
        ],
        [
          <DocLayout.InlineCode>WRB</DocLayout.InlineCode>,
          <>WH-Adverb</>,
        ],
        [
          <DocLayout.InlineCode>WDT</DocLayout.InlineCode>,
          <>WH-Determiner</>,
        ],
        [
          <DocLayout.InlineCode>NFP</DocLayout.InlineCode>,
          <>Superfluous Punctuation</>,
        ],
        [
          <DocLayout.InlineCode>COMMA</DocLayout.InlineCode>,
          <>Comma</>,
        ],
        [
          <DocLayout.InlineCode>PERIOD</DocLayout.InlineCode>,
          <>Period</>,
        ],
        [
          <DocLayout.InlineCode>HYPH</DocLayout.InlineCode>,
          <>Hyphen</>,
        ],
        [
          <DocLayout.InlineCode>COLON</DocLayout.InlineCode>,
          <>Colon</>,
        ],
        [
          <DocLayout.InlineCode>DOLLAR</DocLayout.InlineCode>,
          <>Dollar Sign</>,
        ],
        [
          <DocLayout.InlineCode>RIGHTBRACKET</DocLayout.InlineCode>,
          <>Right Bracket</>,
        ],
        [
          <DocLayout.InlineCode>LEFTBRACKET</DocLayout.InlineCode>,
          <>Left Bracket</>,
        ],
        [
          <DocLayout.InlineCode>OPENINGQUOTES</DocLayout.InlineCode>,
          <>Opening Quotes</>,
        ],
        [
          <DocLayout.InlineCode>CLOSINGQUOTES</DocLayout.InlineCode>,
          <>Closing Quotes</>,
        ],
        [
          <DocLayout.InlineCode>UH</DocLayout.InlineCode>,
          <>Interjection</>,
        ],
        [
          <DocLayout.InlineCode>PDT</DocLayout.InlineCode>,
          <>Predeterminer</>,
        ],
        [
          <DocLayout.InlineCode>FW</DocLayout.InlineCode>,
          <>Foreign Word</>,
        ],
        [
          <DocLayout.InlineCode>SYM</DocLayout.InlineCode>,
          <>Symbol</>,
        ],
        [
          <DocLayout.InlineCode>ADD</DocLayout.InlineCode>,
          <>
            Electronic Address (
            <i>URL</i>
            )
          </>,
        ],
        [
          <DocLayout.InlineCode>LS</DocLayout.InlineCode>,
          <>List Item Marker</>,
        ],
        [
          <DocLayout.InlineCode>GW</DocLayout.InlineCode>,
          <>Go with</>,
        ],
        [
          <DocLayout.InlineCode>AFX</DocLayout.InlineCode>,
          <>Affix</>,
        ],
      ]}
    />

    <DocLayout.Header id={__docsSapienCoreLemmatization.hash}>
      Lemmatization
    </DocLayout.Header>
    <DocLayout.Body>
      Lemmatized or normalized tokens appear under the
      {' '}
      <DocLayout.InlineCode>tokens</DocLayout.InlineCode>
      {' '}
      key
      for each sentence as the third element in the array.
    </DocLayout.Body>
    <DocLayout.Body>
      Normalization includes making the token lowercase, transforming
      verbs to their infinitive form, transforming adjectives to their
      positive degree, and transforming nouns to their singular form.
    </DocLayout.Body>

    <DocLayout.Header id={__docsSapienCoreSyntaxDependencyTrees.hash}>
      Syntax Dependency Trees
    </DocLayout.Header>
    <DocLayout.Body>
      Syntax dependency trees appear under the
      {' '}
      <DocLayout.InlineCode>dependencies</DocLayout.InlineCode>
      {' '}
      key
      for each sentence.
    </DocLayout.Body>
    <DocLayout.Body>
      The first element in the array is the index of the dependent word.
      The second element in the array is the index of the head word
      (or governor). The third element in the array is the name of the
      dependency relation.
    </DocLayout.Body>
    <DocLayout.Table
      headers={[
        'Dependency Relation',
        'Meaning',
      ]}
      rows={[
        [
          <DocLayout.InlineCode>punct</DocLayout.InlineCode>,
          <>Punctuation</>,
        ],
        [
          <DocLayout.InlineCode>prep</DocLayout.InlineCode>,
          <>Preposition</>,
        ],
        [
          <DocLayout.InlineCode>pobj</DocLayout.InlineCode>,
          <>Prepositional Object</>,
        ],
        [
          <DocLayout.InlineCode>det</DocLayout.InlineCode>,
          <>Determinal</>,
        ],
        [
          <DocLayout.InlineCode>nsubj</DocLayout.InlineCode>,
          <>Nominal Subject</>,
        ],
        [
          <DocLayout.InlineCode>nn</DocLayout.InlineCode>,
          <>Noun Compound Modifier</>,
        ],
        [
          <DocLayout.InlineCode>amod</DocLayout.InlineCode>,
          <>Adjectival Modifier</>,
        ],
        [
          <DocLayout.InlineCode>ROOT</DocLayout.InlineCode>,
          <>Root</>,
        ],
        [
          <DocLayout.InlineCode>dobj</DocLayout.InlineCode>,
          <>Direct Object</>,
        ],
        [
          <DocLayout.InlineCode>aux</DocLayout.InlineCode>,
          <>Auxiliary</>,
        ],
        [
          <DocLayout.InlineCode>advmod</DocLayout.InlineCode>,
          <>Adverbial Modifier</>,
        ],
        [
          <DocLayout.InlineCode>conj</DocLayout.InlineCode>,
          <>Conjunct</>,
        ],
        [
          <DocLayout.InlineCode>cc</DocLayout.InlineCode>,
          <>Coordinating Conjunction</>,
        ],
        [
          <DocLayout.InlineCode>num</DocLayout.InlineCode>,
          <>Numeric Modifier</>,
        ],
        [
          <DocLayout.InlineCode>poss</DocLayout.InlineCode>,
          <>Possession Modifier</>,
        ],
        [
          <DocLayout.InlineCode>dep</DocLayout.InlineCode>,
          <>Unspecified Dependency</>,
        ],
        [
          <DocLayout.InlineCode>ccomp</DocLayout.InlineCode>,
          <>Clausal Complement</>,
        ],
        [
          <DocLayout.InlineCode>cop</DocLayout.InlineCode>,
          <>Copula</>,
        ],
        [
          <DocLayout.InlineCode>mark</DocLayout.InlineCode>,
          <>Marker</>,
        ],
        [
          <DocLayout.InlineCode>xcomp</DocLayout.InlineCode>,
          <>Open Clausal Complement</>,
        ],
        [
          <DocLayout.InlineCode>rcmod</DocLayout.InlineCode>,
          <>Relative Clause Modifier</>,
        ],
        [
          <DocLayout.InlineCode>advcl</DocLayout.InlineCode>,
          <>Adverbial Clause Modifier</>,
        ],
        [
          <DocLayout.InlineCode>possessive</DocLayout.InlineCode>,
          <>Possessive Modifier</>,
        ],
        [
          <DocLayout.InlineCode>nsubjpass</DocLayout.InlineCode>,
          <>Passive Nominal Subject</>,
        ],
        [
          <DocLayout.InlineCode>pcomp</DocLayout.InlineCode>,
          <>Prepositional Complement</>,
        ],
        [
          <DocLayout.InlineCode>appos</DocLayout.InlineCode>,
          <>Appositional Modifier</>,
        ],
        [
          <DocLayout.InlineCode>partmod</DocLayout.InlineCode>,
          <>Participial Modifier</>,
        ],
        [
          <DocLayout.InlineCode>neg</DocLayout.InlineCode>,
          <>Negation</>,
        ],
        [
          <DocLayout.InlineCode>number</DocLayout.InlineCode>,
          <>Number</>,
        ],
        [
          <DocLayout.InlineCode>prt</DocLayout.InlineCode>,
          <>Phrasal Verb Particle</>,
        ],
        [
          <DocLayout.InlineCode>quantmod</DocLayout.InlineCode>,
          <>Quantifier Phrase Modifier</>,
        ],
        [
          <DocLayout.InlineCode>infmod</DocLayout.InlineCode>,
          <>Infinitival Modifier</>,
        ],
        [
          <DocLayout.InlineCode>npadvmod</DocLayout.InlineCode>,
          <>Noun Phrase Adverbial Modifier</>,
        ],
        [
          <DocLayout.InlineCode>parataxis</DocLayout.InlineCode>,
          <>Parataxis</>,
        ],
        [
          <DocLayout.InlineCode>mwe</DocLayout.InlineCode>,
          <>Multi-word Expression</>,
        ],
        [
          <DocLayout.InlineCode>expl</DocLayout.InlineCode>,
          <>Expletive</>,
        ],
        [
          <DocLayout.InlineCode>iobj</DocLayout.InlineCode>,
          <>Indirect Object</>,
        ],
        [
          <DocLayout.InlineCode>acomp</DocLayout.InlineCode>,
          <>Adjectival Modifier</>,
        ],
        [
          <DocLayout.InlineCode>discourse</DocLayout.InlineCode>,
          <>Discourse</>,
        ],
        [
          <DocLayout.InlineCode>csubj</DocLayout.InlineCode>,
          <>Clausal Subject</>,
        ],
        [
          <DocLayout.InlineCode>predet</DocLayout.InlineCode>,
          <>Predeterminer</>,
        ],
        [
          <DocLayout.InlineCode>preconj</DocLayout.InlineCode>,
          <>Preconjunct</>,
        ],
        [
          <DocLayout.InlineCode>goeswith</DocLayout.InlineCode>,
          <>Goes with</>,
        ],
        [
          <DocLayout.InlineCode>csubjpass</DocLayout.InlineCode>,
          <>Passive Clausal Subject</>,
        ],
      ]}
    />

    <DocLayout.Header id={__docsSapienCoreSentenceGraph.hash}>
      Sentence Graph of Entities and Relationships
    </DocLayout.Header>
    <DocLayout.Body>
      A graph of entities and relationships will appear under
      the
      {' '}
      <DocLayout.InlineCode>graph</DocLayout.InlineCode>
      {' '}
      key for each sentence. This task
      provides similar information to the relation extraction
      task or open information extraction task in NLP.
    </DocLayout.Body>

    <DocLayout.Header id={__docsSapienCoreNamedEntityRecognition.hash}>
      Named Entity Recognition
    </DocLayout.Header>
    <DocLayout.Body>
      Named entities will appear under the
      {' '}
      <DocLayout.InlineCode>graph</DocLayout.InlineCode>
      {' '}
      key for each sentence under any entity objects under the
      {' '}
      <DocLayout.InlineCode>ner</DocLayout.InlineCode>
      {' '}
      key.
    </DocLayout.Body>
    <DocLayout.Body>
      The
      {' '}
      <DocLayout.InlineCode>ner</DocLayout.InlineCode>
      {' '}
      object provides a label (
      <DocLayout.InlineCode>label</DocLayout.InlineCode>
      ) for the recognized entity,
      along with identifiers for the Freebase entity (
      <DocLayout.InlineCode>freebaseIdentifier</DocLayout.InlineCode>
      ) and the
      {' '}
      <Link to={__docsCortexStructuredEntitySearch.path}>
        Cortex Knowledge Graph
      </Link>
      {' '}
      entity (
      <DocLayout.InlineCode>id</DocLayout.InlineCode>
      ). An example of the
      {' '}
      <DocLayout.InlineCode>ner</DocLayout.InlineCode>
      {' '}
      object is shown below:
    </DocLayout.Body>
    <DocLayout.CodeBlock
      language="json"
      data={
`{
  "freebaseIdentifier": "/m/01hg45m",
  "id": "PWE199585",
  "label": "Let It Be",
  "type": "concept"
}`
      }
    />

    <DocLayout.Header id={__docsSapienCoreDisambiguation.hash}>
      Disambiguation
    </DocLayout.Header>
    <DocLayout.ComingSoon />

    <DocLayout.Header id={__docsSapienCoreCoreferenceResolution.hash}>
      Coreference Resolution
    </DocLayout.Header>
    <DocLayout.ComingSoon />

    <DocLayout.Header id={__docsSapienCoreSentimentAnalysis.hash}>
      Sentiment Analysis
    </DocLayout.Header>
    <DocLayout.ComingSoon />
  </DocLayout>
);

export default Core;
