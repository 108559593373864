import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({
  id, label, children, ...rest
}) => (
  <div {...rest}>
    {children}
  </div>
);

Tab.defaultProps = {
  children: null,
};

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Tab;
