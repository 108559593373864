import React from 'react';

import BlogPostLayout from 'components/BlogPostLayout';

export const meta = {
  date: new Date('Oct 15 2020'),
  title: 'Plasticity Joins Executive 1',
  shortDescription: 'Plasticity is joining Executive 1 Holding Company to bring its NLP and AI capabilities to help defense and commercial customers make sense of unstructured data.',
  longDescription: 'Executive 1 Holding Company, LLC (EX1) has closed on and acquired San Francisco-based Plasticity AI, an Artificial Intelligence (AI), Natural Language Processing (NLP), and Machine Learning (ML) software services provider.',
  category: 'company',
  tags: [
    'acquisition',
  ],
};

const imageFolder = `${process.env.PUBLIC_URL}/blog/posts/plasticity-joins-ex1/images`;

const PlasticityJoinsEx1 = () => (
  <BlogPostLayout {...meta}>
    <BlogPostLayout.Body>
      Today, we&apos;re pleased to announce that Plasticity has
      officially been acquired by Executive 1 Holding Company.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      Since we started this company in 2016, we&apos;ve had a mission
      of making simple, powerful natural language processing technology
      open and accessible to companies, organizations, and developers.
      We&apos;ve long held the belief that natural language understanding
      is crucial to the future of human-computer interaction.
      Language and communication is what sets humans apart from
      our predecessors; it only makes sense that we find ways
      to train machines to comprehend language as well.
    </BlogPostLayout.Body>

    <BlogPostLayout.Image
      src={`${imageFolder}/yc.jpg`}
      placeholderSrc={`${imageFolder}/yc_low.jpg`}
      alt="Ajay and Alex at Y Combinator"
      caption="Plasticity founders, Ajay Patel and Alex Sands, before Y Combinator Demo Day in 2017."
    />

    <BlogPostLayout.Body>
      With this acquisition, we&apos;re excited to continue building
      towards that future. Plasticity will remain independent
      as a wholly owned subsidiary of EX1 post-acquisition, and
      we&apos;ll continue to provide customers with state-of-the-art
      NLP products and tools. With the existing resources of EX1,
      we&apos;ll be able to serve customers in a variety of new and
      exciting ways, bringing even deeper insights from data to
      power government and commercial decision-making.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      We also plan to continue investing in open source,
      releasing packages that NLP developers can openly
      use and contribute to. Researchers and students building
      for education can continue to use Plasticity&apos;s APIs
      for free&mdash;we&apos;re looking forward to seeing what you
      build.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      As founders, we couldn&apos;t be more excited about this
      acquisition. It&apos;s been an incredible four years growing
      a company that only succeeds when our customers do.
      And yet, it still feels like today is just the beginning.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      - Ajay and Alex
    </BlogPostLayout.Body>
  </BlogPostLayout>
);

export default PlasticityJoinsEx1;
