import React from 'react';
import PropTypes from 'prop-types';

import Gradient from 'components/Gradient';

import { THEME_DEFAULT_PROPS, THEME_PROP_TYPES } from 'constants/themes';

import globalStyles from 'css/global.module.scss';
import styles from './styles.module.scss';

const Section = ({
  theme, flavor, accent, gradient, padding, offset, containerElement,
  layoutElement, children, className, ...rest
}) => (
  <section
    className={[
      globalStyles[`theme-${theme}`],
      globalStyles[`flavor-${flavor}`],
      globalStyles[`accent-${accent}`],
      styles.section,
      className,
    ].join(' ')}
    {...rest}
  >
    <div className={styles.container}>
      {containerElement}
      <div className={styles['layout-container']}>
        {layoutElement}
        <div
          className={[
            styles.layout,
            styles[`padding-${padding}`],
            styles[`offset-${offset}`],
          ].join(' ')}
        >
          {children}
        </div>
      </div>
    </div>
    {gradient !== 'none'
        && (
        <div
          className={[
            styles.gradient,
            gradient === 'full' ? styles.full : '',
          ].join(' ')}
        >
          <Gradient />
        </div>
        )}
  </section>
);

Section.defaultProps = {
  ...THEME_DEFAULT_PROPS,
  gradient: 'none',
  padding: 'md',
  offset: 'center',
  containerElement: null,
  layoutElement: null,
  children: null,
  className: '',
};

Section.propTypes = {
  ...THEME_PROP_TYPES,
  gradient: PropTypes.oneOf(['none', 'partial', 'full']),
  padding: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  offset: PropTypes.oneOf(['upper', 'lower', 'center']),
  containerElement: PropTypes.node,
  layoutElement: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Section;
