import React from 'react';

import DocLayout from 'components/DocLayout';

const Cortex = () => (
  <DocLayout>
    <DocLayout.Title>
      Cortex Knowledge Graph
    </DocLayout.Title>
    <DocLayout.Body>
      Query a graph of real word concepts, people, places,
      and things in natural language. Use this API to tap
      into 240+ million facts on over 20+ million entities,
      or to query your own documents with natural language.
    </DocLayout.Body>
  </DocLayout>
);

export default Cortex;
