import React from 'react';
import { Redirect } from 'react-router-dom';

import BlogHome from 'pages/blog/home';

import BlogPostBrianKoloJoinsBoard, { meta as BlogPostBrianKoloJoinsBoardMeta } from 'pages/blog/posts/brian-kolo-joins-board';
import BlogPostGaryKesslerJoinsBoard, { meta as BlogPostGaryKesslerJoinsBoardMeta } from 'pages/blog/posts/gary-kessler-joins-board';
import BlogPostPoliticalDisinformationCampaignOnYoutube, { meta as BlogPostPoliticalDisinformationCampaignOnYoutubeMeta } from 'pages/blog/posts/political-disinformation-campaign-on-youtube';
import BlogPostPlasticityJoinsEx1, { meta as BlogPostPlasticityJoinsEx1Meta } from 'pages/blog/posts/plasticity-joins-ex1';

import { blogPaths } from 'constants/paths';

export const __blogPostBrianKoloJoinsBoard = {
  path: blogPaths.__blogPostBrianKoloJoinsBoard,
  exact: true,
  component: BlogPostBrianKoloJoinsBoard,
  meta: BlogPostBrianKoloJoinsBoardMeta,
};

export const __blogPostGaryKesslerJoinsBoard = {
  path: blogPaths.__blogPostGaryKesslerJoinsBoard,
  exact: true,
  component: BlogPostGaryKesslerJoinsBoard,
  meta: BlogPostGaryKesslerJoinsBoardMeta,
};

export const __blogPostPoliticalDisinformationCampaignOnYoutube = {
  path: blogPaths.__blogPostPoliticalDisinformationCampaignOnYoutube,
  exact: true,
  component: BlogPostPoliticalDisinformationCampaignOnYoutube,
  meta: BlogPostPoliticalDisinformationCampaignOnYoutubeMeta,
};

export const __blogPostPlasticityJoinsEx1 = {
  path: blogPaths.__blogPostPlasticityJoinsEx1,
  exact: true,
  component: BlogPostPlasticityJoinsEx1,
  meta: BlogPostPlasticityJoinsEx1Meta,
};

export const __blogHome = {
  path: blogPaths.__blogHome,
  exact: true,
  component: BlogHome,
};

export const rrblogFallback = {
  path: '*',
  render: () => <Redirect to={__blogHome.path} />,
};

export const blogPosts = [
  __blogPostBrianKoloJoinsBoard,
  __blogPostGaryKesslerJoinsBoard,
  // __blogPostPoliticalDisinformationCampaignOnYoutube,
  __blogPostPlasticityJoinsEx1,
];

/**
 * Export the blog routes.
 *
 * Note: ORDERING MATTERS!
 * These should be ordered from least to most specific
 * since the Switch statement will use the reverse of
 * this ordering. For example, the fallback redirect
 * route should be the first item in the object (even
 * though it is the last thing that should match).
 *
 * Warning: a blogPosts expansion (i.e., ...blogPosts)
 * will not work in blogRoutes because ordering of
 * __blogHome above the blogPosts expansion is not
 * guaranteed. You need to re-include blogPosts
 * individually.
 * @type {Object}
 */
/* eslint-disable indent */
export const blogRoutes = {
  rrblogFallback,
  __blogHome,
    __blogPostBrianKoloJoinsBoard,
    __blogPostGaryKesslerJoinsBoard,
    __blogPostPoliticalDisinformationCampaignOnYoutube,
    __blogPostPlasticityJoinsEx1,
};
/* eslint-enable indent */

export default blogRoutes;
