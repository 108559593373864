import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ColumnLayout = ({ columns, align, children }) => (
  <div
    className={[
      styles.column,
      styles[`align-${align}`],
    ].join(' ')}
    data-columns={columns}
  >
    {children}
  </div>
);

ColumnLayout.defaultProps = {
  children: null,
  align: 'top',
};

ColumnLayout.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['top', 'center', 'bottom']),
  columns: PropTypes.oneOf([
    '1',
    '1,1',
    '1,1,1,1',
    '1,1,2',
    '1,2,1',
    '1,3',
    '2,2',
    '2,1,1',
    '2,1,0',
    '3,1',
  ]).isRequired,
};

export default ColumnLayout;
