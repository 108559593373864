import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typist from 'react-typist';
import { get } from 'lodash';

import styles from './styles.module.scss';

const AVERAGE_TYPING_DELAY = 40;

const CURSOR = {
  hideWhenDone: true,
  hideWhenDoneDelay: 0,
};

const SentenceFactVisualizer = ({ spans, facts, className }) => {
  const [activeFactIndex, setActiveFactIndex] = useState(-1);
  const activeFact = Object.keys(facts)[activeFactIndex] || '';
  const activeSpans = get(facts, activeFact, []);
  const factsLength = Object.keys(facts).length;

  const onSentenceDone = () => {
    setActiveFactIndex(0);
  };

  const onFactDone = () => {
    const newActiveFactIndex = (activeFactIndex + 1) % factsLength;
    setActiveFactIndex(newActiveFactIndex);
  };

  return (
    <div
      className={[
        styles.visualizer,
        activeFact ? styles['has-active-fact'] : '',
        className,
      ].join(' ')}
    >
      <div className={styles.header}>
        Sentence
      </div>

      <div
        className={[
          styles.sentence,
          activeFact ? styles[`flavor-${(activeFactIndex % 6) + 1}`] : '',
          activeSpans.map((idx) => styles[`span-${idx}-active`]).join(' '),
        ].join(' ')}
      >
        <Typist
          avgTypingDelay={AVERAGE_TYPING_DELAY}
          cursor={CURSOR}
          onTypingDone={onSentenceDone}
        >
          {spans.map((span, index) => (
            <span
              key={index}
              data-index={index}
              className={styles['sentence-span']}
            >
              {span}
            </span>
          ))}
          <Typist.Delay ms={2000} />
        </Typist>
      </div>

      {activeFact
        && (
        <div className={styles.header}>
          Fact (
          {activeFactIndex + 1}
          {' '}
          of
          {' '}
          {factsLength}
          )
        </div>
        )}

      {activeFact
        && (
        <div className={styles.fact}>
          <Typist
            key={activeFact}
            avgTypingDelay={AVERAGE_TYPING_DELAY}
            cursor={CURSOR}
            onTypingDone={onFactDone}
          >
            <Typist.Delay ms={3000} />
            {activeFact}
            <Typist.Delay ms={3000} />
          </Typist>
        </div>
        )}
    </div>
  );
};

SentenceFactVisualizer.defaultProps = {
  className: '',
};

SentenceFactVisualizer.propTypes = {
  spans: PropTypes.array.isRequired,
  facts: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default SentenceFactVisualizer;
