import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryParamProvider } from 'use-query-params';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import DefaultHelmet from 'components/Helmets/DefaultHelmet';
import Crisp from 'components/Crisp';
import SegmentListener from 'components/SegmentListener';
import ScrollToTop from 'components/ScrollToTop';
import RouteItem from 'components/RouteItem';

import { internalRoutes, redirectRoutes } from 'constants/routes';

const DEFAULT_HELMET = <DefaultHelmet />;

const App = () => (
  <React.StrictMode>
    <HelmetProvider>
      {DEFAULT_HELMET}
      <Crisp />
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <SegmentListener />
          <ScrollToTop />
          <Suspense fallback={null}>
            <Switch>
              {Object.values(redirectRoutes).map((redirect) => (
                <Redirect
                  key={redirect.from}
                  {...redirect}
                />
              ))}
              {Object.values(internalRoutes).map((route) => (
                <RouteItem
                  key={route.path}
                  {...route}
                />
              ))}
            </Switch>
          </Suspense>
        </QueryParamProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

export default App;
