import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import LazyImage from 'components/LazyImage';
import Link from 'components/Link';
import Copy from 'components/Copy';
import CTAButton from 'components/CTAButton';

import styles from './styles.module.scss';

const BlogPostCard = ({
  path, date, title, longDescription,
}) => {
  const imageSrc = `${process.env.PUBLIC_URL}/blog/banners/${path.substring(6)}.png`;
  const lowResImageSrc = `${process.env.PUBLIC_URL}/blog/banners/${path.substring(6)}_low.png`;

  return (
    <Link
      to={path}
      decoration={false}
      className={styles.link}
    >
      <div className={styles.card}>
        <div className={styles.image}>
          <LazyImage
            src={imageSrc}
            placeholderSrc={lowResImageSrc}
            alt={title}
          />
        </div>
        <div className={styles.content}>
          <Copy
            variant="subsection"
            caption={(
              <Moment
                date={date}
                format="MMMM D, YYYY"
              />
            )}
            title={title}
            body={longDescription}
            footer={(
              <CTAButton
                variant="link"
                text="Read more"
                className={styles.button}
              />
            )}
            className={styles.copy}
            captionClassName={styles.caption}
            titleClassName={styles.title}
            bodyClassName={styles.body}
            footerClassName={styles.footer}
          />
        </div>
      </div>
    </Link>
  );
};

BlogPostCard.propTypes = {
  path: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
};

export default BlogPostCard;
