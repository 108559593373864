import React from 'react';
import PropTypes from 'prop-types';
import Code from 'components/CodeBlock';

import styles from './styles.module.scss';

const CodeBlock = ({ data, language }) => (
  <Code
    language={language}
    code={data}
    className={styles.code}
  />
);

CodeBlock.defaultProps = {
  language: 'json',
};

CodeBlock.propTypes = {
  language: PropTypes.string,
  data: PropTypes.string.isRequired,
};

export default CodeBlock;
