import React, { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { get } from 'lodash';
import {
  Button,
  TextInputField,
} from 'evergreen-ui';

import Copy from 'components/Copy';
import Form from 'components/Form';

/**
 * This component sends form data to a Google Sheet.
 * The Google Sheet has a Google Scripts project
 * that publishes a web app to listen for post requests.
 * The backend logic all happens in the Google Script,
 * which you can find here:
 *
 * Sheet: https://docs.google.com/spreadsheets/d/1KHrIlcBr1-1toSvv12QUUWy6cBHtU42OAD2QRF5du4M/edit#gid=0
 * Script: https://script.google.com/d/1FzZw1mHZDQlv35AqQaoSG3f1yw9-G_8N6Y5uitO6-5YTAI4QXyXmeNl8/edit
 *
 * When modifying the backend logic, make sure to publish
 * changes via:
 * - Publish -> Deploy as web app...
 * - Change "Project version" to New
 * - Update
 */

const SCRIPT_PATH = 'https://script.google.com/macros/s/AKfycbzltATyx4Aiw-01ISHR8q609BOkASCQn3Wuf9-5ILBAWK2rIpD4/exec';
const SUCCESS_MESSAGE = 'Welcome! We\'ve added you to our newsletter.';
const ERROR_MESSAGE = 'An unexpected error occurred.';

const fetchResult = async (email) => {
  const response = await fetch(SCRIPT_PATH, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });

  const json = await response.json();

  if (response.status !== 200) {
    throw new Error(ERROR_MESSAGE);
  }

  if (get(json, 'result') === 'error') {
    throw new Error(get(json, 'error'));
  }

  return json;
};

const NewsletterSubscribeDetail = () => {
  const [email, setEmail] = useState('');
  const asyncFetchResult = useAsyncCallback(fetchResult);

  const onSubmit = () => {
    asyncFetchResult.execute(email);
    setEmail('');
  };

  return (
    <Copy
      variant="detail"
      title="Subscribe to our newsletter"
      body="Get new blog posts and Plasticity updates delivered directly to your inbox."
      footer={(
        <Form>
          <Form.FieldRow>
            <TextInputField
              placeholder="Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.FieldRow>
          <Form.ButtonRow>
            <Button
              appearance="primary"
              isLoading={asyncFetchResult.loading}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Form.ButtonRow>
          <Form.ErrorRow error={asyncFetchResult.error} />
          {asyncFetchResult.result
            && <Form.SuccessRow message={SUCCESS_MESSAGE} />}
        </Form>
      )}
    />
  );
};

export default NewsletterSubscribeDetail;
