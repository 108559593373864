import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Subheader = ({ children, ...rest }) => (
  <h3
    className={styles.subheader}
    {...rest}
  >
    {children}
  </h3>
);

Subheader.defaultProps = {
  children: null,
};

Subheader.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Subheader;
