import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import Body from 'components/DocLayout/Body';

import { SALES_EMAIL } from 'constants/contact';
import { __scheduleDemo } from 'constants/routes';

const ComingSoon = ({ showSalesMessage }) => (
  <div>
    <Body>
      This API endpoint is currently under development
      and will be released shortly. Please
      {' '}
      <Link
        to={__scheduleDemo.path}
        target="_blank"
        rel="noreferrer"
      >
        reach out to us
      </Link>
      {' '}
      to stay updated on future releases.
    </Body>
    {showSalesMessage
      && (
      <Body>
        If you represent a company that could benefit from
        early access to this endpoint, please contact us at
        {' '}
        <Link to={`mailto:${SALES_EMAIL}`}>{SALES_EMAIL}</Link>
        .
      </Body>
      )}
  </div>
);

ComingSoon.defaultProps = {
  showSalesMessage: false,
};

ComingSoon.propTypes = {
  showSalesMessage: PropTypes.bool,
};

export default ComingSoon;
