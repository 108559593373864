import React from 'react';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import BlogPostCard from 'components/BlogPostCard';
import NewsletterSubscribeDetail from 'components/NewsletterSubscribeDetail';

import { sortedBlogPosts } from 'pages/blog/utils';

import { ACCENTS } from 'constants/themes';

const Home = () => (
  <>
    <SiteHeader
      theme="dark"
      accent={ACCENTS.blog}
    />
    <Section
      theme="white"
      offset="lower"
      accent={ACCENTS.blog}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Blog"
            title="Writing from the Plasticity team"
            body={(
              <p>
                Stay up to date with Plasticity&apos;s engineering, growth,
                and company announcements through our blog. Read
                posts from our team below and subscribe to our newsletter
                to be notified when new posts are published.
              </p>
            )}
          />
          <NewsletterSubscribeDetail />
        </ColumnLayout>
        {sortedBlogPosts.map((post) => (
          <BlogPostCard
            key={post.path}
            path={post.path}
            {...post.meta}
          />
        ))}
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Home;
