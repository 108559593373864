import React from 'react';

import DocLayout from 'components/DocLayout';

const Sapien = () => (
  <DocLayout>
    <DocLayout.Title>
      Sapien Language Engine
    </DocLayout.Title>
    <DocLayout.Body>
      A general natural language understanding API that
      extracts entities, relationships, intents, context,
      and more from text. Use this API for text analytics
      or for building robust conversational interfaces.
    </DocLayout.Body>
  </DocLayout>
);

export default Sapien;
