import React from 'react';
import { Redirect } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';

import DemoHome from 'pages/demo/home';
import DemoSapienParsing from 'pages/demo/sapien/parsing';
import DemoSapienExtractRelations from 'pages/demo/sapien/extract-relations';
import DemoSapienCompare from 'pages/demo/sapien/compare';
import DemoCortexQueryNL from 'pages/demo/cortex/query-nl';

import { demoPaths } from 'constants/paths';

export const __demoSapienParsing = {
  path: demoPaths.__demoSapienParsing,
  exact: true,
  component: DemoSapienParsing,
  nav: {
    text: 'Low-level text parsing',
  },
};

export const __demoSapienExtractRelations = {
  path: demoPaths.__demoSapienExtractRelations,
  exact: true,
  component: DemoSapienExtractRelations,
  nav: {
    text: 'Extract relations from sentences',
  },
};

export const __demoSapienCompare = {
  path: demoPaths.__demoSapienCompare,
  exact: true,
  component: DemoSapienCompare,
  nav: {
    text: 'Compare with other NLP packages',
  },
};

export const __demoSapien = {
  path: demoPaths.__demoSapien,
  exact: true,
  children: <Redirect to={__demoSapienExtractRelations.path} />,
  nav: {
    text: 'Sapien Language Engine',
  },
};

export const __demoCortexQueryNL = {
  path: demoPaths.__demoCortexQueryNL,
  exact: true,
  component: DemoCortexQueryNL,
  nav: {
    text: 'Query in natural language',
  },
};

export const __demoCortex = {
  path: demoPaths.__demoCortex,
  exact: true,
  children: <Redirect to={__demoCortexQueryNL.path} />,
  nav: {
    text: 'Cortex Knowledge Graph',
  },
};

export const __demoHome = {
  path: demoPaths.__demoHome,
  exact: true,
  component: DemoHome,
  nav: {
    text: 'Demos',
    icon: <FiHome />,
  },
};

export const rrdemoFallback = {
  path: '*',
  render: () => <Redirect to={__demoHome.path} />,
};

/**
 * Export the demo routes.
 *
 * Note: ORDERING MATTERS!
 * These should be ordered from least to most specific
 * since the Switch statement will use the reverse of
 * this ordering. For example, the fallback redirect
 * route should be the first item in the object (even
 * though it is the last thing that should match).
 * @type {Object}
 */
/* eslint-disable indent */
export const demoRoutes = {
rrdemoFallback,
__demoHome,
__demoSapien,
  __demoSapienParsing,
  __demoSapienExtractRelations,
  __demoSapienCompare,
__demoCortex,
  __demoCortexQueryNL,
};
/* eslint-enable indent */

export default demoRoutes;
