import React, { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { get } from 'lodash';
import {
  Button,
  TextareaField,
  TextInputField,
} from 'evergreen-ui';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import Copy from 'components/Copy';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Form from 'components/Form';

import { ACCENTS } from 'constants/themes';

/**
 * This component sends form data to a Google Sheet.
 * The Google Sheet has a Google Scripts project
 * that publishes a web app to listen for post requests.
 * The backend logic all happens in the Google Script,
 * which you can find here:
 *
 * Sheet: https://docs.google.com/spreadsheets/d/1X7GVr_7pHezRCps8mA816awAEOC-gKWE-ozuZhGZft4/edit#gid=0
 * Script: https://script.google.com/d/1UQupVRuWn4JLKWuowjp0jySGM6HOb496PjOUYxUAWc9j2sEFkGzDnDwu/edit
 *
 * When modifying the backend logic, make sure to publish
 * changes via:
 * - Publish -> Deploy as web app...
 * - Change "Project version" to New
 * - Update
 */

const SCRIPT_PATH = 'https://script.google.com/macros/s/AKfycbxlqSXOTdRWrZINZLY8LZh-mq_L2j87LmHUlb6NC_FOr8-lPW0/exec';
const FORM_VALUES = {
  name: '',
  email: '',
  website: '',
  notes: '',
};
const SUCCESS_MESSAGE = 'Your request has been received. We will follow up via email shortly.';
const ERROR_MESSAGE = 'An unexpected error occurred.';

const fetchResult = async (data) => {
  const response = await fetch(SCRIPT_PATH, {
    method: 'POST',
    body: JSON.stringify(data),
  });

  const json = await response.json();

  if (response.status !== 200) {
    throw new Error(ERROR_MESSAGE);
  }

  if (get(json, 'result') === 'error') {
    throw new Error(get(json, 'error'));
  }

  return json;
};

const ScheduleDemo = () => {
  const [formValues, setFormValues] = useState(FORM_VALUES);
  const asyncFetchResult = useAsyncCallback(fetchResult);

  const onSubmit = () => {
    asyncFetchResult.execute(formValues);
    setFormValues(FORM_VALUES);
  };

  return (
    <>
      <SiteHeader
        theme="light"
        accent={ACCENTS.home}
      />
      <Section
        theme="dark"
        accent={ACCENTS.home}
      >
        <RowLayout>
          <ColumnLayout columns="3,1">
            <Copy
              variant="section"
              caption="Get in touch"
              title="Schedule a demo with our team"
              body={(
                <p>
                  See how Plasticity can help your organization.
                  Use the form below to request a one-on-one demo
                  with our sales team. We&apos;ll be in touch shortly
                  to schedule a time to learn more.
                </p>
              )}
              footer={(
                <ColumnLayout columns="2,2">
                  <Form>
                    <Form.FieldRow>
                      <TextInputField
                        required
                        value={formValues.name}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            name: e.target.value,
                          });
                        }}
                        label="Name"
                        placeholder="Jane Smith"
                      />
                    </Form.FieldRow>
                    <Form.FieldRow>
                      <TextInputField
                        required
                        value={formValues.email}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            email: e.target.value,
                          });
                        }}
                        label="Email"
                        placeholder="jane.smith@example.com"
                      />
                    </Form.FieldRow>
                    <Form.FieldRow>
                      <TextInputField
                        required
                        value={formValues.website}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            website: e.target.value,
                          });
                        }}
                        label="Company Website"
                        placeholder="example.com"
                      />
                    </Form.FieldRow>
                    <Form.FieldRow>
                      <TextareaField
                        value={formValues.notes}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            notes: e.target.value,
                          });
                        }}
                        label="How can we help?"
                        placeholder="I would like to see a demo of..."
                      />
                    </Form.FieldRow>
                    <Form.ButtonRow>
                      <Button
                        height={40}
                        appearance="primary"
                        isLoading={asyncFetchResult.loading}
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                    </Form.ButtonRow>
                    <Form.ErrorRow error={asyncFetchResult.error} />
                    {asyncFetchResult.result
                      && <Form.SuccessRow message={SUCCESS_MESSAGE} />}
                  </Form>
                </ColumnLayout>
              )}
            />
          </ColumnLayout>
        </RowLayout>
      </Section>
      <SiteFooter theme="light" />
    </>
  );
};

export default ScheduleDemo;
