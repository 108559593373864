import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  RiBookOpenLine,
  RiCodeSSlashLine,
  RiGithubLine,
  RiGovernmentLine,
  RiLeafLine,
  RiPenNibLine,
  RiPhoneLine,
  RiShieldUserLine,
  RiStore2Line,
} from 'react-icons/ri';

import Card from 'components/Card';
import List from 'components/List';
import MenuListItem from 'components/MenuListItem';
import Icon from 'components/Icon';
import {
  SapienLine,
  CortexLine,
  LinguaLine,
} from 'components/Icons';

import { ACCENTS } from 'constants/themes';
import {
  __government,
  __commercial,
  __developers,
  __apiSapien,
  __apiCortex,
  __apiLingua,
  __docs,
  __blog,
  __openSource,
  __scheduleDemo,
  __contactUs,
} from 'constants/routes';
import {
  __docsGettingStarted,
  __docsSapien,
  __docsCortex,
  __docsLingua,
} from 'pages/docs/routes';
import { getNewestBlogPosts } from 'pages/blog/utils';
import { getNewestArticles } from 'pages/news/utils';

import globalStyles from 'css/global.module.scss';
import styles from './styles.module.scss';

const SiteMenu = ({
  hoveredDropdownButton, activeDropdown, setActiveDropdown,
}) => {
  const forceVisible = ''; // Set to menu id for development to show the menu
  const [isVisible, setIsVisible] = useState(false);

  /**
   * Which dropdown should be visible during development.
   * Set this by changing the `forceVisible` variable
   * to one of the menu.
   * @type {string}
   */
  const forceDropdown = (
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'development' // check if development environment
    && forceVisible
  );

  /**
   * Set the active dropdown to the hovered button.
   * @return {void}
   */
  useEffect(() => {
    if (hoveredDropdownButton) {
      setActiveDropdown(hoveredDropdownButton);
    }
  }, [hoveredDropdownButton]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Set the active dropdown back to nothing when the users
   * is outside of the dropdown and no longer hovering on the
   * dropdown button.
   * @return {void}
   */
  useEffect(() => {
    if (!isVisible && !hoveredDropdownButton) {
      setActiveDropdown('');
    }
  }, [isVisible, hoveredDropdownButton]); // eslint-disable-line react-hooks/exhaustive-deps

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  return (
    <div
      className={[
        styles.menu,
        (isVisible || hoveredDropdownButton || forceDropdown) ? styles.show : '',
      ].join(' ')}
      onMouseEnter={show}
      onMouseLeave={hide}
    >
      {/* PRODUCT DROPDOWN */}
      {(activeDropdown === 'product' || forceDropdown === 'product')
        && (
        <Card
          className={[
            globalStyles['theme-white'],
            styles.card,
            styles.product,
          ].join(' ')}
        >
          <section className={styles.section}>
            <section className={styles.subsection}>
              <h1 className={styles.header}>Use cases</h1>
              <List>
                <MenuListItem
                  to={__government.path}
                  title="Government"
                  text="Solutions for government"
                  icon={(
                    <Icon
                      glyph={<RiGovernmentLine />}
                      size="lg"
                      accent={ACCENTS.government}
                    />
                  )}
                  onClick={hide}
                />
                <MenuListItem
                  to={__commercial.path}
                  title="Commercial"
                  text="Products for companies"
                  icon={(
                    <Icon
                      glyph={<RiStore2Line />}
                      size="lg"
                      accent={ACCENTS.commercial}
                    />
                  )}
                  onClick={hide}
                />
                <MenuListItem
                  to={__developers.path}
                  title="Developers"
                  text="APIs for developers"
                  icon={(
                    <Icon
                      glyph={<RiCodeSSlashLine />}
                      size="lg"
                      accent={ACCENTS.developers}
                    />
                  )}
                  onClick={hide}
                />
              </List>
            </section>
          </section>
          <section className={styles.section}>
            <section className={styles.subsection}>
              <h1 className={styles.header}>API Endpoints</h1>
              <List>
                <MenuListItem
                  to={__apiSapien.path}
                  title="Sapien Language Engine"
                  text="Core natural language tasks"
                  icon={(
                    <Icon
                      glyph={<SapienLine />}
                      size="lg"
                      accent={ACCENTS.sapien}
                    />
                  )}
                  onClick={hide}
                />
                <MenuListItem
                  to={__apiCortex.path}
                  title="Cortex Knowledge Graph"
                  text="Real-world information graph"
                  icon={(
                    <Icon
                      glyph={<CortexLine />}
                      size="lg"
                      accent={ACCENTS.cortex}
                    />
                  )}
                  onClick={hide}
                />
                <MenuListItem
                  to={__apiLingua.path}
                  title="Lingua Dialogue Engine"
                  text="Conversational interfaces"
                  icon={(
                    <Icon
                      glyph={<LinguaLine />}
                      size="lg"
                      accent={ACCENTS.lingua}
                    />
                  )}
                  onClick={hide}
                />
              </List>
            </section>
          </section>
        </Card>
        )}

      {/* RESOURCES DROPDOWN */}
      {(activeDropdown === 'resources' || forceDropdown === 'resources')
        && (
        <Card
          className={[
            globalStyles['theme-white'],
            styles.card,
            styles.resources,
          ].join(' ')}
        >
          <section className={styles.section}>
            <section className={styles.subsection}>
              <h1 className={styles.header}>Docs</h1>
              <List>
                <MenuListItem
                  to={__docs.path}
                  title="Documentation"
                  text="Get started in minutes"
                  icon={(
                    <Icon
                      glyph={<RiBookOpenLine />}
                      size="lg"
                    />
                  )}
                  onClick={hide}
                />
                <MenuListItem
                  to={__docsGettingStarted.path}
                  title="Getting started"
                  icon={<RiLeafLine />}
                  onClick={hide}
                />
                <MenuListItem
                  to={__docsSapien.path}
                  title="Sapien Language Engine"
                  icon={<SapienLine />}
                  onClick={hide}
                />
                <MenuListItem
                  to={__docsCortex.path}
                  title="Cortex Knowledge Graph"
                  icon={<CortexLine />}
                  onClick={hide}
                />
                <MenuListItem
                  to={__docsLingua.path}
                  title="Lingua Dialogue Engine"
                  icon={<LinguaLine />}
                  onClick={hide}
                />
              </List>
            </section>
            <section className={styles.subsection}>
              <h1 className={styles.header}>Company</h1>
              <List columns={2}>
                <MenuListItem
                  to={__scheduleDemo.path}
                  title="Request a demo"
                  icon={<RiShieldUserLine />}
                  onClick={hide}
                />
                <MenuListItem
                  to={__openSource.path}
                  title="Open source"
                  icon={<RiGithubLine />}
                  onClick={hide}
                />
                <MenuListItem
                  to={__contactUs.path}
                  title="Contact us"
                  icon={<RiPhoneLine />}
                  onClick={hide}
                />
              </List>
            </section>
          </section>
          <section className={styles.section}>
            <section className={styles.subsection}>
              <h1 className={styles.header}>Blog</h1>
              <List>
                <MenuListItem
                  to={__blog.path}
                  title="Blog posts"
                  text="Stay up to date with Plasticity"
                  icon={(
                    <Icon
                      glyph={<RiPenNibLine />}
                      size="lg"
                    />
                  )}
                  onClick={hide}
                />
                {getNewestBlogPosts(2).map((post) => (
                  <MenuListItem
                    key={post.path}
                    title={post.meta.title}
                    text={post.meta.shortDescription}
                    to={post.path}
                    onClick={hide}
                  />
                ))}
              </List>
            </section>
            <section className={styles.subsection}>
              <h1 className={styles.header}>News</h1>
              <List>
                {getNewestArticles(2).map((article) => (
                  <MenuListItem
                    key={article.path}
                    title={article.meta.title}
                    text={article.meta.source}
                    to={article.to}
                    onClick={hide}
                  />
                ))}
              </List>
            </section>
          </section>
        </Card>
        )}
    </div>
  );
};

SiteMenu.defaultProps = {
  hoveredDropdownButton: '',
  activeDropdown: '',
};

SiteMenu.propTypes = {
  hoveredDropdownButton: PropTypes.string,
  activeDropdown: PropTypes.string,
  setActiveDropdown: PropTypes.func.isRequired,
};

export default SiteMenu;
