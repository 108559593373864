import React from 'react';

import DocLayout from 'components/DocLayout';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import CTAButton from 'components/CTAButton';
import Link from 'components/Link';
import Icon from 'components/Icon';

import {
  __docsGettingStarted,
  __docsSapien,
  __docsCortex,
  __docsLingua,
} from 'pages/docs/routes';

import { SUPPORT_EMAIL } from 'constants/contact';
import { ACCENTS } from 'constants/themes';

const Home = () => (
  <DocLayout>
    <DocLayout.Title>
      Documentation
    </DocLayout.Title>
    <DocLayout.Body>
      Welcome to Plasticity! Get familiar with the Plasticity
      developer APIs and explore their features. If you need
      any help, reach out to us at
      {' '}
      <Link to={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
      .
    </DocLayout.Body>
    <RowLayout size="sm">
      <ColumnLayout columns="2,2">
        <Copy
          variant="detail"
          icon={(
            <Icon
              size="lg"
              accent={ACCENTS.docs}
              glyph={__docsGettingStarted.nav.icon}
            />
            )}
          title={__docsGettingStarted.nav.text}
          body={(
            <p>
              See examples of the API request and response format,
              how to authenticate, and more information to help you
              get started in minutes.
            </p>
            )}
          footer={(
            <CTAButton
              variant="link"
              text="Read more"
              to={__docsGettingStarted.path}
            />
            )}
        />
        <Copy
          variant="detail"
          icon={(
            <Icon
              size="lg"
              accent={ACCENTS.sapien}
              glyph={__docsSapien.nav.icon}
            />
            )}
          title={__docsSapien.nav.text}
          body={(
            <p>
              A general natural language understanding API that extracts
              entities, relationships, intents, context, and more from text.
            </p>
            )}
          footer={(
            <CTAButton
              variant="link"
              text="Read more"
              to={__docsSapien.path}
            />
            )}
        />
      </ColumnLayout>
      <ColumnLayout columns="2,2">
        <Copy
          variant="detail"
          icon={(
            <Icon
              size="lg"
              accent={ACCENTS.cortex}
              glyph={__docsCortex.nav.icon}
            />
            )}
          title={__docsCortex.nav.text}
          body={(
            <p>
              Query a graph of real word concepts, people, places,
              and things in natural language.
            </p>
            )}
          footer={(
            <CTAButton
              variant="link"
              text="Read more"
              to={__docsCortex.path}
            />
            )}
        />
        <Copy
          variant="detail"
          icon={(
            <Icon
              size="lg"
              accent={ACCENTS.lingua}
              glyph={__docsLingua.nav.icon}
            />
            )}
          title={__docsLingua.nav.text}
          body={(
            <p>
              Provides human-like natural language responses to
              questions or intents.
            </p>
            )}
          footer={(
            <CTAButton
              variant="link"
              text="Read more"
              to={__docsLingua.path}
            />
            )}
        />
      </ColumnLayout>
    </RowLayout>
  </DocLayout>
);

export default Home;
