import React from 'react';
import {
  Switch,
} from 'react-router-dom';

import RouteItem from 'components/RouteItem';

import { __newsHome, newsLinks } from 'pages/news/routes';

const News = () => (
  <Switch>
    {Object.values(newsLinks).reverse().map((route) => (
      <RouteItem
        key={route.path}
        exact={route.exact}
        path={route.path}
        render={() => {
          window.location = route.to;
        }}
      />
    ))}
    <RouteItem {...__newsHome} />
  </Switch>
);

export default News;
