import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './styles.module.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({
  src, placeholderSrc, alt, className,
}) => (
  <LazyLoadImage
    src={src}
    placeholderSrc={placeholderSrc || null}
    alt={alt}
    effect="blur"
    className={[
      styles.image,
      className,
    ].join(' ')}
  />
);

LazyImage.defaultProps = {
  placeholderSrc: '',
  className: '',
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  placeholderSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LazyImage;
