import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import NewsletterSubscribeDetail from 'components/NewsletterSubscribeDetail';

import Body from 'components/BlogPostLayout/Body';
import Header from 'components/BlogPostLayout/Header';
import Subheader from 'components/BlogPostLayout/Subheader';
import Quote from 'components/BlogPostLayout/Quote';
import Image from 'components/BlogPostLayout/Image';
import Video from 'components/BlogPostLayout/Video';
import Slider from 'components/BlogPostLayout/Slider';

import { ACCENTS } from 'constants/themes';

import styles from './styles.module.scss';

const BlogPostLayout = ({
  date, title, children,
}) => (
  <>
    <SiteHeader
      theme="dark"
      accent={ACCENTS.blog}
    />

    {/* HEADER SECTION */}
    <Section
      theme="dark"
      offset="upper"
      accent={ACCENTS.blog}
    >
      <Copy
        variant="section"
        title={title}
        body={(
          <Moment
            date={date}
            format="MMMM D, YYYY"
          />
          )}
      />
    </Section>

    {/* BODY SECTION */}
    <Section
      theme="white"
      offset="lower"
      accent={ACCENTS.blog}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <div className={styles.article}>
            {children}
          </div>
          <NewsletterSubscribeDetail />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="light" />
  </>
);

BlogPostLayout.defaultProps = {
  children: null,
};

BlogPostLayout.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

BlogPostLayout.Body = Body;
BlogPostLayout.Header = Header;
BlogPostLayout.Subheader = Subheader;
BlogPostLayout.Quote = Quote;
BlogPostLayout.Image = Image;
BlogPostLayout.Video = Video;
BlogPostLayout.Slider = Slider;

export default BlogPostLayout;
