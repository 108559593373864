import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Subheader = ({ children }) => (
  <h2 className={styles.subheader}>{children}</h2>
);

Subheader.defaultProps = {
  children: null,
};

Subheader.propTypes = {
  children: PropTypes.node,
};

export default Subheader;
