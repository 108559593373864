import React from 'react';
import PropTypes from 'prop-types';

import CTAButton from 'components/CTAButton';

import styles from './styles.module.scss';

const Quote = ({
  text, noQuotes, attribution, attributionTitle, attributionSource,
}) => (
  <div className={styles.quote}>
    {!noQuotes
      && (
      <span className={styles['quote-symbol']}>
        &ldquo;
      </span>
      )}
    <div className={styles['quote-text']}>
      {text}
    </div>
    {attribution
      && (
      <div className={styles['quote-attribution-name']}>
        {attribution}
      </div>
      )}
    {attributionTitle
      && (
      <div className={styles['quote-attribution-title']}>
        {attributionTitle}
      </div>
      )}
    {attributionSource
      && (
      <CTAButton
        to={attributionSource}
        variant="link"
        text="Source"
        className={styles['quote-attribution-source']}
      />
      )}
  </div>
);

Quote.defaultProps = {
  noQuotes: false,
  attribution: '',
  attributionTitle: '',
  attributionSource: '',
};

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  noQuotes: PropTypes.bool,
  attribution: PropTypes.string,
  attributionTitle: PropTypes.string,
  attributionSource: PropTypes.string,
};

export default Quote;
