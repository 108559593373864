import React from 'react';
import { Redirect } from 'react-router-dom';

import DocsHome from 'pages/docs/home';
import DocsGettingStarted from 'pages/docs/getting-started';
import DocsGettingStartedRequest from 'pages/docs/getting-started/request';
import DocsGettingStartedResponse from 'pages/docs/getting-started/response';
import DocsGettingStartedAuthentication from 'pages/docs/getting-started/authentication';
import DocsGettingStartedBilling from 'pages/docs/getting-started/billing';
import DocsGettingStartedRateLimits from 'pages/docs/getting-started/rate-limits';
import DocsSapien from 'pages/docs/sapien';
import DocsSapienCore from 'pages/docs/sapien/core';
import DocsSapienTransform from 'pages/docs/sapien/transform';
import DocsSapienNames from 'pages/docs/sapien/names';
import DocsCortex from 'pages/docs/cortex';
import DocsCortexStructured from 'pages/docs/cortex/structured';
import DocsCortexUnstructured from 'pages/docs/cortex/unstructured';
import DocsLingua from 'pages/docs/lingua';

import {
  SapienLine,
  CortexLine,
  LinguaLine,
} from 'components/Icons';
import { FiHome } from 'react-icons/fi';
import { RiLeafLine } from 'react-icons/ri';

export const __docsHome = {
  path: '/docs',
  exact: true,
  component: DocsHome,
  nav: {
    text: 'Home',
    icon: <FiHome />,
  },
};

export const __docsGettingStarted = {
  path: '/docs/getting-started',
  exact: true,
  component: DocsGettingStarted,
  nav: {
    text: 'Getting Started',
    icon: <RiLeafLine />,
  },
};

export const __docsGettingStartedRequest = {
  path: '/docs/getting-started/request',
  exact: true,
  component: DocsGettingStartedRequest,
  nav: {
    text: 'API Request Format',
  },
};

export const __docsGettingStartedResponse = {
  path: '/docs/getting-started/response',
  exact: true,
  component: DocsGettingStartedResponse,
  nav: {
    text: 'API Response Format',
  },
};

export const __docsGettingStartedAuthentication = {
  path: '/docs/getting-started/authentication',
  exact: true,
  component: DocsGettingStartedAuthentication,
  nav: {
    text: 'Authentication',
  },
};

export const __docsGettingStartedAuthenticationHttpAuthorizationHeader = {
  hash: 'http-authorization-header',
  path: `${__docsGettingStartedAuthentication.path}#http-authorization-header`,
  nav: {
    text: 'HTTP Authorization Header',
  },
};

export const __docsGettingStartedAuthenticationApiKeyParameter = {
  hash: 'api-key-parameter',
  path: `${__docsGettingStartedAuthentication.path}#api-key-parameter`,
  nav: {
    text: 'API Key Parameter',
  },
};

export const __docsGettingStartedAuthenticationSession = {
  hash: 'session',
  path: `${__docsGettingStartedAuthentication.path}#session`,
  nav: {
    text: 'Session',
  },
};

export const __docsGettingStartedBilling = {
  path: '/docs/getting-started/billing',
  exact: true,
  component: DocsGettingStartedBilling,
  nav: {
    text: 'Billing',
  },
};

export const __docsGettingStartedRateLimits = {
  path: '/docs/getting-started/rate-limits',
  exact: true,
  component: DocsGettingStartedRateLimits,
  nav: {
    text: 'Rate Limits',
  },
};

export const __docsSapien = {
  path: '/docs/sapien',
  exact: true,
  component: DocsSapien,
  nav: {
    text: 'Sapien Language Engine',
    icon: <SapienLine />,
  },
};

export const __docsSapienCore = {
  path: '/docs/sapien/core',
  exact: true,
  component: DocsSapienCore,
  nav: {
    text: 'Core',
  },
};

export const __docsSapienCoreTokenization = {
  hash: 'tokenization',
  path: `${__docsSapienCore.path}#tokenization`,
  nav: {
    text: 'Tokenization',
  },
};

export const __docsSapienCorePartsOfSpeech = {
  hash: 'pos',
  path: `${__docsSapienCore.path}#pos`,
  nav: {
    text: 'Parts of Speech',
  },
};

export const __docsSapienCoreLemmatization = {
  hash: 'lemmatization',
  path: `${__docsSapienCore.path}#lemmatization`,
  nav: {
    text: 'Lemmatization',
  },
};

export const __docsSapienCoreSyntaxDependencyTrees = {
  hash: 'syntax-dependency-trees',
  path: `${__docsSapienCore.path}#syntax-dependency-trees`,
  nav: {
    text: 'Syntax Dependency Trees',
  },
};

export const __docsSapienCoreSentenceGraph = {
  hash: 'sentence-graph',
  path: `${__docsSapienCore.path}#sentence-graph`,
  nav: {
    text: 'Sentence Graph',
  },
};

export const __docsSapienCoreNamedEntityRecognition = {
  hash: 'ner',
  path: `${__docsSapienCore.path}#ner`,
  nav: {
    text: 'Named Entity Recognition',
  },
};

export const __docsSapienCoreDisambiguation = {
  hash: 'disambiguation',
  path: `${__docsSapienCore.path}#disambiguation`,
  nav: {
    text: 'Disambiguation',
  },
};

export const __docsSapienCoreCoreferenceResolution = {
  hash: 'coreference',
  path: `${__docsSapienCore.path}#coreference`,
  nav: {
    text: 'Coreference Resolution',
  },
};

export const __docsSapienCoreSentimentAnalysis = {
  hash: 'sentiment',
  path: `${__docsSapienCore.path}#sentiment`,
  nav: {
    text: 'Sentiment Analysis',
  },
};

export const __docsSapienTransform = {
  path: '/docs/sapien/transform',
  exact: true,
  component: DocsSapienTransform,
  nav: {
    text: 'Transform',
  },
};

export const __docsSapienTransformNounPluralization = {
  hash: 'noun-pluralization',
  path: `${__docsSapienTransform.path}#noun-pluralization`,
  nav: {
    text: 'Noun Pluralization',
  },
};

export const __docsSapienTransformVerbConjugation = {
  hash: 'verb-conjugation',
  path: `${__docsSapienTransform.path}#verb-conjugation`,
  nav: {
    text: 'Verb Conjugation',
  },
};

export const __docsSapienTransformAdjectiveTransformation = {
  hash: 'adjective-transformation',
  path: `${__docsSapienTransform.path}#adjective-transformation`,
  nav: {
    text: 'Adjective Transformation',
  },
};

export const __docsSapienTransformAdverbTransformation = {
  hash: 'adverb-transformation',
  path: `${__docsSapienTransform.path}#adverb-transformation`,
  nav: {
    text: 'Adverb Transformation',
  },
};

export const __docsSapienNames = {
  path: '/docs/sapien/names',
  exact: true,
  component: DocsSapienNames,
  nav: {
    text: 'Names',
  },
};

export const __docsCortex = {
  path: '/docs/cortex',
  exact: true,
  component: DocsCortex,
  nav: {
    text: 'Cortex Knowledge Graph',
    icon: <CortexLine />,
  },
};

export const __docsCortexStructured = {
  path: '/docs/cortex/structured',
  exact: true,
  component: DocsCortexStructured,
  nav: {
    text: 'Structured Graph',
  },
};

export const __docsCortexStructuredEntitySearch = {
  hash: 'entity',
  path: `${__docsCortexStructured.path}#entity`,
  nav: {
    text: 'Entity Search',
  },
};

export const __docsCortexStructuredPropertySearch = {
  hash: 'property',
  path: `${__docsCortexStructured.path}#property`,
  nav: {
    text: 'Property Search',
  },
};

export const __docsCortexStructuredCategorySearch = {
  hash: 'category',
  path: `${__docsCortexStructured.path}#category`,
  nav: {
    text: 'Category Search',
  },
};

export const __docsCortexStructuredEntityAndPropertySearch = {
  hash: 'entity-and-property',
  path: `${__docsCortexStructured.path}#entity-and-property`,
  nav: {
    text: 'Entity and Property Search',
  },
};

export const __docsCortexStructuredNaturalLanguageQA = {
  hash: 'nl-qa',
  path: `${__docsCortexStructured.path}#nl-qa`,
  nav: {
    text: 'Natural Language QA',
  },
};

export const __docsCortexUnstructured = {
  path: '/docs/cortex/unstructured',
  exact: true,
  component: DocsCortexUnstructured,
  nav: {
    text: 'Unstructured Graph',
  },
};

export const __docsLingua = {
  path: '/docs/lingua',
  exact: true,
  component: DocsLingua,
  nav: {
    text: 'Lingua Dialogue Engine',
    icon: <LinguaLine />,
  },
};

export const rrdocsFallback = {
  path: '*',
  render: () => <Redirect to={__docsHome.path} />,
};

/**
 * Export the docs routes.
 *
 * Note: ORDERING MATTERS!
 * These should be ordered from least to most specific
 * since the Switch statement will use the reverse of
 * this ordering. For example, the fallback redirect
 * route should be the first item in the object (even
 * though it is the last thing that should match).
 * @type {Object}
 */
/* eslint-disable indent */
export const docsRoutes = {
  rrdocsFallback,
  __docsHome,
  __docsGettingStarted,
    __docsGettingStartedRequest,
    __docsGettingStartedResponse,
    __docsGettingStartedAuthentication,
    __docsGettingStartedBilling,
    __docsGettingStartedRateLimits,
  __docsSapien,
    __docsSapienCore,
    __docsSapienTransform,
    __docsSapienNames,
  __docsCortex,
    __docsCortexStructured,
    __docsCortexUnstructured,
  __docsLingua,
};
/* eslint-enable indent */

export default docsRoutes;
