import React from 'react';
import PropTypes from 'prop-types';
import Tilt from 'react-tilt';

import styles from './styles.module.scss';

const Card = ({ tilt, children, className }) => {
  const outerClassName = [
    styles.card,
    className,
  ].join(' ');

  if (tilt) {
    return (
      <Tilt
        className={outerClassName}
        options={{ max: 25, scale: 1.05 }}
      >
        {children}
      </Tilt>
    );
  }

  return (
    <div className={outerClassName}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  tilt: false,
  children: null,
  className: '',
};

Card.propTypes = {
  tilt: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
