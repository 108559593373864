import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import {
  __pricing,
  xxAppPayment,
} from 'constants/routes';

const Billing = () => (
  <DocLayout>
    <DocLayout.Title>
      Billing
    </DocLayout.Title>
    <DocLayout.Body>
      Plasticity APIs that require payment will work until
      you reach the free tier cap for a particular service.
    </DocLayout.Body>
    <DocLayout.Body>
      If you exceed the free tier cap for a particular service
      and have no payment information on file, requests will
      fail until you
      {' '}
      <Link
        to={xxAppPayment.path}
        target="_blank"
      >
        enter your payment information
      </Link>
      .
    </DocLayout.Body>
    <DocLayout.Body>
      You will be billed monthly based on the number of requests
      made and the
      {' '}
      <Link to={__pricing.path}>pricing schedule</Link>
      {' '}
      for the particular service. All requests are billed at the
      same CPM rate once you reach each tier of the pricing schedule.
    </DocLayout.Body>
    <DocLayout.Body>
      If at any point your payment information becomes invalid
      (e.g. expired credit card, etc.), API requests that exceed
      the free tier cap will continue to work to avoid service
      disruption to your application. Please update your payment
      information immediately when this happens to prevent your
      account from being placed on hold.
    </DocLayout.Body>
  </DocLayout>
);

export default Billing;
