import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const GridLayout = ({ children }) => (
  <div className={styles.grid}>
    {children}
  </div>
);

GridLayout.defaultProps = {
  children: null,
};

GridLayout.propTypes = {
  children: PropTypes.node,
};

export default GridLayout;
