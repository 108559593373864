import React from 'react';
import { Helmet } from 'react-helmet-async';

const TITLE = 'Plasticity · Natural Language Processing APIs';
const DESCRIPTION = 'Plasticity is a simple, powerful Natural Language Processing API built for developers. Use our API to handle tokenization, parts of speech tagging, dependency parsing, relation extraction, named entity recognition, and more.';
const TAGS = [
  'plasticity',
  'natural language processing',
  'nlp',
  'api',
  'artificial intelligence',
  'ai',
  'sapien',
  'cortex',
  'lingua',
  'brevis',
  'yc',
  'ycombinator',
  'y combinator',
].join(',');
const LOGO_PATH = `${process.env.PUBLIC_URL}/logos/logo-horizontal-black_on_white@2x.png`;

const DefaultHelmet = () => (
  <Helmet>
    {/* Title, description, etc. */}
    <title>{TITLE}</title>
    <meta
      name="title"
      content={TITLE}
    />
    <meta
      name="description"
      content={DESCRIPTION}
    />
    <meta
      name="keywords"
      content={TAGS}
    />

    {/* Facebook */}
    <meta
      property="og:title"
      content={TITLE}
    />
    <meta
      property="og:image"
      content={LOGO_PATH}
    />
    <meta
      property="og:description"
      content={DESCRIPTION}
    />
    <meta
      property="og:locale"
      content="en_US"
    />

    {/* Twitter */}
    <meta
      name="twitter:card"
      content="summary"
    />
    <meta
      name="twitter:site"
      content="@plasticityai"
    />
    <meta
      name="twitter:title"
      content={TITLE}
    />
    <meta
      name="twitter:description"
      content={DESCRIPTION}
    />
    <meta
      name="twitter:image"
      content={LOGO_PATH}
    />

    {process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' && (
      <meta
        name="robots"
        content="noindex, nofollow"
      />
    )}
  </Helmet>
);

export default DefaultHelmet;
