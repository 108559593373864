import React from 'react';

import DocLayout from 'components/DocLayout';

const Lingua = () => (
  <DocLayout>
    <DocLayout.Title>
      Lingua Dialogue Engine
    </DocLayout.Title>
    <DocLayout.Body>
      Provides human-like natural language responses to
      questions or intents. Use this API for dialogue in
      your conversational interface to prevent predictable
      robotic responses.
    </DocLayout.Body>
    <DocLayout.ComingSoon showSalesMessage />
  </DocLayout>
);

export default Lingua;
