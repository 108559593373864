import { get } from 'lodash';

import {
  aaCompetitor,
  aaSapien,
  aaSapienDemo,
  aaCortex,
  aaCortexDemo,
} from 'constants/routes';

const DEFAULT_ERROR = 'An unexpected error occurred. Please try again.';

export const convertToDemoEndpoint = (path) => (
  path
    .replace(aaSapien.path, aaSapienDemo.path)
    .replace(aaCortex.path, aaCortexDemo.path)
);

export const makeApiRequest = async (path, body) => {
  const response = await fetch(path, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  if (response.status === 429) {
    throw new Error(get(json, 'message'));
  }

  if (response.status !== 200) {
    throw new Error(DEFAULT_ERROR);
  }

  return json;
};

export const makeCompetitorApiRequest = async (body) => {
  const response = await fetch(aaCompetitor.path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  if (response.status !== 200) {
    throw new Error(DEFAULT_ERROR);
  }

  if (response.status === 200 && get(json, 'error', false)) {
    throw new Error(get(response, 'responseJSON.message', DEFAULT_ERROR));
  }

  return json;
};
