import React from 'react';

import Card from 'components/Card';
import SentenceFactVisualizer from 'components/SentenceFactVisualizer';

import styles from './styles.module.scss';

const SPANS = [
  'Plasticity',
  ',',
  ' ',
  'founded',
  ' ',
  'in 2016',
  ' ',
  'in San Francisco',
  ',',
  ' ',
  'makes',
  ' ',
  'natural language processing',
  ' ',
  'tools',
  ' ',
  'and',
  ' ',
  'solutions',
  ' ',
  'for',
  ' ',
  'commercial customers',
  ',',
  ' ',
  'government organizations',
  ',',
  ' ',
  'and',
  ' ',
  'developers',
  '.',
];

const FACTS = {
  'Plasticity was founded in 2016': [0, 3, 5],
  'Plasticity was founded in San Francisco': [0, 3, 7],
  'Plasticity makes natural language processing tools for commercial customers': [0, 10, 12, 14, 20, 22],
  'Plasticity makes natural language processing tools for government organizations': [0, 10, 12, 14, 20, 25],
  'Plasticity makes natural language processing tools for developers': [0, 10, 12, 14, 20, 30],
  'Plasticity makes natural language processing solutions for commercial customers': [0, 10, 12, 18, 20, 22],
  'Plasticity makes natural language processing solutions for government organizations': [0, 10, 12, 18, 20, 25],
  'Plasticity makes natural language processing solutions for developers': [0, 10, 12, 18, 20, 30],
};

const HomeHero = () => (
  <Card className={styles.hero}>
    <SentenceFactVisualizer
      facts={FACTS}
      spans={SPANS}
    />
  </Card>
);

export default HomeHero;
