import React from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';

import List from 'components/List';

import styles from './styles.module.scss';

const Checklist = ({ hasIcon, columns, data }) => (
  <List columns={columns}>
    {data.map((item, index) => (
      <li
        key={index}
        className={styles.item}
      >
        {hasIcon && <FaCheckCircle className={styles.icon} />}
        <span className={styles.text}>{item}</span>
      </li>
    ))}
  </List>
);

Checklist.defaultProps = {
  hasIcon: true,
  columns: 1,
};

Checklist.propTypes = {
  hasIcon: PropTypes.bool,
  columns: PropTypes.oneOf([1, 2, 3, 4]),
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Checklist;
