/* eslint-disable import/no-named-as-default-member */

import { lazy } from 'react';

import Home from 'pages/home';
import Government from 'pages/government';
import Commercial from 'pages/commercial';
import Developers from 'pages/developers';
import Sapien from 'pages/api/sapien';
import Cortex from 'pages/api/cortex';
import Lingua from 'pages/api/lingua';
import Pricing from 'pages/pricing';
import About from 'pages/about';
import ContactUs from 'pages/contact-us';
import ScheduleDemo from 'pages/schedule-demo';
import Terms from 'pages/terms';
import Eula from 'pages/eula';
import PrivacyPolicy from 'pages/privacy';
import News from 'pages/news';
import NotFound from 'pages/404';

import paths from 'constants/paths';

/**
 * Use `xx` at the start of variable name to represent an external route
 * Use `__` at the start of variable name to represent the root of an internal route
 * Use `_` in a variable name to represent a match variable in the route
 *
 * Route variables are as follows:
 *
 * {
 *   path: string - the path of the route,
 *   exact: boolean - if it must be an exact match,
 *   component: ReactNode - the component to render at the route,
 *   nav: {
 *     text: string - the text that should be used in nav items (menus, etc.),
 *     icon: ReactNode - the icon that should accompany text in nav items,
 *   },
 * };
 */

/**
 * BEGIN INTERNAL ROUTE DEFINITIONS
 */

export const __ = {
  path: paths.__,
  exact: true,
  component: Home,
};

export const __government = {
  path: paths.__government,
  exact: true,
  component: Government,
};

export const __commercial = {
  path: paths.__commercial,
  exact: true,
  component: Commercial,
};

export const __developers = {
  path: paths.__developers,
  exact: true,
  component: Developers,
};

export const __apiSapien = {
  path: paths.__apiSapien,
  exact: true,
  component: Sapien,
};

export const __apiCortex = {
  path: paths.__apiCortex,
  exact: true,
  component: Cortex,
};

export const __apiLingua = {
  path: paths.__apiLingua,
  exact: true,
  component: Lingua,
};

export const __demo = {
  path: paths.__demo,
  exact: false,
  component: lazy(() => import('pages/demo')),
};

export const __docs = {
  path: paths.__docs,
  exact: false,
  component: lazy(() => import('pages/docs')),
};

export const __blog = {
  path: paths.__blog,
  exact: false,
  component: lazy(() => import('pages/blog')),
};

export const __news = {
  path: paths.__news,
  exact: false,
  component: News,
};

export const __openSource = {
  hash: 'open-source',
  path: `${__developers.path}#open-source`,
};

export const __pricing = {
  path: paths.__pricing,
  exact: true,
  component: Pricing,
};

export const __about = {
  path: paths.__about,
  exact: true,
  component: About,
};

export const __scheduleDemo = {
  path: paths.__scheduleDemo,
  exact: true,
  component: ScheduleDemo,
};

export const __contactUs = {
  path: paths.__contactUs,
  exact: true,
  component: ContactUs,
};

export const __terms = {
  path: paths.__terms,
  exact: true,
  component: Terms,
};

export const __termsNotice = {
  hash: 'api-updates-and-important-changes',
  path: `${__terms.path}#api-updates-and-important-changes`,
};

export const __eula = {
  path: paths.__eula,
  exact: true,
  component: Eula,
};

export const __privacy = {
  path: paths.__privacy,
  exact: true,
  component: PrivacyPolicy,
};

export const __404 = {
  path: paths.__404,
  component: NotFound,
};

/**
 * BEGIN REDIRECT ROUTE DEFINITIONS
 */

export const rrApi = {
  exact: true,
  from: '/api',
  to: __developers.path,
};

export const rrApiDemo = {
  exact: true,
  from: '/api/demo',
  to: __demo.path,
};

export const rrApiDocs = {
  exact: true,
  from: '/api/docs',
  to: __docs.path,
};

export const rrApiPricing = {
  exact: true,
  from: '/api/pricing',
  to: __pricing.path,
};

/**
 * BEGIN API ROUTE DEFINITIONS
 */

export const aa = {
  path: paths.aa,
};

export const aaCompetitor = {
  path: paths.aaCompetitor,
};

export const aaSapien = {
  path: paths.aaSapien,
};

export const aaSapienCore = {
  path: paths.aaSapienCore,
};

export const aaSapienTransform = {
  path: paths.aaSapienTransform,
};

export const aaSapienNames = {
  path: paths.aaSapienNames,
};

export const aaCortex = {
  path: paths.aaCortex,
};

export const aaCortexStructured = {
  path: paths.aaCortexStructured,
};

export const aaSapienDemo = {
  path: paths.aaSapienDemo,
};

export const aaSapienDemoCore = {
  path: paths.aaSapienDemoCore,
};

export const aaSapienDemoTransform = {
  path: paths.aaSapienDemoTransform,
};

export const aaSapienDemoNames = {
  path: paths.aaSapienDemoNames,
};

export const aaCortexDemo = {
  path: paths.aaCortexDemo,
};

export const aaCortexDemoStructured = {
  path: paths.aaCortexDemoStructured,
};

/**
 * BEGIN EXTERNAL ROUTE DEFINITIONS
 */

export const xxLanding = {
  path: paths.xxLanding,
};

export const xxLandingStaging = {
  path: paths.xxLandingStaging,
};

export const xxSitemap = {
  path: paths.xxSitemap,
};

export const xxApp = {
  path: paths.xxApp,
};

export const xxAppLogin = {
  path: paths.xxAppLogin,
};

export const xxAppSignup = {
  path: paths.xxAppSignup,
};

export const xxAppPayment = {
  path: paths.xxAppPayment,
};

export const xxAppKeys = {
  path: paths.xxAppKeys,
};

export const xxBenchmarkSapien = {
  path: paths.xxBenchmarkSapien,
};

export const xxGithub = {
  path: paths.xxGithub,
};

export const xxTwitter = {
  path: paths.xxTwitter,
};

/**
 * BEGIN COMBINATION EXPORTS
 *
 * These should be ordered by increasing specificity
 * in path level, so the route for /level1 should
 * come before the route for /level1/level2.
 */

/* eslint-disable indent */
export const internalRoutes = {
  __,
    __government,
    __commercial,
    __developers,
      __apiSapien,
      __apiCortex,
      __apiLingua,
    __demo,
    __docs,
    __blog,
    __news,
    __pricing,
    __about,
    __scheduleDemo,
    __contactUs,
    __terms,
    __termsNotice,
    __eula,
    __privacy,
    __404, // catch-all, should come last
};
/* eslint-enable indent */

/* eslint-disable indent */
export const redirectRoutes = {
  rrApi,
    rrApiDemo,
    rrApiDocs,
    rrApiPricing,
};
/* eslint-enable indent */

/* eslint-disable indent */
export const apiRoutes = {
  aa,
  aaCompetitor,
  aaSapien,
  aaSapienCore,
  aaSapienTransform,
  aaSapienNames,
  aaCortex,
  aaCortexStructured,
  aaSapienDemo,
  aaSapienDemoCore,
  aaSapienDemoTransform,
  aaSapienDemoNames,
  aaCortexDemo,
  aaCortexDemoStructured,
};
/* eslint-enable indent */

/* eslint-disable indent */
export const externalRoutes = {
  xxLanding,
  xxLandingStaging,
  xxSitemap,
  xxApp,
    xxAppLogin,
    xxAppSignup,
    xxAppPayment,
    xxAppKeys,
  xxBenchmarkSapien,
  xxGithub,
  xxTwitter,
};
/* eslint-enable indent */

export default internalRoutes;
