import React from 'react';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import CTAButton from 'components/CTAButton';

import { ACCENTS } from 'constants/themes';
import {
  __contactUs,
  __scheduleDemo,
} from 'constants/routes';

const About = () => (
  <>
    <SiteHeader
      theme="light"
      accent={ACCENTS.home}
    />
    <Section
      theme="light"
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Our story"
            title="About Plasticity"
            body={(
              <>
                <p>
                  Plasticity makes simple, powerful natural language
                  processing technology open and accessible to
                  companies, organizations, and developers.
                </p>
                <p>
                  Since 2016, Plasticity has provided hundreds of
                  companies and thousands of developers with NLP
                  tools and solutions. Today, these include custom solutions
                  for monitoring social media and news, grouping
                  and analyzing documents, search, and structuring
                  unstructured information. We also provide the
                  underlying NLP technology powering these solutions
                  as APIs for developers, including the Sapien Language
                  Engine for low-level NLP tasks and the Cortex Knowledge
                  Graph for information about the real world.
                </p>
                <p>
                  In 2020, Plasticity was acquired by Executive 1
                  Holding Company to further our mission of providing
                  customers with state-of-the-art NLP technology.
                </p>
              </>
            )}
            footer={(
              <>
                <CTAButton
                  text="Contact us"
                  variant="button"
                  to={__contactUs.path}
                />
                <CTAButton
                  text="Book a demo"
                  variant="link"
                  to={__scheduleDemo.path}
                />
              </>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default About;
