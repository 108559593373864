import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

import styles from './styles.module.scss';

const JSONViewer = ({ src, ...rest }) => (
  <div className={styles.viewer}>
    <ReactJson
      src={src}
      name={false}
      {...rest}
    />
  </div>
);

JSONViewer.propTypes = {
  src: PropTypes.object.isRequired,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default JSONViewer;
