import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import { SUPPORT_EMAIL } from 'constants/contact';

const RateLimits = () => (
  <DocLayout>
    <DocLayout.Title>
      Rate Limits
    </DocLayout.Title>
    <DocLayout.Body>
      The Plasticity APIs may establish rate limiting over
      certain API endpoints.
    </DocLayout.Body>
    <DocLayout.Body>
      If at any point too many requests are made within a
      given period, the API endpoint will send an error
      response until the appropriate time interval has
      passed and requests can be made again. For most
      applications, this will not be an issue. If your
      application requires an exception, please email us at
      {' '}
      <Link to={`mailto:${SUPPORT_EMAIL}?subject=${encodeURI('Rate Limiting')}`}>{SUPPORT_EMAIL}</Link>
      .
    </DocLayout.Body>
    <DocLayout.Body>
      The HTTP Response headers returned with each request
      are the best way to know what the rate limit restrictions
      are and how many requests can be made without exceeding
      the rate limiter.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Response Header Format
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Header',
        'Value',
      ]}
      rows={[
        [
          <DocLayout.InlineCode>
            X-Ratelimit-Limit
          </DocLayout.InlineCode>,
          <>
            The total number of requests that can be made in the
            current period.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            X-Ratelimit-Remaining
          </DocLayout.InlineCode>,
          <>
            The number of requests remaining that can be made in
            the current period.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            X-Ratelimit-Reset
          </DocLayout.InlineCode>,
          <>
            The UNIX timestamp for when the next period will begin.
          </>,
        ],
      ]}
    />
  </DocLayout>
);

export default RateLimits;
