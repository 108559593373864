import NewsHome from 'pages/blog/home';

export const __newsHome = {
  path: '/news',
  exact: true,
  component: NewsHome,
};

export const __newsPlasticityJoinsEx1 = {
  path: '/news/plasticity-joins-ex1',
  to: 'https://www.newswire.com/news/executive-1-holding-company-completes-acquisition-of-plasticity-ai-21236902',
  exact: true,
  meta: {
    date: new Date('Oct 15 2020'),
    title: 'Executive 1 Holding Company Completes Acquisition of Plasticity AI',
    shortDescription: 'Plasticity is joining Executive 1 Holding Company to bring its NLP and AI capabilities to help defense and commercial customers make sense of unstructured data.',
    longDescription: '',
    source: 'Newswire',
    tags: [
      'news',
      'company',
    ],
  },
};

export const __newsCnnNewDayYoutubeDisinformation = {
  path: '/news/cnn-new-day-youtube-disinformation',
  to: 'https://www.youtube.com/watch?v=QtSTPeMOFQQ',
  exact: true,
  meta: {
    date: new Date('Dec 13 2019'),
    title: 'Plasticity Featured on CNN New Day',
    shortDescription: 'Following CNN\'s coverage of Plasticity\'s NLP tools for monitoring social media, CNN\'s Politics and Technology Reporter, Donie O\'Sullivan, discusses Plasticity on CNN New Day.',
    longDescription: '',
    source: 'CNN',
    tags: [
      'news',
    ],
  },
};

export const __newsAirForceTransitionAccelerator = {
  path: '/news/air-force-transition-accelerator',
  to: 'https://www.afmc.af.mil/News/Article-Display/Article/1821086/air-force-transition-accelerator-demonstrates-fast-smart-approach-to-partnering/',
  exact: true,
  meta: {
    date: new Date('Apr 23 2019'),
    title: 'Plasticity in Air Force Transition Accelerator',
    shortDescription: 'Plasticity participates in Demo Day for the Air Force\'s Transition Accelerator, a partnership between AFRL\'s Airman Systems Directorate, the Wright Brothers Institute, and The Entrepreneur Center.',
    longDescription: '',
    source: 'Air Force Materiel Command',
    tags: [
      'news',
    ],
  },
};

export const __newsMagnitudeTop10 = {
  path: '/news/magnitude-top-10-ml-open-source',
  to: 'https://medium.mybridge.co/machine-learning-open-source-of-the-month-v-july-2018-eb41780e5a76',
  exact: true,
  meta: {
    date: new Date('Jul 23 2018'),
    title: 'Magnitude - Top 10 Machine Learning Open Source of the Month',
    shortDescription: 'Magnitude, a Python package for utilizing vector embeddings in ML models built by Plasticity is featured in Mybridge\'s Machine Learning Open Source of the Month rankings.',
    longDescription: '',
    source: 'Mybridge',
    tags: [
      'news',
    ],
  },
};

export const newsLinks = [
  __newsPlasticityJoinsEx1,
  __newsCnnNewDayYoutubeDisinformation,
  __newsAirForceTransitionAccelerator,
  __newsMagnitudeTop10,
];

/* eslint-disable indent */
export const newsRoutes = {
  ...newsLinks,
  __newsHome,
};
/* eslint-enable indent */

export default newsRoutes;
