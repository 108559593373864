import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const InlineCode = ({ children, ...rest }) => (
  <code
    className={styles.code}
    {...rest}
  >
    {children}
  </code>
);

InlineCode.defaultProps = {
  children: null,
};

InlineCode.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default InlineCode;
