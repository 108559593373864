import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const APIEmbed = ({
  id, url, methods, sourceUrl, targets,
}) => {
  const targetsStr = targets.join(',');
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <code>
          <span className={styles.url}>{url}</span>
          {methods.map((method) => (
            <span
              key={method}
              className={styles.method}
            >
              {method}
            </span>
          ))}
        </code>
      </div>
      <div className={styles.source}>
        <iframe
          title="APIEmbed"
          id={id}
          src={`//api.apiembed.com/?source=${sourceUrl}&targets=${targetsStr}`}
          frameBorder="0"
          scrolling="no"
          width="100%"
          height="400"
          seamless
        />
      </div>
    </div>
  );
};

APIEmbed.defaultProps = {
  methods: ['GET', 'POST'],
  targets: [
    'shell:curl',
    'python:requests',
    'java:unirest',
    'javascript:jquery', // eslint-disable-line no-script-url
    'node:unirest',
    'php:curl',
    'ruby:native',
    'c:libcurl',
    'csharp:restsharp',
    'objc:nsurlsession',
    'swift:nsurlsession',
    'go:native',
  ],
};

APIEmbed.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  methods: PropTypes.arrayOf(PropTypes.string),
  sourceUrl: PropTypes.string.isRequired,
  targets: PropTypes.arrayOf(PropTypes.string),
};

export default APIEmbed;
