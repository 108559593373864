import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Video = ({ src }) => (
  <div className={styles['video-container']}>
    <iframe
      className={styles.video}
      src={src}
      title="Video"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

Video.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Video;
