import React from 'react';

import BlogPostLayout from 'components/BlogPostLayout';
import Link from 'components/Link';

import { __ } from 'constants/routes';

export const meta = {
  date: new Date('Dec 28 2020'),
  title: 'Gary Kessler Joins Advisory Board of Executive 1 Holding Company',
  shortDescription: 'Plasticity and EX1 add Gary Kessler to its Advisory Board.',
  longDescription: 'Executive 1 Holding Company, LLC (EX1) is pleased to announce that Gary K. Kessler will join its Advisory Board this week. Mr. Kessler is the former Deputy Assistant Secretary of the Navy (Air Programs) and has served for over 35 years in the Senior Executive Service to the federal Government in program management, engineering, flight testing, weapons, strategy, and budget.',
  category: 'company',
  tags: [
    'people',
  ],
};

const imageFolder = `${process.env.PUBLIC_URL}/blog/posts/gary-kessler-joins-board/images`;

const GaryKesslerJoinsBoard = () => (
  <BlogPostLayout {...meta}>
    <BlogPostLayout.Body>
      Executive 1 Holding Company, LLC (EX1) is pleased to announce that
      Gary K. Kessler will join its Advisory Board this week. Mr. Kessler is
      the former Deputy Assistant Secretary of the Navy (Air Programs) and has
      served for over 35 years in the Senior Executive Service to the federal
      Government in program management, engineering, flight testing, weapons,
      strategy, and budget.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      EX1 holds and invests in leading consulting and technology solutions
      companies collaborating with the federal Government, working at the
      forefront of organizational transformation using artificial intelligence
      (AI), machine learning (ML), and natural language processing (NLP).
    </BlogPostLayout.Body>

    <BlogPostLayout.Image
      src={`${imageFolder}/garykessler.jpg`}
      placeholderSrc={`${imageFolder}/garykessler_low.jpg`}
      alt="Gary Kessler"
      caption="Gary Kessler"
    />

    <BlogPostLayout.Header>
      About Gary K. Kessler
    </BlogPostLayout.Header>

    <BlogPostLayout.Body>
      Mr. Kessler is currently an Independent Consultant for his own company,
      Kessler Integrated Systems Solutions, LLC. In this capacity, he performs
      a broad range of technical, management and organizational efforts in
      support of government, military, and industry. He also consults on
      strategic planning, program management, work force development, systems
      engineering and test and evaluation (T&E), primarily focused on U.S.
      Navy manned/unmanned platforms and weapon systems. Further, Mr. Kessler
      is a Board member for several businesses, including The Patuxent
      Partnership, and is the current President of the Southern Maryland Navy
      Alliance.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      Mr. Kessler started his career as a flight test engineer and has a long
      career of working in the U.S. Navy to deliver high performance
      capabilities within budgetary limitations and rigid timelines. His
      model-based systems engineering programs were some of the first of
      their kind, and he has long applied commercial best practices within the
      DoD to successfully run acquisition programs for NAVAIR, as well as
      directing all U.S. Navy and Marine Corps Unmanned Aviation programs.
      Mr. Kessler graduated from the University of Maryland in Aeronautical
      and Aerospace Engineering, and later received a Masters&apos; degree in
      Business Administration. He is a graduate of the Defense Systems
      Management College, the Senior Executive Management Development Program,
      and the Leadership Maryland Program.  Personal awards include the
      Secretary of Defense Superior Management Award, FCT Program Manager
      of the Year, ANA Edward H. Heineman Award, University of Maryland
      Glenn L. Martin Medal and the Meritorious, Superior and Distinguished
      Civil Service Awards.
    </BlogPostLayout.Body>

    <BlogPostLayout.Quote
      text="Throughout my career, I have looked for ways to improve and advance the best solutions in aviation and aerospace within the U.S. military. What we have learned in the DoD is that real innovation is coming from the non-traditional industrial base. In my role on the EX1 Advisory Board, I want to continue my work identifying cutting-edge companies and solutions to outpace the threats to our national security."
      attribution="Gary K. Kessler"
    />

    <BlogPostLayout.Body>
      EX1
      {' '}
      <Link to="https://www.linkedin.com/in/moejafari/">CEO Moe Jafari</Link>
      {' '}
      said, &rdquo;Gary Kessler&apos;s understanding of how the Armed Forces
      operate and how our companies can respond to the needs of the DoD is
      invaluable,&rdquo; he said. &rdquo;Our mission is to serve our country
      by responding to complex IT and cyber challenges specific to defense and
      the intelligence community. We want to compete in the DoD environment,
      aggressively grow and are eager to benefit from Mr. Kessler&apos;s
      stewardship.&rdquo;
    </BlogPostLayout.Body>

    <BlogPostLayout.Header>
      About EX1
    </BlogPostLayout.Header>

    <BlogPostLayout.Body>
      EX1 is a private holding firm that invests in AI/ML/NLP and
      software-as-a-service (SaaS). EX1&apos;s current investments in
      {' '}
      <Link to={__.path}>Plasticity Inc.</Link>
      ,
      {' '}
      <Link to="http://www.humantouchllc.com/">HumanTouch, LLC</Link>
      , and
      {' '}
      <Link to="http://www.coras.com/">CORASCloud, Inc.</Link>
      {' '}
      (SaaS) are positioned to lead in these technologies.
    </BlogPostLayout.Body>

    <BlogPostLayout.Body>
      Consumers, businesses, and the federal Government are all embracing AI
      in the marketplace. According to Gartner, Government and private firms
      are forecasted to spend over $125 billion by 2025 on AI products and
      services. The EX1 family of companies find solutions to complex business
      challenges, with ML, AI and NLP becoming an increasingly significant
      part of that formula. Our strategic solutions and applications bring
      together innovators, experts and data scientists in solving the federal
      Government&apos;s business challenges. EX1&apos;s mission is to provide
      superior human interactions and client support, using service and
      technology that leverages cutting-edge data science applications to work
      smarter. This growing family of companies serves the Department of
      Defense, civilian federal Government agencies and commercial
      organizations with pride. For more information, visit
      {' '}
      <Link to="https://www.executive1holding.com/">https://www.executive1holding.com/</Link>
    </BlogPostLayout.Body>
  </BlogPostLayout>
);

export default GaryKesslerJoinsBoard;
