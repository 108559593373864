import React from 'react';

import TitleHelmet from 'components/Helmets/TitleHelmet';
import DescriptionHelmet from 'components/Helmets/DescriptionHelmet';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import Card from 'components/Card';
import Checklist from 'components/Checklist';
import CTAButton from 'components/CTAButton';
import Link from 'components/Link';
import GovernmentHero from 'components/GovernmentHero';
import LazyImage from 'components/LazyImage';

import { FLAVORS, ACCENTS } from 'constants/themes';
import { __scheduleDemo } from 'constants/routes';
import { __blogPostPoliticalDisinformationCampaignOnYoutube } from 'pages/blog/routes';

const imagesFolder = `${process.env.PUBLIC_URL}/images/government`;

const TITLE_HELMET = <TitleHelmet title="Government · Plasticity Natural Language Processing tools and solutions" />;
const DESCRIPTION_HELMET = <DescriptionHelmet description="Plasticity provides government organizations with state-of-the-art natural language processing tools and solutions to convert unstructured text into structured data, find networks of bots and disinformation, and more." />;

const Government = () => (
  <>
    {TITLE_HELMET}
    {DESCRIPTION_HELMET}
    <SiteHeader
      variant="overlay"
      theme="light"
      accent={ACCENTS.government}
    />
    <Section
      theme="light"
      gradient="partial"
      flavor={FLAVORS.government}
      accent={ACCENTS.government}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="hero"
            title="Plasticity for Government"
            body={(
              <p>
                Most of the data being created today is difficult
                for computers to access and understand. We build
                natural language processing tools and applications
                that make sense of it.
              </p>
            )}
            footer={(
              <CTAButton
                text="Request a demo"
                variant="button"
                to={__scheduleDemo.path}
              />
            )}
          />
          <GovernmentHero />
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Unstructured to structured */}
    <Section
      theme="white"
      accent={ACCENTS.government}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Insights"
            title="Convert unstructured text into structured data"
            body={(
              <p>
                Unstructured data is doubling in size every year.
                Plasticity uses machine learning and natural language
                processing to help analysts scale exponentially as well.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Uncover insights in free-form text"
            body={(
              <p>
                We build systems that process large amounts of text
                to extract relationships between people, places, and
                things, helping analysts quickly comb through large
                quantities of data.
              </p>
            )}
            footer={(
              <Checklist
                columns={1}
                data={[
                  'Extract all entities from a document',
                  'Find open-domain relationships among entities',
                  'Build your own custom knowledge graph',
                  'Query documents in natural language',
                  'Summarize large documents visually',
                ]}
              />
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/unstructured.svg`}
              alt="Plasticity helps government organizations structure unstructured data"
              width="100%"
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Targeting and crawling */}
    <Section
      theme="dark"
      accent={ACCENTS.government}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Targeting"
            title="Fingerprint accounts and content to find similar actors"
            body={(
              <p>
                Using the content posted from a known account, Plasticity
                uses natural language processing to fingerprint its metadata
                and find similar actors.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Targeting on real-world accounts"
            body={(
              <p>
                Plasticity&apos;s targeting application
                {' '}
                <Link to="https://www.wsj.com./articles/sanders-aims-for-positive-campaign-allies-dont-always-follow-11579694402">helped national security journalists</Link>
                {' '}
                at the Wall Street Journal uncover similar accounts on Twitter
                spreading an abusive hashtag campaign. Analysts can
                automatically uncover rings of accounts from just one
                sample account.
              </p>
            )}
            footer={(
              <Checklist
                columns={1}
                data={[
                  'Uses NLP to fingerprint text content',
                  'Finds similarities between account metadata',
                  'Saves all data for further analysis and reporting',
                  'Point-in-time or ongoing monitoring',
                  'Scour YouTube, Twitter, and more',
                ]}
              />
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/fingerprint.svg`}
              alt="Plasticity helps analysts with targeting"
              width="100%"
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Disinformation */}
    <Section
      theme="light"
      accent={ACCENTS.government}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Disinformation"
            title="Find networks of bot activity spreading disinformation"
            body={(
              <p>
                Disinformation is one of the fastest growing cyber threats
                today. To counter disinformation effectively, Plasticity
                provides solutions to government organizations to identify,
                visualize, and evaluate disinformation narrative themes in
                near real-time.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Detect and analyze disinformation"
            body={(
              <p>
                Prior to the 2020 presidential election,
                Plasticity&apos;s targeting application
                {' '}
                <Link
                  to={__blogPostPoliticalDisinformationCampaignOnYoutube.path}
                >
                  helped uncover 800 YouTube accounts
                </Link>
                {' '}
                linked to Russia and Ukraine that were posting
                doctored news videos as disinformation. Our
                publicly available information (PAI) toolkit was
                custom-designed for intelligence analysts with
                support from the U.S. Air Force.
              </p>
            )}
            footer={(
              <Checklist
                columns={1}
                data={[
                  'Search PAI on news and social media',
                  'Automatically flag disinformation and bot activity',
                  'Continually monitor saved search queries',
                  'Analyze topics and sentiment',
                  'Discover narrative themes',
                  'Generate PowerPoint or PDF reports with one click',
                ]}
              />
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/disinformation.svg`}
              alt="Plasticity teams with analysts to discover disinformation on social media"
              width="100%"
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Get started */}
    <Section
      theme="white"
      accent={ACCENTS.government}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="section"
            title="Work with us"
            body={(
              <p>
                Schedule a conversation with our government sales team
                to learn more about how Plasticity&apos;s applications and
                custom solutions can meet your organization&apos;s data needs.
              </p>
            )}
            footer={(
              <CTAButton
                text="Request a demo"
                variant="button"
                to={__scheduleDemo.path}
              />
            )}
          />
          <LazyImage
            src={`${imagesFolder}/customers.png`}
            placeholderSrc={`${imagesFolder}/customers_low.png`}
            alt="Plasticity government customers"
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Government;
