import React from 'react';

import TitleHelmet from 'components/Helmets/TitleHelmet';
import DescriptionHelmet from 'components/Helmets/DescriptionHelmet';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import GridLayout from 'components/GridLayout';
import Copy from 'components/Copy';
import Checklist from 'components/Checklist';
import CTAButton from 'components/CTAButton';

import { ACCENTS } from 'constants/themes';
import {
  __demo,
  __scheduleDemo,
  __contactUs,
} from 'constants/routes';

import styles from './styles.module.scss';

const TITLE_HELMET = <TitleHelmet title="Pricing · Plasticity Natural Language Processing APIs" />;
const DESCRIPTION_HELMET = <DescriptionHelmet description="Plasticity offers simple pricing for individuals, teams, and enterprises needing natural language processing technology. Pay only for what you use with monthly usage-based pricing, as well as free API keys for open source and education." />;

const Pricing = () => (
  <>
    {TITLE_HELMET}
    {DESCRIPTION_HELMET}
    <SiteHeader
      theme="light"
      accent={ACCENTS.pricing}
    />
    <Section
      theme="dark"
    >
      <Copy
        variant="section"
        title="Pricing"
        body={(
          <p>
            Join hundreds of organizations using Plasticity&apos;s
            natural language processing products and APIs.
          </p>
        )}
        footer={(
          <div className={styles['pricing-cards']}>
            <GridLayout>
              <div className={styles['api-pricing-card']}>
                <Copy
                  variant="detail"
                  title="Cloud-hosted API"
                  body={(
                    <Checklist
                      columns={1}
                      data={[
                        'Simple pricing based on monthly usage',
                        'Pay only for what you use',
                        'Work in any programming language',
                        'Email support within 24 hours',
                      ]}
                    />
                  )}
                  footer={(
                    <CTAButton
                      text="Request access"
                      variant="button"
                      to={__scheduleDemo.path}
                    />
                  )}
                />
              </div>
              <div className={styles['solution-pricing-card']}>
                <Copy
                  variant="detail"
                  title="On-Premise Solutions"
                  body={(
                    <p>
                      Looking for something specific? Plasticity builds
                      custom-tailored NLP products and solutions for
                      enterprises in  technology, law, medicine, government,
                      customer service, and others. Let&apos;s get started
                      adding natural language understanding to your next
                      application.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="Schedule a demo"
                      variant="link"
                      to={__scheduleDemo.path}
                    />
                  )}
                />
              </div>
            </GridLayout>
          </div>
        )}
      />
    </Section>
    <Section
      theme="white"
      accent={ACCENTS.pricing}
    >
      <Copy
        variant="section"
        title="API Plans"
        body={(
          <p>
            Simple pricing for individuals, teams, and enterprises
            that scales as you grow.
          </p>
        )}
        footer={(
          <div className={styles['api-pricing']}>
            <table className={styles['api-pricing-table']}>
              <thead>
                <tr className={styles['api-pricing-columns-header']}>
                  <td>API Service</td>
                  <td>
                    &lt;&nbsp;1K
                    <span>requests per month</span>
                  </td>
                  <td>
                    1K&nbsp;-&nbsp;500K
                    <span>requests per month</span>
                  </td>
                  <td>
                    500K&nbsp;-&nbsp;10M
                    <span>requests per month</span>
                  </td>
                  <td>
                    &gt;&nbsp;10M
                    <span>requests per month</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sapien Language Engine</td>
                  <td>FREE</td>
                  <td>
                    $2.00
                    <span>per 1,000 requests</span>
                  </td>
                  <td>
                    $1.50
                    <span>per 1,000 requests</span>
                  </td>
                  <td>
                    $1.00
                    <span>per 1,000 requests</span>
                  </td>
                </tr>
                <tr>
                  <td>Cortex Knowledge Graph</td>
                  <td>FREE</td>
                  <td>
                    $3.00
                    <span>per 1,000 requests</span>
                  </td>
                  <td>
                    $2.00
                    <span>per 1,000 requests</span>
                  </td>
                  <td>
                    $1.50
                    <span>per 1,000 requests</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      />
    </Section>
    <Section
      theme="light"
      accent={ACCENTS.pricing}
    >
      <RowLayout>
        <ColumnLayout columns="2,2">
          <Copy
            variant="section"
            title="Getting started is easy"
            body={(
              <p>
                Add natural language understanding to your application
                in just a few minutes by signing up for a free developer
                API account today. Or schedule a demo with our sales team
                to discuss your specific needs and use cases&mdash;we&apos;re
                here to help.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demo.path}
                />
              </>
            )}
          />
          <Copy
            variant="detail"
            caption="Open source"
            title="Free access for research and open source"
            body={(
              <p>
                Building for education or an open source project?
                Plasticity supports the research and open source
                communities by providing free access to its API for
                certain projects. Get in touch with us to apply for
                a free API key.
              </p>
            )}
            footer={(
              <CTAButton
                text="Contact us"
                variant="link"
                to={__contactUs.path}
              />
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Pricing;
