import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Body = ({ children }) => (
  <p className={styles.body}>{children}</p>
);

Body.defaultProps = {
  children: null,
};

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;
