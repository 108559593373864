import React from 'react';

import DocLayout from 'components/DocLayout';

import {
  __docsSapienTransformNounPluralization,
  __docsSapienTransformVerbConjugation,
  __docsSapienTransformAdjectiveTransformation,
  __docsSapienTransformAdverbTransformation,
} from 'pages/docs/routes';

import { aaSapienTransform } from 'constants/routes';

const Transform = () => (
  <DocLayout>
    <DocLayout.Title>
      Transform
    </DocLayout.Title>
    <DocLayout.Body>
      Transforms words to their various other forms, which
      is useful for normalizing text or for natural language
      generation.
    </DocLayout.Body>

    <DocLayout.Header id={__docsSapienTransformNounPluralization.hash}>
      Noun Singularization and Pluralization
    </DocLayout.Header>
    <DocLayout.Body>
      Transforms a noun to its various forms.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>leaf</DocLayout.InlineCode>
                {' '}
                or
                {' '}
                <DocLayout.InlineCode>mice</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>NounSinglar</DocLayout.InlineCode>
                {' '}
                will transform the word to its singular form
              </li>
              <li>
                <DocLayout.InlineCode>NounPlural</DocLayout.InlineCode>
                {' '}
                will transform the word to its plural form
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns the transformed noun.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="sapien-transform-noun-1"
      url={aaSapienTransform.path}
      methods={['GET', 'POST']}
    />

    <DocLayout.Header id={__docsSapienTransformVerbConjugation.hash}>
      Verb Conjugations
    </DocLayout.Header>
    <DocLayout.Body>
      Transforms a verb to its various forms.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>eating</DocLayout.InlineCode>
                {' '}
                or
                {' '}
                <DocLayout.InlineCode>are</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>VerbInfinitive</DocLayout.InlineCode>
                {' '}
                will transform the word to its infinitive form
              </li>
              <li>
                <DocLayout.InlineCode>VerbPresentSingular</DocLayout.InlineCode>
                {' '}
                will transform the word to its present singular form
              </li>
              <li>
                <DocLayout.InlineCode>VerbPresentPlural</DocLayout.InlineCode>
                {' '}
                will transform the word to its present plural form
              </li>
              <li>
                <DocLayout.InlineCode>VerbPast</DocLayout.InlineCode>
                {' '}
                will transform the word to its past form
              </li>
              <li>
                <DocLayout.InlineCode>VerbPastParticiple</DocLayout.InlineCode>
                {' '}
                will transform the word to its past participle form
              </li>
              <li>
                <DocLayout.InlineCode>VerbContinuous</DocLayout.InlineCode>
                {' '}
                will transform the word to its continuous form
              </li>
              <li>
                <DocLayout.InlineCode>VerbAgent</DocLayout.InlineCode>
                {' '}
                will transform the word to its agent form
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns the transformed verb.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Examples
    </DocLayout.Subheader>
    <DocLayout.Example
      id="sapien-transform-verb-1"
      url={aaSapienTransform.path}
      methods={['GET', 'POST']}
    />
    <DocLayout.Example
      id="sapien-transform-verb-2"
      url={aaSapienTransform.path}
      methods={['GET', 'POST']}
    />

    <DocLayout.Header id={__docsSapienTransformAdjectiveTransformation.hash}>
      Adjective Transformations
    </DocLayout.Header>
    <DocLayout.Body>
      Transforms an adjective to its various forms.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>worst</DocLayout.InlineCode>
                {' '}
                or
                {' '}
                <DocLayout.InlineCode>tallest</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>
                  AdjectivePositiveDegree
                </DocLayout.InlineCode>
                {' '}
                will transform the word to its positive degree form
              </li>
              <li>
                <DocLayout.InlineCode>
                  AdjectiveComparative
                </DocLayout.InlineCode>
                {' '}
                will transform the word to its comparative form
              </li>
              <li>
                <DocLayout.InlineCode>
                  AdjectiveSuperlative
                </DocLayout.InlineCode>
                {' '}
                will transform the word to its superlative form
              </li>
              <li>
                <DocLayout.InlineCode>
                  AdjectiveAdverb
                </DocLayout.InlineCode>
                {' '}
                will transform the word to its adverb form
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns the transformed adjective.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="sapien-transform-adjective-1"
      url={aaSapienTransform.path}
      methods={['GET', 'POST']}
    />

    <DocLayout.Header id={__docsSapienTransformAdverbTransformation.hash}>
      Adverb Transformations
    </DocLayout.Header>
    <DocLayout.Body>Transforms an adverb to its various forms.</DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>quickly</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>action</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>AdverbAdjective</DocLayout.InlineCode>
                {' '}
                will transform the word to its adjective form
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns the transformed adverb.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="sapien-transform-adverb-1"
      url={aaSapienTransform.path}
      methods={['GET', 'POST']}
    />

  </DocLayout>
);

export default Transform;
