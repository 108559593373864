import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAsyncCallback } from 'react-async-hook';
import {
  Button,
  Select,
  Textarea,
} from 'evergreen-ui';
import {
  get,
  findKey,
} from 'lodash';

import Section from 'components/Section';
import Copy from 'components/Copy';
import Form from 'components/Form';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import SapienSentenceGraphVisualizer from 'components/SapienSentenceGraphVisualizer';
import SapienCompetitorVisualizer from 'components/SapienCompetitorVisualizer';
import LoadingSpinner from 'components/LoadingSpinner';

import { makeApiRequest, makeCompetitorApiRequest } from 'utils/api';
import { aaSapienDemoCore } from 'constants/routes';
import { CUSTOM, OPTIONS } from 'pages/demo/sapien/constants';
import { ACCENTS } from 'constants/themes';

import demoStyles from 'pages/demo/styles.module.scss';
import styles from './styles.module.scss';

const fetchSapienResult = async (text) => (
  makeApiRequest(aaSapienDemoCore.path, {
    text,
    ner: false,
  })
);

const fetchCompetitorResult = async (text) => makeCompetitorApiRequest({
  text,
  models: 'allennlp,openie,clausie,corenlp',
});

const DemoSapienCompare = () => {
  const [textQueryParam, setTextQueryParam] = useQueryParam('text', StringParam);
  const [modelQueryParam, setModelQueryParam] = useQueryParam('model', StringParam);
  const [dropdownValue, setDropdownValue] = useState(Object.keys(OPTIONS)[0]);
  const [textareaValue, setTextareaValue] = useState(Object.values(OPTIONS)[0]);
  const asyncFetchSapienResult = useAsyncCallback(fetchSapienResult);
  const asyncFetchCompetitorResult = useAsyncCallback(fetchCompetitorResult);

  /**
   * Run on initial load to determine if there is supplied
   * text in the query params. If so, set the text and
   * the dropdown value and run the query.
   */
  useEffect(() => {
    if (textQueryParam) {
      const key = findKey(OPTIONS, (o) => (o === textQueryParam)) || CUSTOM;
      setDropdownValue(key);
      setTextareaValue(textQueryParam);
      asyncFetchSapienResult.execute(textQueryParam);
      asyncFetchCompetitorResult.execute(textQueryParam);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectChange = (event) => {
    asyncFetchSapienResult.reset();
    asyncFetchCompetitorResult.reset();
    setTextQueryParam(undefined);
    setDropdownValue(event.target.value);
    setTextareaValue(OPTIONS[event.target.value]);
  };

  const onTextareaChange = (event) => {
    asyncFetchSapienResult.reset();
    asyncFetchCompetitorResult.reset();
    setTextQueryParam(undefined);
    setDropdownValue(CUSTOM);
    setTextareaValue(event.target.value);
  };

  const onTabChange = (tab) => {
    setModelQueryParam(tab);
  };

  const onSubmit = () => {
    asyncFetchSapienResult.execute(textareaValue);
    asyncFetchCompetitorResult.execute(textareaValue);
    setTextQueryParam(textareaValue);
  };

  return (
    <Section
      padding="none"
      accent={ACCENTS.demo}
    >
      <Copy
        variant="section"
        caption="Sapien Language Engine"
        title="Compare with other NLP packages"
        body={(
          <p>
            See how Sapien compares to other open source and commercial
            packages for open information extraction.
          </p>
        )}
        footer={(
          <>
            <Form>
              <Form.FieldRow>
                <Select
                  height={40}
                  width="100%"
                  value={dropdownValue}
                  onChange={onSelectChange}
                >
                  {Object.keys(OPTIONS).map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </Select>
                <Textarea
                  className={styles.textarea}
                  value={textareaValue}
                  onChange={onTextareaChange}
                  placeholder="Enter custom text..."
                />
              </Form.FieldRow>
              <Form.ButtonRow>
                <Button
                  height={40}
                  appearance="primary"
                  isLoading={(
                      asyncFetchSapienResult.loading
                      || asyncFetchCompetitorResult.loading
                    )}
                  onClick={onSubmit}
                >
                  Run
                </Button>
              </Form.ButtonRow>
              <Form.ErrorRow error={asyncFetchSapienResult.error} />
              <Form.ErrorRow error={asyncFetchCompetitorResult.error} />
            </Form>
            {(asyncFetchSapienResult.result
              || asyncFetchCompetitorResult.result)
              && (
              <div className={demoStyles['response-data']}>
                <Tabs
                  defaultTab={modelQueryParam}
                  onChange={onTabChange}
                >
                  <Tab
                    id="sapien"
                    label="Plasticity Sapien"
                  >
                    {asyncFetchSapienResult.loading
                      ? <LoadingSpinner withContainer />
                      : (
                        <SapienSentenceGraphVisualizer
                          sentences={get(asyncFetchSapienResult.result, 'data', [])}
                        />
                      )}
                  </Tab>
                  <Tab
                    id="allennlp"
                    label="AllenNLP"
                  >
                    {asyncFetchCompetitorResult.loading
                      ? <LoadingSpinner withContainer />
                      : (
                        <SapienCompetitorVisualizer
                          relations={get(asyncFetchCompetitorResult.result, 'data.allennlp', [])}
                        />
                      )}
                  </Tab>
                  <Tab
                    id="openie"
                    label="OpenIE"
                  >
                    {asyncFetchCompetitorResult.loading
                      ? <LoadingSpinner withContainer />
                      : (
                        <SapienCompetitorVisualizer
                          relations={get(asyncFetchCompetitorResult.result, 'data.openie', [])}
                        />
                      )}
                  </Tab>
                  <Tab
                    id="clausie"
                    label="ClausIE"
                  >
                    {asyncFetchCompetitorResult.loading
                      ? <LoadingSpinner withContainer />
                      : (
                        <SapienCompetitorVisualizer
                          relations={get(asyncFetchCompetitorResult.result, 'data.clausie', [])}
                        />
                      )}
                  </Tab>
                  <Tab
                    id="corenlp"
                    label="CoreNLP"
                  >
                    {asyncFetchCompetitorResult.loading
                      ? <LoadingSpinner withContainer />
                      : (
                        <SapienCompetitorVisualizer
                          relations={get(asyncFetchCompetitorResult.result, 'data.corenlp', [])}
                        />
                      )}
                  </Tab>
                </Tabs>
              </div>
              )}
          </>
        )}
      />
    </Section>
  );
};

export default DemoSapienCompare;
