import React from 'react';

import styles from './styles.module.scss';

const RequiredField = () => (
  <div className={styles.required}>
    (required)
  </div>
);

export default RequiredField;
