import React from 'react';

import DocLayout from 'components/DocLayout';

import {
  __docsCortexStructuredEntitySearch,
  __docsCortexStructuredPropertySearch,
  __docsCortexStructuredCategorySearch,
  __docsCortexStructuredEntityAndPropertySearch,
  __docsCortexStructuredNaturalLanguageQA,
} from 'pages/docs/routes';

import { aaCortexStructured } from 'constants/routes';

const Structured = () => (
  <DocLayout>
    <DocLayout.Title>
      Structured Knowledge Graph
    </DocLayout.Title>
    <DocLayout.Body>
      A graph of real word concepts, people, places, and things
      that you can query in natural language.
    </DocLayout.Body>

    <DocLayout.Header id={__docsCortexStructuredEntitySearch.hash}>
      Entity Search
    </DocLayout.Header>
    <DocLayout.Body>
      Searches the knowledge graph for a particular entity or
      concept to get a list of matching results with rich metadata.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>entity</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>entity</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>Mark Zuckerberg</DocLayout.InlineCode>
                {' '}
                or
                {' '}
                <DocLayout.InlineCode>Zuckerberg</DocLayout.InlineCode>
              </li>
              <li>
                a Wikipedia URL like
                {' '}
                <DocLayout.InlineCode>https://en.wikipedia.org/wiki/Mark_Zuckerberg</DocLayout.InlineCode>
              </li>
              <li>
                a Freebase Identifier like
                {' '}
                <DocLayout.InlineCode>/m/086dny</DocLayout.InlineCode>
              </li>
              <li>
                a Cortex Knowledge Graph ID like
                {' '}
                <DocLayout.InlineCode>PWE36215</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>score</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>score</DocLayout.InlineCode>
            {' '}
            value determines which scoring function should be used when
            ordering results. The
            {' '}
            <DocLayout.InlineCode>score</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>popularity</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
                {' '}
                which ranks the entities by a popularity heuristic
              </li>
              <li>
                <DocLayout.InlineCode>exact</DocLayout.InlineCode>
                {' '}
                which ranks the entities by how closely their label matches
                the query string
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns a list of entities that resulted from the search
      as ordered by the scoring function. Each entity is returned
      with rich metadata.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="cortex-structured-entity-1"
      url={aaCortexStructured.path}
      methods={['GET', 'POST']}
    />

    <DocLayout.Header id={__docsCortexStructuredPropertySearch.hash}>
      Property Search
    </DocLayout.Header>
    <DocLayout.ComingSoon />

    <DocLayout.Header id={__docsCortexStructuredCategorySearch.hash}>
      Category Search
    </DocLayout.Header>
    <DocLayout.ComingSoon />

    <DocLayout.Header id={__docsCortexStructuredEntityAndPropertySearch.hash}>
      Entity and Property Search
    </DocLayout.Header>
    <DocLayout.Body>
      Searches the knowledge graph for a property of a particular
      entity or concept to get a list of matching results with
      rich metadata.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>entity</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>entity</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>Mark Zuckerberg</DocLayout.InlineCode>
                {' '}
                or
                {' '}
                <DocLayout.InlineCode>Zuckerberg</DocLayout.InlineCode>
              </li>
              <li>
                a Wikipedia URL like
                {' '}
                <DocLayout.InlineCode>https://en.wikipedia.org/wiki/Mark_Zuckerberg</DocLayout.InlineCode>
              </li>
              <li>
                a Freebase Identifier like
                {' '}
                <DocLayout.InlineCode>/m/086dny</DocLayout.InlineCode>
              </li>
              <li>
                a Cortex Knowledge Graph ID like
                {' '}
                <DocLayout.InlineCode>PWE36215</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>property</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>property</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>wife</DocLayout.InlineCode>
              </li>
              <li>
                a Cortex Knowledge Graph ID like
                {' '}
                <DocLayout.InlineCode>PWP26</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>score</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>score</DocLayout.InlineCode>
            {' '}
            value determines which scoring function should be used when
            ordering results. The
            {' '}
            <DocLayout.InlineCode>score</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>popularity</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
                {' '}
                which ranks the entities by a popularity heuristic
              </li>
              <li>
                <DocLayout.InlineCode>exact</DocLayout.InlineCode>
                {' '}
                which ranks the entities by how closely their label matches
                the query string
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns a list of relationships that resulted from the
      search as ordered by the scoring function. Each relationship
      is returned with rich metadata.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="cortex-structured-entity-and-property-1"
      url={aaCortexStructured.path}
      methods={['GET', 'POST']}
    />

    <DocLayout.Header id={__docsCortexStructuredNaturalLanguageQA.hash}>
      Natural Language Question Answering
    </DocLayout.Header>
    <DocLayout.Body>
      Searches the knowledge graph with a natural language query
      to get a list of matching answers with rich metadata.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>query</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>query</DocLayout.InlineCode>
            {' '}
            value is any natural language query. It can be a keyword search,
            simple natural language query, or a more complicated compound or
            nested query. Here are a few examples:
            <ul>
              <li>
                <DocLayout.InlineCode>
                  Who&apos;s Zuckerberg&apos;s wife?
                </DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>
                  birthday of bill gates
                </DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>
                  michael jordan height
                </DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>
                  When is Beyonce&apos;s husband&apos;s birthday?
                </DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>
                  Who directed Shawshank Redemption, Dead Poets Society,
                  and Good Will Hunting?
                </DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns a list of relationships that resulted from the search.
      Each relationship is returned with rich metadata.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Body />
    <DocLayout.Example
      id="cortex-structured-natural-language-qa-1"
      url={aaCortexStructured.path}
      methods={['GET', 'POST']}
    />
  </DocLayout>
);

export default Structured;
