import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Table = ({ headers, rows }) => (
  <table className={styles.table}>
    {(headers && headers.length > 0) && (
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
    )}
    {(rows && rows.length > 0) && (
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((col, colIndex) => (
              <td key={colIndex}>{col}</td>
            ))}
          </tr>
        ))}
      </tbody>
    )}
  </table>
);

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default Table;
