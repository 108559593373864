import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const RowLayout = ({ size, children }) => (
  <div
    className={[
      styles.row,
      styles[`size-${size}`],
    ].join(' ')}
  >
    {children}
  </div>
);

RowLayout.defaultProps = {
  children: null,
  size: 'md',
};

RowLayout.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default RowLayout;
