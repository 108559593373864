import React from 'react';
import PropTypes from 'prop-types';
import Tilt from 'react-tilt';

import styles from './styles.module.scss';

const FeatureCard = ({
  icon, body, span, tilt,
}) => {
  const inner = (
    <>
      {icon
        && (
        <div className={styles.icon}>
          {icon}
        </div>
        )}
      {body
        && (
        <div className={styles.body}>
          {body}
        </div>
        )}
    </>
  );

  const outerClassName = [
    styles.outer,
    styles.card,
    styles[`span-${span}`],
  ].join(' ');

  if (tilt) {
    return (
      <Tilt
        className={outerClassName}
        options={{ max: 25, scale: 1.05 }}
      >
        {inner}
      </Tilt>
    );
  }
  return (
    <div className={outerClassName}>
      {inner}
    </div>
  );
};

FeatureCard.defaultProps = {
  icon: null,
  body: null,
  span: 1,
  tilt: false,
};

FeatureCard.propTypes = {
  icon: PropTypes.node,
  body: PropTypes.node,
  span: PropTypes.oneOf([1, 2, 3, 4]),
  tilt: PropTypes.bool,
};

export default FeatureCard;
