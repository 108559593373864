import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import {
  __docsGettingStartedAuthenticationHttpAuthorizationHeader,
  __docsGettingStartedAuthenticationApiKeyParameter,
  __docsGettingStartedAuthenticationSession,
} from 'pages/docs/routes';

import {
  aa,
  aaCortexStructured,
  xxAppKeys,
} from 'constants/routes';

const Authentication = () => {
  const sessionExampleUrl = `${aaCortexStructured.path}/?query=How%20tall%20is%20Michael%20Jordan?&pretty=true`;

  return (
    <DocLayout>
      <DocLayout.Title>
        Authentication
      </DocLayout.Title>
      <DocLayout.Body>
        There are three methods you can use to authenticate with
        the Plasticity developer APIs with your API Key.
      </DocLayout.Body>
      <DocLayout.Body>
        If you don&apos;t already have an API Key to use, you can
        generate one
        {' '}
        <Link
          to={xxAppKeys.path}
          target="_blank"
        >
          here
        </Link>
        .
      </DocLayout.Body>

      <DocLayout.Header
        id={__docsGettingStartedAuthenticationHttpAuthorizationHeader.hash}
      >
        HTTP Authorization Header
      </DocLayout.Header>
      <DocLayout.Body>
        You can add a bearer Authorization HTTP header to
        authenticate with the API. This is the preferred and
        recommended method of authenticating with the API.
      </DocLayout.Body>

      <DocLayout.Subheader>
        Request Header Format
      </DocLayout.Subheader>
      <DocLayout.Table
        headers={[
          'Header',
          'Value',
        ]}
        rows={[
          [
            <DocLayout.InlineCode>Authorization</DocLayout.InlineCode>,
            <DocLayout.InlineCode>Bearer YOUR_API_KEY</DocLayout.InlineCode>,
          ],
        ]}
      />

      <DocLayout.Header
        id={__docsGettingStartedAuthenticationApiKeyParameter.hash}
      >
        API Key Parameter
      </DocLayout.Header>
      <DocLayout.Body>
        You can pass your API Key as a GET parameter (a URL query
        variable) or as a parameter in the JSON request body with
        the
        {' '}
        <DocLayout.InlineCode>key</DocLayout.InlineCode>
        {' '}
        variable.
      </DocLayout.Body>

      <DocLayout.Subheader>
        Request URL Format
      </DocLayout.Subheader>
      <DocLayout.CodeBlock
        language="text"
        data={`${aa.path}/.../.../?key=YOUR_API_KEY&param1=value1&param2=value2`}
      />

      <DocLayout.Subheader>
        Request Body Format
      </DocLayout.Subheader>
      <DocLayout.CodeBlock
        language="json"
        data={
`{
  "key": "YOUR_API_KEY",
  "param1": "value1",
  "param2": "value2",
  "param3": "value3"
}`
        }
      />

      <DocLayout.Header id={__docsGettingStartedAuthenticationSession.hash}>
        Session
      </DocLayout.Header>
      <DocLayout.Body>
        The final method of authenticating with an API, which
        you should not use when actually building an application,
        is with your Plasticity login session.
      </DocLayout.Body>
      <DocLayout.Body>
        That is, when you are logged into your Plasticity developer
        account in a web browser, accessing API endpoints like
        the following will work without any authentication:
      </DocLayout.Body>
      <DocLayout.Body>
        <Link
          to={sessionExampleUrl}
          target="_blank"
        >
          {sessionExampleUrl}
        </Link>
      </DocLayout.Body>
      <DocLayout.Body>
        We use the cookies to detect your account and keep you logged
        in. This method of authenticating with API endpoint is merely
        provided as a convenience for quick prototyping with the APIs
        in your browser and should not be used as a method of
        authentication in a real application.
      </DocLayout.Body>
    </DocLayout>
  );
};

export default Authentication;
