import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Body = ({ children, ...rest }) => (
  <p
    className={styles.body}
    {...rest}
  >
    {children}
  </p>
);

Body.defaultProps = {
  children: null,
};

Body.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Body;
