import React from 'react';
import PropTypes from 'prop-types';
import {
  get,
  isEmpty,
} from 'lodash';

import Section from 'components/Section';
import Copy from 'components/Copy';
import RowLayout from 'components/RowLayout';

import styles from './styles.module.scss';

const SapienLemmaVisualizer = ({ sentences }) => {
  const renderSentences = () => {
    if (isEmpty(sentences)) {
      return (
        <div className={styles['no-results']}>No sentences were found.</div>
      );
    }

    return sentences.map((sentence, sentenceIndex) => {
      const alternative = get(sentence, 'alternatives[0]', sentence);
      const tokens = get(alternative, 'tokens', []);
      return (
        <Copy
          key={sentenceIndex}
          variant="detail"
          caption={`Sentence ${sentenceIndex + 1}`}
          title={get(alternative, 'sentence')}
          footer={(
            <div className={styles.wrapper}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <td>Token</td>
                    <td>Lemma</td>
                  </tr>
                </thead>
                <tbody>
                  {tokens.map((token, tokenIndex) => {
                    const text = token[0];
                    const lemma = token[2];
                    return (
                      <tr key={tokenIndex}>
                        <td>{text}</td>
                        <td>{lemma}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        />
      );
    });
  };

  return (
    <Section padding="sm">
      <RowLayout size="sm">
        {renderSentences()}
      </RowLayout>
    </Section>
  );
};

SapienLemmaVisualizer.propTypes = {
  sentences: PropTypes.array.isRequired,
};

export default SapienLemmaVisualizer;
