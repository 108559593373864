/**
 * This file is used by react-router-sitemap
 * to generate a sitemap.xml for the website
 * automatically in sitemap-generator.js.
 *
 * It should maintain compatibility with Babel
 * es2015 and react presets for babel-register.
 */

/**
 * BEGIN INTERNAL PATH DEFINITIONS
 */
export const __ = '/';
export const __government = '/government';
export const __commercial = '/commercial';
export const __developers = '/developers';
export const __apiSapien = '/api/sapien';
export const __apiCortex = '/api/cortex';
export const __apiLingua = '/api/lingua';
export const __demo = '/demo';
export const __docs = '/docs';
export const __blog = '/blog';
export const __news = '/news';
export const __pricing = '/pricing';
export const __about = '/about';
export const __scheduleDemo = '/schedule-demo';
export const __contactUs = '/contact-us';
export const __terms = '/terms';
export const __eula = '/eula';
export const __privacy = '/privacy';
export const __404 = '*';

// Blog
export const __blogPostBrianKoloJoinsBoard = '/blog/brian-kolo-joins-board';
export const __blogPostGaryKesslerJoinsBoard = '/blog/gary-kessler-joins-board';
export const __blogPostPoliticalDisinformationCampaignOnYoutube = '/blog/political-disinformation-campaign-on-youtube';
export const __blogPostPlasticityJoinsEx1 = '/blog/plasticity-joins-ex1';
export const __blogHome = '/blog';

// Demo
export const __demoSapienParsing = '/demo/sapien/parsing';
export const __demoSapienExtractRelations = '/demo/sapien/extract-relations';
export const __demoSapienCompare = '/demo/sapien/compare';
export const __demoSapien = '/demo/sapien';
export const __demoCortexQueryNL = '/demo/cortex/query-nl';
export const __demoCortex = '/demo/cortex';
export const __demoHome = '/demo';

/**
 * BEGIN API ROUTE DEFINITIONS
 */
export const aa = 'https://api.plasticity.ai';
export const aaCompetitor = 'https://competitor.api.plasticity.ai';

// Production endpoints
export const aaSapien = 'https://api.plasticity.ai/sapien';
export const aaSapienCore = 'https://api.plasticity.ai/sapien/core';
export const aaSapienTransform = 'https://api.plasticity.ai/sapien/transform';
export const aaSapienNames = 'https://api.plasticity.ai/sapien/names';
export const aaCortex = 'https://api.plasticity.ai/cortex';
export const aaCortexStructured = 'https://api.plasticity.ai/cortex/structured';

// Demo endpoints
export const aaSapienDemo = 'https://api.plasticity.ai/sapien-demo';
export const aaSapienDemoCore = 'https://api.plasticity.ai/sapien-demo/core';
export const aaSapienDemoTransform = 'https://api.plasticity.ai/sapien-demo/transform';
export const aaSapienDemoNames = 'https://api.plasticity.ai/sapien-demo/names';
export const aaCortexDemo = 'https://api.plasticity.ai/cortex-demo';
export const aaCortexDemoStructured = 'https://api.plasticity.ai/cortex-demo/structured';

/**
 * BEGIN EXTERNAL ROUTE DEFINITIONS
 */
export const xxLanding = 'https://plasticity.ai';
export const xxLandingStaging = 'https://www-staging.plasticity.ai';
export const xxSitemap = '/sitemap.xml';
export const xxApp = 'https://app.plasticity.ai';
export const xxAppLogin = 'https://app.plasticity.ai/login';
export const xxAppSignup = 'https://app.plasticity.ai/signup';
export const xxAppPayment = 'https://app.plasticity.ai/#payment';
export const xxAppKeys = 'https://app.plasticity.ai/#keys';
export const xxBenchmarkSapien = 'https://media.plasticity.ai/benchmarks/sapien/';
export const xxGithub = 'https://github.com/plasticityai/';
export const xxTwitter = 'https://twitter.com/plasticityai/';

/**
 * BEGIN COMBINATION EXPORTS
 */

export const internalPaths = {
  __,
  __government,
  __commercial,
  __developers,
  __apiSapien,
  __apiCortex,
  __apiLingua,
  __demo,
  __docs,
  __blog,
  __news,
  __pricing,
  __about,
  __scheduleDemo,
  __contactUs,
  __terms,
  __eula,
  __privacy,
  __404,
};

export const blogPaths = {
  __blogPostBrianKoloJoinsBoard,
  __blogPostGaryKesslerJoinsBoard,
  __blogPostPoliticalDisinformationCampaignOnYoutube,
  __blogPostPlasticityJoinsEx1,
  __blogHome,
};

export const demoPaths = {
  __demoSapienParsing,
  __demoSapienExtractRelations,
  __demoSapienCompare,
  __demoSapien,
  __demoCortexQueryNL,
  __demoCortex,
  __demoHome,
};

export const apiPaths = {
  aa,
  aaCompetitor,
  aaSapien,
  aaSapienCore,
  aaSapienTransform,
  aaSapienNames,
  aaCortex,
  aaCortexStructured,
  aaSapienDemo,
  aaSapienDemoCore,
  aaSapienDemoTransform,
  aaSapienDemoNames,
  aaCortexDemo,
  aaCortexDemoStructured,
};

export const externalPaths = {
  xxLanding,
  xxLandingStaging,
  xxSitemap,
  xxApp,
  xxAppLogin,
  xxAppSignup,
  xxAppPayment,
  xxAppKeys,
  xxBenchmarkSapien,
  xxGithub,
  xxTwitter,
};

// eslint-disable-next-line prefer-object-spread
const paths = Object.assign(
  {},
  internalPaths,
  blogPaths,
  demoPaths,
  apiPaths,
  externalPaths,
);

export default paths;
