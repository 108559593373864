import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ButtonRow = ({ children }) => (
  <div className={styles.row}>
    {children}
  </div>
);

ButtonRow.defaultProps = {
  children: null,
};

ButtonRow.propTypes = {
  children: PropTypes.node,
};

export default ButtonRow;
