import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiMenu } from 'react-icons/fi';

import Logo from 'components/Logo';
import Link from 'components/Link';
import CTAButton from 'components/CTAButton';
import SiteMenu from 'components/SiteMenu';
import SiteMobileMenu from 'components/SiteMobileMenu';

import { THEME_DEFAULT_PROPS, THEME_PROP_TYPES } from 'constants/themes';
import {
  __,
  __demo,
  __pricing,
  xxAppLogin,
} from 'constants/routes';

import globalStyles from 'css/global.module.scss';
import styles from './styles.module.scss';

const SiteHeader = ({
  variant, theme, flavor, accent,
}) => {
  const [hoveredDropdownButton, setHoveredDropdownButton] = useState('');
  const [activeDropdown, setActiveDropdown] = useState('');
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  return (
    <header
      className={[
        globalStyles[`theme-${theme}`],
        globalStyles[`flavor-${flavor}`],
        globalStyles[`accent-${accent}`],
        styles.outer,
        styles[variant],
      ].join(' ')}
    >
      <div className={styles.container}>
        <div className={styles['nav-container']}>
          <h1>
            <Link
              to={__.path}
              decoration={false}
              className={styles['logo-link']}
            >
              <Logo />
            </Link>
          </h1>
          <nav className={styles.nav}>
            <ul className={styles['nav-items']}>
              <li>
                <button
                  type="submit"
                  className={[
                    styles['nav-item-link'],
                    activeDropdown === 'product' ? styles.active : '',
                  ].join(' ')}
                  aria-haspopup
                  onMouseEnter={() => setHoveredDropdownButton('product')}
                  onMouseLeave={() => setHoveredDropdownButton('')}
                >
                  Product
                </button>
              </li>
              <li>
                <Link
                  to={__demo.path}
                  decoration={false}
                  className={styles['nav-item-link']}
                >
                  Demo
                </Link>
              </li>
              <li>
                <Link
                  to={__pricing.path}
                  decoration={false}
                  className={styles['nav-item-link']}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <button
                  type="submit"
                  className={[
                    styles['nav-item-link'],
                    activeDropdown === 'resources' ? styles.active : '',
                  ].join(' ')}
                  aria-haspopup
                  onMouseEnter={() => setHoveredDropdownButton('resources')}
                  onMouseLeave={() => setHoveredDropdownButton('')}
                >
                  Resources
                </button>
              </li>
            </ul>
          </nav>
          <nav className={styles['cta-nav']}>
            <CTAButton
              text="Log in"
              to={xxAppLogin.path}
            />
          </nav>
          <nav className={styles['mobile-nav']}>
            <CTAButton
              text={<FiMenu />}
              arrow={false}
              aria-haspopup
              onClick={() => setMobileMenuVisible(true)}
            />
          </nav>
        </div>
      </div>
      <SiteMenu
        hoveredDropdownButton={hoveredDropdownButton}
        activeDropdown={activeDropdown}
        setActiveDropdown={setActiveDropdown}
      />
      <SiteMobileMenu
        visible={mobileMenuVisible}
        onClose={() => setMobileMenuVisible(false)}
      />
    </header>
  );
};

SiteHeader.defaultProps = {
  ...THEME_DEFAULT_PROPS,
  variant: 'relative',
};

SiteHeader.propTypes = {
  ...THEME_PROP_TYPES,
  variant: PropTypes.oneOf(['overlay', 'fixed', 'relative']),
};

export default SiteHeader;
