import { useLocation } from 'react-router-dom';

import { useDidMountEffect } from 'utils/hooks';

const SegmentListener = () => {
  const location = useLocation();

  /**
   * Listen for page changes that happen from the
   * Router. Ignore the first mount though, because
   * Segment's initialization script will take care
   * of that.
   */
  useDidMountEffect(() => {
    analytics.page(); // eslint-disable-line no-undef
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default SegmentListener;
