import { sortBy } from 'lodash';
import { blogPosts } from 'pages/blog/routes';

export const sortedBlogPosts = (
  sortBy(blogPosts, [(p) => p.meta.date]).reverse()
);

export const getNewestBlogPosts = (count = 3) => (
  sortedBlogPosts.slice(0, count)
);
