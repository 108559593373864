import { useEffect, useState, useRef } from 'react';

/**
 * React Hook that runs when any of the dependencies
 * change, but NOT on the first mount. Useful for
 * running code after the component has mounted.
 * @param  {Function} func The function to run
 * @param  {Array}    deps The dependencies to trigger the hook
 * @return {void}          Runs the given function
 */
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * React Hook that tells us if a component is visible
 * on the screen.
 * @param  {Element} ref       A reference to the component
 * @param  {String} rootMargin The margin of visibility on the screen
 * @return {Boolean}           Whether or not the element is on screen
 */
export const useOnScreen = (ref, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    { rootMargin },
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => { observer.disconnect(); };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isIntersecting;
};
