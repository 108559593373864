import React from 'react';
import { Helmet } from 'react-helmet-async';

const NotFoundHelmet = () => (
  <Helmet>
    <meta
      name="prerender-status-code"
      content="404"
    />
  </Helmet>
);

export default NotFoundHelmet;
