import { useEffect } from 'react';

export const open = () => {
  $crisp.push(['do', 'chat:open']); // eslint-disable-line no-undef
};

const Crisp = () => {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '33137c9c-cda5-40c0-b7a5-4486c268edb5';

    (function () { // eslint-disable-line func-names
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    }());
  }, []);

  return null;
};

export default Crisp;
