import React from 'react';
import PropTypes from 'prop-types';

import { THEME_DEFAULT_PROPS, THEME_PROP_TYPES } from 'constants/themes';

import globalStyles from 'css/global.module.scss';
import styles from './styles.module.scss';

const Icon = ({
  accent, glyph, variant, size, className,
}) => (
  <div
    className={[
      globalStyles[`accent-${accent}`],
      styles.icon,
      styles[`variant-${variant}`],
      styles[`size-${size}`],
      className,
    ].join(' ')}
  >
    {glyph}
  </div>
);

Icon.defaultProps = {
  accent: THEME_DEFAULT_PROPS.accent,
  variant: 'square',
  size: 'md',
  className: '',
};

Icon.propTypes = {
  glyph: PropTypes.node.isRequired,
  accent: THEME_PROP_TYPES.accent,
  variant: PropTypes.oneOf(['square', 'circle']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
};

export default Icon;
