import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'evergreen-ui';

import styles from './styles.module.scss';

const LoadingSpinner = ({
  isLoading, withContainer, className, ...rest
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={[
        withContainer ? styles.container : '',
        className,
      ].join(' ')}
      {...rest}
    >
      <Spinner />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  isLoading: true,
  withContainer: false,
  className: '',
};

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
  withContainer: PropTypes.bool,
  className: PropTypes.string,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default LoadingSpinner;
