import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import {
  __docsGettingStartedAuthentication,
  __docsGettingStartedRateLimits,
} from 'pages/docs/routes';
import {
  xxAppPayment,
} from 'constants/routes';

const ResponseFormat = () => (
  <DocLayout>
    <DocLayout.Title>
      API Response Format
    </DocLayout.Title>

    <DocLayout.Body>
      The Plasticity developer HTTP APIs return
      JSON-formatted outputs.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Response Header Format
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Header',
        'Value',
      ]}
      rows={[
        [
          <DocLayout.InlineCode>
            Content-Type
          </DocLayout.InlineCode>,
          <DocLayout.InlineCode>
            application/json
          </DocLayout.InlineCode>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response Status Codes
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Status Code',
        'Value',
      ]}
      rows={[
        [
          <DocLayout.InlineCode>
            200
          </DocLayout.InlineCode>,
          <>
            Indicates a successful response.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            400
          </DocLayout.InlineCode>,
          <>
            Indicates an invalid request error.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            401
          </DocLayout.InlineCode>,
          <>
            Indicates an
            {' '}
            <Link to={__docsGettingStartedAuthentication.path}>
              authentication
            </Link>
            {' '}
            error.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            402
          </DocLayout.InlineCode>,
          <>
            Indicates the free tier for the API has been reached and
            {' '}
            <Link
              to={xxAppPayment.path}
              target="_blank"
            >
              payment information must be entered
            </Link>
            {' '}
            to continue usage.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            404
          </DocLayout.InlineCode>,
          <>
            Indicates the path requested at the API endpoint does not exist.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            429
          </DocLayout.InlineCode>,
          <>
            Indicates a
            {' '}
            <Link to={__docsGettingStartedRateLimits.path}>rate limiting</Link>
            {' '}
            error.
          </>,
        ],
        [
          <DocLayout.InlineCode>
            5xx
          </DocLayout.InlineCode>,
          <>
            Indicates an internal server error.
          </>,
        ],
      ]}
    />
    <DocLayout.Subheader>
      Success Response Format
    </DocLayout.Subheader>
    <DocLayout.CodeBlock
      language="json"
      data={
`{
  "error": "false",
  "data": { ... }
}`
        }
    />

    <DocLayout.Subheader>
      Error Response Format
    </DocLayout.Subheader>
    <DocLayout.CodeBlock
      language="json"
      data={
`{
  "error": "true",
  "errorCode": XXX,
  "message": "A message describing the error will appear here."
}`
        }
    />
  </DocLayout>
);

export default ResponseFormat;
