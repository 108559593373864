import React from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import {
  get,
  isEmpty,
} from 'lodash';

import SapienSentenceElement from 'components/SapienSentenceElement';

import styles from './styles.module.scss';

const SapienSentenceGraphVisualizer = ({ sentences }) => {
  const renderPrepositions = (prepositions) => {
    if (isEmpty(prepositions)) {
      return null;
    }
    return (
      <div className={styles['prepositions-wrapper']}>
        <table>
          <tbody>
            {prepositions.map((preposition, index) => {
              const prepositionPrefix = get(preposition, 'prepositionPrefix', []).join(' ');

              return (
                <tr key={index}>
                  <td>
                    <span className={styles['element-wrapper']}>
                      <SapienSentenceElement
                        type="preposition"
                        prefix={(
                          <>
                            {prepositionPrefix}
                            {prepositionPrefix && ' '}
                          </>
                        )}
                        text={(
                          <>
                            {get(preposition, 'preposition')}
                          </>
                        )}
                        tooltipData={{
                          'Preposition Prefix': prepositionPrefix || 'None',
                        }}
                      />
                    </span>
                  </td>
                  <td>
                    {/* eslint-disable-next-line no-use-before-define */}
                    {renderGraphNode(get(preposition, 'prepositionObject'))}
                    {renderPrepositions(get(preposition, 'nestedPrepositions'))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderGraphNode = (node, entityType = 'object', entityPrepositionsJsx = null) => {
    if (get(node, 'type') === 'relation') {
      // Question
      const questionJsx = (get(node, 'question')
        && (
        <span className={styles['element-wrapper']}>
          <SapienSentenceElement
            type="question"
            text={(
              <>
                {get(node, 'question')}
              </>
            )}
            suffix={(
              <>
                {get(node, 'questionAuxiliary') && ' '}
                {get(node, 'questionAuxiliary')}
              </>
            )}
            tooltipData={{
              'Question Auxiliary': get(node, 'questionAuxiliary') || 'None',
            }}
          />
        </span>
        )
      );

      // Qualifiers
      const qualifiersJsx = (!isEmpty(get(node, 'qualifiers', []))
        && (
        <span className={styles['element-wrapper']}>
          <SapienSentenceElement
            type="qualifier"
            text={(
              <>
                {get(node, 'qualifiers', []).join(' ')}
              </>
            )}
          />
        </span>
        )
      );

      // Subject
      const subjectPrepositions = get(node, 'prepositions', []).filter((p) => (get(p, 'prepositionType') === 'Subject'));
      const subjectPrepositionsJsx = (!isEmpty(subjectPrepositions)
        && (
        <span className={styles['element-wrapper']}>
          {renderPrepositions(subjectPrepositions)}
        </span>
        )
      );
      const subjectJsx = renderGraphNode(get(node, 'subject'), 'subject', subjectPrepositionsJsx);

      // Predicate
      const predicatePrepositions = get(node, 'prepositions', []).filter((p) => (get(p, 'prepositionType') === 'Predicate'));
      const predicatePrepositionsJsx = renderPrepositions(predicatePrepositions); // eslint-disable-line max-len
      const verbPrefix = get(node, 'predicate.verbPrefix', '');
      const verbSuffix = get(node, 'predicate.verbSuffix', '');
      const verbModifiersSubjectPrefix = get(node, 'verbModifiersSubjectPrefix', []).join(' ');
      const verbModifiersPrefix = get(node, 'verbModifiersPrefix', []).join(' ');
      const verbModifiersSuffix = get(node, 'verbModifiersSuffix', []).join(' ');
      const verbModifiersObjectSuffix = get(node, 'verbModifiersObjectSuffix', []).join(' ');
      const predicateJsx = (
        <span className={styles['element-wrapper']}>
          <SapienSentenceElement
            type="predicate"
            prefix={(
              <>
                {verbPrefix}
                {verbPrefix && ' '}
                {verbModifiersPrefix}
                {verbModifiersPrefix && ' '}
              </>
            )}
            text={(
              <>
                {get(node, 'predicate.verb')}
              </>
            )}
            suffix={(
              <>
                {verbSuffix && ' '}
                {verbSuffix}
                {verbModifiersSuffix && ' '}
                {verbModifiersSuffix}
              </>
            )}
            tooltipData={{
              Tense: get(node, 'predicate.tense') || 'None',
              'Verb Conjugation': get(node, 'predicate.conjugation') || 'None',
              'Verb Modifiers Subject Prefix': verbModifiersSubjectPrefix || 'None',
              'Verb Modifiers Prefix': verbModifiersPrefix || 'None',
              'Verb Prefix': get(node, 'predicate.verbPrefix') || 'None',
              'Verb Suffix': get(node, 'predicate.verbSuffix') || 'None',
              'Verb Modifiers Suffix': verbModifiersSuffix || 'None',
              'Verb Modifiers Object Suffix': verbModifiersObjectSuffix || 'None',
            }}
          />
        </span>
      );

      // Object
      const objectPrepositions = get(node, 'prepositions', []).filter((p) => (get(p, 'prepositionType') === 'Object'));
      const objectPrepositionsJsx = (!isEmpty(objectPrepositions)
        && (
        <span className={styles['element-wrapper']}>
          {renderPrepositions(objectPrepositions)}
        </span>
        )
      );
      const objectJsx = renderGraphNode(get(node, 'object'), 'object', objectPrepositionsJsx);
      const qualifiedObjectJsx = renderGraphNode(get(node, 'qualifiedObject'), 'qualified');

      // Relationship
      let relationshipClass = entityType;
      if (get(node, 'inferred')) {
        relationshipClass = 'inferred';
      }
      if (get(node, 'nested')) {
        relationshipClass = 'nested';
      }
      if (get(node, 'qualified')) {
        relationshipClass = 'qualified';
      }
      if (get(node, 'artificial')) {
        relationshipClass = 'artificial';
      }
      const relationshipJsx = (
        <span className={styles['element-wrapper']}>
          <SapienSentenceElement
            type="relationship"
            relationshipType={relationshipClass}
            text={(
              <>
                {questionJsx}
                {qualifiersJsx}
                {verbModifiersSubjectPrefix}
                {verbModifiersSubjectPrefix && ' '}
                {subjectJsx}
                {predicateJsx}
                {objectJsx}
                {verbModifiersObjectSuffix && ' '}
                {verbModifiersObjectSuffix}
                {predicatePrepositionsJsx}
                {qualifiedObjectJsx}
              </>
            )}
            tooltipData={{
              'Inferred Relation': get(node, 'inferred') ? 'Yes' : 'No',
              'Nested Relation': get(node, 'nested') ? 'Yes' : 'No',
              'Qualified Relation': get(node, 'qualified') ? 'Yes' : 'No',
              'Artificial Relation Type': get(node, 'artificialType') || 'None',
              Confidence: get(node, 'confidence') || 'None',
            }}
          />
        </span>
      );
      return relationshipJsx;
    } if (get(node, 'type') === 'entity') {
      const determiner = get(node, 'determiner');
      const entityModifiersPrefix = get(node, 'entityModifiersPrefix', []).join(' ');
      const entityModifiersSuffix = get(node, 'entityModifiersSuffix', []).join(' ');
      const possessiveSuffix = get(node, 'possessiveSuffix');
      const possessiveEntity = get(node, 'possessiveEntity');
      const ner = get(node, 'ner', []);
      const nerInfo = (
        ner
          .slice(0, 5)
          .filter((nerObj) => get(nerObj, 'label') && get(nerObj, 'id'))
          .map((nerObj) => `${get(nerObj, 'label')} (${get(nerObj, 'id')})`)
      );
      const entityJsx = (
        <span className={styles['element-wrapper']}>
          <SapienSentenceElement
            type={entityType}
            prefix={(
              <>
                {determiner}
                {determiner && ' '}
                {possessiveEntity && renderGraphNode(possessiveEntity)}
                {possessiveEntity && possessiveSuffix}
                {(possessiveEntity && possessiveSuffix)
                  && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {entityModifiersPrefix}
                {entityModifiersPrefix && ' '}
              </>
            )}
            text={(
              <>
                {get(node, 'entity')}
              </>
            )}
            suffix={(
              <>
                {entityPrepositionsJsx}
                {entityModifiersSuffix && ' '}
                {entityModifiersSuffix}
              </>
            )}
            tooltipData={{
              Determiner: get(node, 'determiner') || 'None',
              'Proper Noun': get(node, 'properNoun') ? 'Yes' : 'No',
              'Grammatical Person': get(node, 'person') || 'None',
              'Entity Modifiers Prefix': entityModifiersPrefix || 'None',
              'Entity Modifiers Suffix': entityModifiersSuffix || 'None',
              ...!isEmpty(nerInfo) && { 'Cortex Knowledge Graph ID': nerInfo },
            }}
          />
        </span>
      );
      return entityJsx;
    }

    return null;
  };

  const renderSentences = () => {
    if (isEmpty(sentences)) {
      return (
        <div className={styles['no-results']}>No sentences were found.</div>
      );
    }

    const relationships = [];
    sentences.forEach((sentence) => {
      const seen = {};
      const alternative = get(sentence, 'alternatives[0]', sentence);
      const graph = get(alternative, 'graph', []);
      graph.forEach((graphItem) => {
        if (get(graphItem, 'artificialType') === 'EntityPreposition') {
          return;
        }
        const graphNode = renderGraphNode(graphItem, '');
        const graphNodeText = renderToString(graphNode);
        if (!seen[graphNodeText]) {
          relationships.push(
            <div
              key={relationships.length}
              className={styles['top-level-relationship']}
            >
              {graphNode}
            </div>,
          );
        }
        seen[graphNodeText] = true;
      });
    });

    if (isEmpty(relationships)) {
      return (
        <div className={styles['no-results']}>No relationships were found in the text.</div>
      );
    }

    return relationships;
  };

  return (
    <div className={styles.outer}>
      <div className={styles.wrapper}>
        {renderSentences()}
      </div>
    </div>
  );
};

SapienSentenceGraphVisualizer.propTypes = {
  sentences: PropTypes.array.isRequired,
};

export default SapienSentenceGraphVisualizer;
