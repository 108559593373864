import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'evergreen-ui';

const SuccessRow = ({ message }) => (
  <div>
    <Alert
      intent="success"
      title={message}
    />
  </div>
);

SuccessRow.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SuccessRow;
