import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAsyncCallback } from 'react-async-hook';
import {
  Button,
  Select,
} from 'evergreen-ui';
import {
  get,
  isEmpty,
} from 'lodash';

import Section from 'components/Section';
import Copy from 'components/Copy';
import Form from 'components/Form';
import RowLayout from 'components/RowLayout';
import CortexRelationshipCard from 'components/CortexRelationshipCard';

import { makeApiRequest } from 'utils/api';
import { aaCortexDemoStructured } from 'constants/routes';
import { ACCENTS } from 'constants/themes';

import demoStyles from 'pages/demo/styles.module.scss';

const OPTIONS = [
  'How tall is Michael Jordan?',
  'What is the area of California?',
  'What is the Empire State Building made of?',
  'What is the boiling point of water?',
  'Who is Hassan Rouhani married to and how many children does Rouhani have?',
  'Who directed Shawshank Redemption, Good Will Hunting, and Dead Poets Society?',
  // 'When was Abraham Lincoln born and when was Thomas Jefferson president?',
  'Who were Abraham Lincoln and Thomas Jefferson?',
  // 'Who wrote Hamlet and who is the author of War and Peace?',
  'Who wrote Hamlet and War and Peace?',
  // 'When was Google founded and where was Apple founded?',
  // 'Who is the mayor of the capital of California?',
  'Who is Arya Stark\'s father\'s wife?',
  // 'Mark Zuckerberg\'s wife\'s birthday',
  // 'How old is the President?',
];

const fetchResult = async (query) => (
  makeApiRequest(aaCortexDemoStructured.path, { query })
);

const DemoCortexQueryNL = () => {
  const [queryQueryParam, setQueryQueryParam] = useQueryParam('q', StringParam);
  const [value, setValue] = useState(OPTIONS[0]);
  const asyncFetchResult = useAsyncCallback(fetchResult);

  /**
   * Run on initial load to determine if there is a supplied
   * query in the query params. If so, and it's valid, set
   * the dropdown value and run the query.
   */
  useEffect(() => {
    if (queryQueryParam) {
      if (OPTIONS.includes(queryQueryParam)) {
        setValue(queryQueryParam);
        asyncFetchResult.execute(queryQueryParam);
      } else {
        setQueryQueryParam(undefined);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (event) => {
    asyncFetchResult.reset();
    setQueryQueryParam(undefined);
    setValue(event.target.value);
  };

  const onSubmit = () => {
    asyncFetchResult.execute(value);
    setQueryQueryParam(value);
  };

  const renderResponseData = (response) => {
    const relationships = get(response, 'data.relationships', []);

    // No results were returned
    if (isEmpty(relationships)) {
      return (
        <div className={demoStyles['no-results']}>No results were found.</div>
      );
    }

    // Has results
    return (
      <RowLayout size="sm">
        {relationships.map((relationship, index) => (
          <CortexRelationshipCard
            key={index}
            {...relationship}
          />
        ))}
      </RowLayout>
    );
  };

  return (
    <Section
      padding="none"
      accent={ACCENTS.demo}
    >
      <Copy
        variant="section"
        caption="Cortex Knowledge Graph"
        title="Query in natural language"
        body={(
          <p>
            Answer questions on over 20+ million entities by querying
            the Cortex Knowledge Graph in natural language.
          </p>
        )}
        footer={(
          <>
            <Form>
              <Form.FieldRow>
                <Select
                  height={40}
                  width="100%"
                  value={value}
                  onChange={onChange}
                >
                  {OPTIONS.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </Select>
              </Form.FieldRow>
              <Form.ButtonRow>
                <Button
                  height={40}
                  appearance="primary"
                  isLoading={asyncFetchResult.loading}
                  onClick={onSubmit}
                >
                  Run
                </Button>
              </Form.ButtonRow>
              <Form.ErrorRow error={asyncFetchResult.error} />
            </Form>

            {/* Results */}
            {asyncFetchResult.result
              && (
              <div className={demoStyles['response-data']}>
                {renderResponseData(asyncFetchResult.result)}
              </div>
              )}
          </>
        )}
      />
    </Section>
  );
};

export default DemoCortexQueryNL;
