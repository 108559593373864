export const CUSTOM = 'Custom';
export const OPTIONS = {
  'Barack Obama': 'Barack Hussein Obama II is an American politician who served as the 44th President of the United States from 2009 to 2017. He is the first African American to have served as president, as well as the first born outside the contiguous United States. He previously served in the U.S. Senate representing Illinois from 2005 to 2008, and in the Illinois State Senate from 1997 to 2004.',
  'Election Interference': 'The FBI held an unforeseen news conference on Wednesday to say that Iran and Russia have been leading a misinformation campaign ahead of this year’s election.\n\nAlthough the sudden nature of Wednesday’s news conference made the announcement seem urgent, intelligence community officials have been warning of interference by Iran, China and Russia for months.\n\nAs part of Evanina’s assessment, he said that China and Iran were attempting to seek a favorable outcome for Biden, while Russia was working to ensure a favorable outcome for Trump.',
  'Toy Story': 'Toy Story is a 1995 American computer-animated buddy comedy adventure film produced by Pixar Animation Studios and released by Walt Disney Pictures. The directorial debut of John Lasseter, Toy Story was the first feature-length computer-animated film and the first theatrical film produced by Pixar. The screenplay was written by Joss Whedon, Andrew Stanton, Joel Cohen, and Alec Sokolow, based on a story by Lasseter, Pete Docter, Stanton and Joe Ranft. The film features music by Randy Newman, and was executive produced by Steve Jobs and Edwin Catmull.',
  'Customer Service': 'With Remind, you can send text messages straight to any phone, including flip phones.\nYou can change the name of the class by going to class settings in both web and app.\nYou can join a class to receive Remind messages via text, email, or push notification.\nYou\'ll receive an email from Remind with a password reset link to access your account again.\nUS based teachers can add phone numbers or email addresses if available.',
  'Medical Research': 'Identification of drug targets and mechanism of action (MoA) for new and uncharacterized anticancer drugs is important for optimization of treatment efficacy. Current MoA prediction largely relies on prior information including side effects, therapeutic indication, and chemo-informatics. Such information is not transferable or applicable for newly identified, previously uncharacterized small molecules. Therefore, a shift in the paradigm of MoA predictions is necessary towards development of unbiased approaches that can elucidate drug relationships and efficiently classify new compounds with basic input data. We propose here a new integrative computational pharmacogenomic approach, referred to as Drug Network Fusion (DNF), to infer scalable drug taxonomies that relies only on basic drug characteristics towards elucidating drug-drug relationships.',
  'Electronic Health Records': 'This is a 43 year old Malaysian-American female with a medical history significant for a laparoscopic cholecystectomy in 2002. Since the birth of her second child in 1992, she has had periodic episodes of abdominal pain with or without N/V about every 6-24 months, usually localized to the RUQ. Most of these episodes have resolved spontaneously without medical care, but she has sought medical care on several of these occasions. In August of 2002, she underwent a laparoscopic cholecystectomy. Following this operation, she continued to have periodic abdominal pain, of the same character and at the same frequency.',
  'COVID-19 News': 'The United States reported 48,210 new cases and 388 more deaths yesterday, according to the Johns Hopkins online dashboard. The global number of COVID-19 cases passed 40 million today, led by a deepening situation in Europe and a steady rise in cases in the United States.',
  Questions: 'What is the area of California?\nHow tall is Michael Jordan?\nWhat is the Empire State Building made of?\nWhat is the boiling point of water?\nWho directed Shawshank Redemption, Good Will Hunting, and Dead Poets Society?\nWhen was Google founded and where was Apple founded?\nWho is the mayor of the capital of California?\nHow old is the President?',
  [CUSTOM]: '',
};
