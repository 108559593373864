import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowRight, FiArrowUpRight } from 'react-icons/fi';

import Link from 'components/Link';

import { isThirdPartyUrl } from 'utils';

import styles from './styles.module.scss';

const Banner = ({
  tag, text, to, until,
}) => {
  if (new Date() > until) {
    return null;
  }

  const innerContent = (
    <>
      {tag}
      <span className={styles.text}>
        {text}
      </span>
      {to && (
        isThirdPartyUrl(to)
          ? <FiArrowUpRight className={styles.arrow} />
          : <FiArrowRight className={styles.arrow} />
      )}
    </>
  );

  return (
    to
      ? (
        <Link
          decoration={false}
          to={to}
          className={styles.banner}
        >
          {innerContent}
        </Link>
      )
      : (
        <div
          className={styles.banner}
        >
          {innerContent}
        </div>
      )
  );
};

Banner.defaultProps = {
  to: undefined,
  until: null,
};

Banner.propTypes = {
  tag: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  until: PropTypes.instanceOf(Date),
};

export default Banner;
