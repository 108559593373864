import React from 'react';

import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import CTAButton from 'components/CTAButton';

import {
  __demoSapienParsing,
  __demoSapienExtractRelations,
  __demoCortexQueryNL,
} from 'pages/demo/routes';

import { ACCENTS } from 'constants/themes';

const DemoHome = () => (
  <Section
    padding="none"
    accent={ACCENTS.demo}
  >
    <RowLayout size="sm">
      <Copy
        variant="section"
        title="Demos"
      />
      <Copy
        useAccent
        variant="subsection"
        title="Sapien Language Engine"
        body={(
          <p>
            Sapien handles the low-level natural language
            processing tasks needed for most applications.
            It is written for speed and accuracy, and it
            works on text across various domains and writing
            styles.
          </p>
        )}
        footer={(
          <RowLayout>
            <ColumnLayout columns="2,2">
              <Copy
                variant="detail"
                title="Low-level text parsing"
                body={(
                  <p>
                    Handle low-level NLP tasks including part-of-speech
                    (POS) tagging, lemmatization, tokenization, and dependency
                    parsing.
                  </p>
                )}
                footer={(
                  <CTAButton
                    variant="button"
                    text="Try it out"
                    to={__demoSapienParsing.path}
                  />
                )}
              />
              <Copy
                variant="detail"
                title="Extract relations from sentences"
                body={(
                  <p>
                    Discover how entities are related to one another in
                    a complex sentence.
                  </p>
                )}
                footer={(
                  <CTAButton
                    variant="button"
                    text="Try it out"
                    to={__demoSapienExtractRelations.path}
                  />
                )}
              />
            </ColumnLayout>
          </RowLayout>
        )}
      />
      <Copy
        useAccent
        variant="subsection"
        title="Cortex Knowledge Graph"
        body={(
          <p>
            Cortex enriches your application with information
            about the real world. Use Cortex to determine
            the population of a city or find relationships
            from a diverse set of entities. Uncover this
            information using just a natural language question.
          </p>
        )}
        footer={(
          <RowLayout>
            <ColumnLayout columns="2,2">
              <Copy
                variant="detail"
                title="Query in natural language"
                body={(
                  <p>
                    Answer questions on over 20+ million entities by querying
                    the Cortex Knowledge Graph in natural language.
                  </p>
                )}
                footer={(
                  <CTAButton
                    variant="button"
                    text="Try it out"
                    to={__demoCortexQueryNL.path}
                  />
                )}
              />
            </ColumnLayout>
          </RowLayout>
        )}
      />
    </RowLayout>
  </Section>
);

export default DemoHome;
