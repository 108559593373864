import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from 'components/LazyImage';

import styles from './styles.module.scss';

const Image = ({
  src, placeholderSrc, alt, caption, className,
}) => (
  <div className={styles.outer}>
    <LazyImage
      src={src}
      placeholderSrc={placeholderSrc || null}
      alt={alt}
      effect="blur"
      className={className}
    />
    {caption
      && (
      <div className={styles.caption}>
        {caption}
      </div>
      )}
  </div>
);

Image.defaultProps = {
  caption: '',
  placeholderSrc: '',
  className: '',
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  placeholderSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string,
  className: PropTypes.string,
};

export default Image;
