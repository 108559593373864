import React from 'react';
import PropTypes from 'prop-types';
import {
  get,
  isEmpty,
  isNil,
} from 'lodash';

import SapienSentenceElement from 'components/SapienSentenceElement';

import styles from 'components/SapienSentenceGraphVisualizer/styles.module.scss';

const SapienCompetitorVisualizer = ({ relations }) => {
  const renderGraphNode = (node) => {
    let argumentIndex = 0;

    // Subject
    const subjectJsx = get(node, '[0]', []).map((entity, index) => {
      argumentIndex += 1;
      return (
        <span
          key={index}
          className={styles['element-wrapper']}
        >
          <SapienSentenceElement
            type="argument"
            argumentIndex={argumentIndex}
            text={entity}
          />
        </span>
      );
    });

    // Object
    const objectJsx = get(node, '[2]', []).map((entity, index) => {
      argumentIndex += 1;
      return (
        <span
          key={index}
          className={styles['element-wrapper']}
        >
          <SapienSentenceElement
            type="argument"
            argumentIndex={argumentIndex}
            text={entity}
          />
        </span>
      );
    });

    // Predicate
    const predicateJsx = (
      <span className={styles['element-wrapper']}>
        <SapienSentenceElement
          type="predicate"
          text={get(node, '[1].verb')}
        />
      </span>
    );

    // Relationship
    const relationshipJsx = (
      <span className={styles['element-wrapper']}>
        <SapienSentenceElement
          type="relationship"
          text={(
            <>
              {subjectJsx}
              {predicateJsx}
              {objectJsx}
            </>
          )}
          tooltipData={!isNil(get(node, '[1].confidence')) && {
            Confidence: get(node, '[1].confidence').toFixed(3),
          }}
        />
      </span>
    );

    return relationshipJsx;
  };

  const renderSentences = () => {
    const relationships = relations.map((relation, index) => (
      <div
        key={index}
        className={styles['top-level-relationship']}
      >
        {renderGraphNode(relation)}
      </div>
    ));

    if (isEmpty(relationships)) {
      return (
        <div className={styles['no-results']}>No relationships were found in the text.</div>
      );
    }

    return relationships;
  };

  return (
    <div className={styles.outer}>
      <div className={styles.wrapper}>
        {renderSentences()}
      </div>
    </div>
  );
};

SapienCompetitorVisualizer.propTypes = {
  relations: PropTypes.array.isRequired,
};

export default SapienCompetitorVisualizer;
