import React from 'react';

import DocLayout from 'components/DocLayout';

import { aaSapienNames } from 'constants/routes';

const Names = () => (
  <DocLayout>
    <DocLayout.Title>
      Names
    </DocLayout.Title>
    <DocLayout.Body>
      Returns information on a name.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Request
    </DocLayout.Subheader>
    <DocLayout.Table
      headers={[
        'Parameter',
        'Type',
        'Values',
      ]}
      rows={[
        [
          <>
            <DocLayout.InlineCode>name</DocLayout.InlineCode>
            <DocLayout.RequiredField />
          </>,
          <>
            <DocLayout.InlineCode>String</DocLayout.InlineCode>
            ,
          </>,
          <>
            The
            {' '}
            <DocLayout.InlineCode>word</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                a string value like
                {' '}
                <DocLayout.InlineCode>bill gates</DocLayout.InlineCode>
                ,
                {' '}
                <DocLayout.InlineCode>bill</DocLayout.InlineCode>
                , or
                {' '}
                <DocLayout.InlineCode>gates</DocLayout.InlineCode>
              </li>
            </ul>
          </>,
        ],
        [
          <>
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
          </>,
          <>
            <DocLayout.InlineCode>Boolean</DocLayout.InlineCode>
            ,
          </>,
          <>
            Pretty print the response. Useful for debugging. The
            {' '}
            <DocLayout.InlineCode>pretty</DocLayout.InlineCode>
            {' '}
            value may be:
            <ul>
              <li>
                <DocLayout.InlineCode>true</DocLayout.InlineCode>
              </li>
              <li>
                <DocLayout.InlineCode>false</DocLayout.InlineCode>
                <DocLayout.DefaultValue />
              </li>
            </ul>
          </>,
        ],
      ]}
    />

    <DocLayout.Subheader>
      Response
    </DocLayout.Subheader>
    <DocLayout.Body>
      Returns information on whether the input is a name,
      whether it is commonly known to be a male name, whether
      it is commonly known to be a female name, and whether it
      is commonly known to be a family name. Moreover,
      confidence levels of
      {' '}
      <DocLayout.InlineCode>Certain</DocLayout.InlineCode>
      ,
      {' '}
      <DocLayout.InlineCode>Likely</DocLayout.InlineCode>
      , or
      {' '}
      <DocLayout.InlineCode>Uncertain</DocLayout.InlineCode>
      {' '}
      are returned.
    </DocLayout.Body>

    <DocLayout.Subheader>
      Example
    </DocLayout.Subheader>
    <DocLayout.Example
      id="sapien-names-1"
      url={aaSapienNames.path}
      methods={['GET', 'POST']}
    />
  </DocLayout>
);

export default Names;
