import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowRight, FiArrowUpRight } from 'react-icons/fi';

import Link from 'components/Link';

import { isThirdPartyUrl } from 'utils';

import styles from './styles.module.scss';

const CTAButton = ({
  variant, arrow, to, text, className, ...rest
}) => {
  const outerClassName = [
    styles.cta,
    styles[variant],
    arrow ? styles['has-arrow'] : '',
    className,
  ].join(' ');

  const innerContent = (
    <>
      {text}
      {arrow && (
        isThirdPartyUrl(to)
          ? <FiArrowUpRight className={styles.arrow} />
          : <FiArrowRight className={styles.arrow} />
      )}
    </>
  );

  return (
    to
      ? (
        <Link
          decoration={false}
          to={to}
          className={outerClassName}
          {...rest}
        >
          {innerContent}
        </Link>
      )
      : (
        <div
          className={outerClassName}
          {...rest}
        >
          {innerContent}
        </div>
      )
  );
};

CTAButton.defaultProps = {
  variant: 'button',
  arrow: true,
  className: '',
  to: undefined,
};

CTAButton.propTypes = {
  variant: PropTypes.oneOf(['button', 'link']),
  arrow: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  text: PropTypes.node.isRequired,
  className: PropTypes.string,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default CTAButton;
