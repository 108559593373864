import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouteItem = ({
  path, exact, strict, sensitive, component, children, ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    sensitive={sensitive}
    {...((component && !children) && { component })}
    {...rest}
  >
    {children}
  </Route>
);

RouteItem.defaultProps = {
  exact: false,
  strict: false,
  sensitive: false,
  component: null,
  children: null,
  rest: {},
};

RouteItem.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default RouteItem;
