import { sortBy } from 'lodash';
import { newsLinks } from 'pages/news/routes';

export const sortedArticles = (
  sortBy(newsLinks, [(a) => a.meta.date]).reverse()
);

export const getNewestArticles = (count = 3) => (
  sortedArticles.slice(0, count)
);
