import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';

const Link = ({
  to, target, decoration, children, className, ...rest
}) => {
  const isExternalUrl = (
    typeof to === 'string'
    && (to.startsWith('https://')
      || to.startsWith('http://')
      || to.startsWith('mailto:')
      || to.startsWith('tel:'))
  );

  const isThirdPartyUrl = (
    isExternalUrl
    && !(to.includes('plasticity.ai') || to.includes('plasticityai.com'))
  );

  const finalTarget = target || (isThirdPartyUrl ? '_blank' : '');

  const classString = [
    styles.link,
    decoration ? styles.decoration : '',
    className,
  ].join(' ');

  return (
    isExternalUrl
      ? (
        <a
          className={classString}
          href={to}
          {...(finalTarget && { target: finalTarget })}
          {...(finalTarget === '_blank' && { rel: 'noreferrer' })}
          {...rest}
        >
          {children}
        </a>
      )
      : (
        <HashLink
          className={classString}
          to={to}
          {...rest}
        >
          {children}
        </HashLink>
      )
  );
};

Link.defaultProps = {
  decoration: true,
  children: null,
  target: '',
  className: '',
};

Link.propTypes = {
  decoration: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  target: PropTypes.oneOf(['_blank', '']),
  children: PropTypes.node,
  className: PropTypes.string,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Link;
