import React from 'react';

import Card from 'components/Card';
import SentenceFactVisualizer from 'components/SentenceFactVisualizer';
import { GridPentagon } from 'components/Backgrounds';

import styles from './styles.module.scss';

const SPANS = [
  'The global number', // 0
  ' ', // 1
  'of COVID-19 cases', // 2
  ' ', // 3
  'passed', // 4
  ' ', // 5
  '40 million', // 6
  ' ', // 7
  'today', // 8
  ',', // 9
  ' ', // 10
  'led', // 11
  ' ', // 12
  'by', // 13
  ' ', // 14
  'a deepening situation', // 15
  ' ', // 16
  'in Europe', // 17
  ' ', // 18
  'and', // 19
  ' ', // 20
  'a steady rise', // 21
  ' ', // 22
  'in cases', // 23
  ' ', // 24
  'in the United States', // 25
  '.', // 26
];

const FACTS = {
  'The global number of COVID-19 cases passed 40 million today': [0, 2, 4, 6, 8],
  'The global number was led by a deepening situation in Europe': [0, 11, 13, 15, 17],
  'The global number was led by a steady rise in cases in the United States': [0, 11, 13, 21, 23, 25],
  'A situation in Europe is deepening': [15, 17],
  'A rise in cases in the United States is steady': [21, 23, 25],
};

const GovernmentHero = () => (
  <div className={styles.container}>
    <Card className={styles.hero}>
      <SentenceFactVisualizer
        className={styles.visualizer}
        facts={FACTS}
        spans={SPANS}
      />
    </Card>
    <GridPentagon className={styles.background} />
  </div>
);

export default GovernmentHero;
