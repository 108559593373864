import React from 'react';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import GridLayout from 'components/GridLayout';
import Copy from 'components/Copy';
import Card from 'components/Card';
import CodeBlock from 'components/CodeBlock';
import FeatureCard from 'components/FeatureCard';
import CTAButton from 'components/CTAButton';
import Link from 'components/Link';
import HomeHero from 'components/HomeHero';
import LazyImage from 'components/LazyImage';
import Banner from 'components/Banner';
import Tag from 'components/Tag';

import { FLAVORS, ACCENTS } from 'constants/themes';
import {
  __apiSapien,
  __apiCortex,
  __apiLingua,
  __government,
  __commercial,
  __developers,
  __demo,
  __docs,
  __scheduleDemo,
  __termsNotice,
  xxBenchmarkSapien,
} from 'constants/routes';

import styles from './styles.module.scss';

const imagesFolder = `${process.env.PUBLIC_URL}/images`;

const Home = () => (
  <>
    <SiteHeader
      variant="overlay"
      theme="dark"
      accent={ACCENTS.home}
    />
    <Section
      className={styles['home-hero']}
      theme="dark"
      gradient="full"
      flavor={FLAVORS.home}
      accent={ACCENTS.home}
      layoutElement={(
        <Banner
          tag={<Tag accent="purple">INFO</Tag>}
          text="We've made some updates to our API"
          to={__termsNotice.path}
          until={new Date('April 24, 2021')}
        />
      )}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="hero"
            title="Machines that comprehend language"
            titleClassName={styles['home-hero-title']}
            body={(
              <p>
                Join hundreds of organizations using Plasticity&apos;s
                natural language processing products and APIs
                to understand unstructured data and extract information
                from text.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request a demo"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demo.path}
                />
              </>
            )}
          />
          <HomeHero />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="light"
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Industries"
            title="Powering products and building solutions across industries"
            body={(
              <p>
                Plasticity provides prebuilt solutions to understand
                unstructured data and API endpoints for natural language
                processing. So whether you&apos;re searching for an
                off-the-shelf product or building one of your own, we have
                you covered. Today, we help government agencies and hundreds
                of companies in technology, law, and medicine, extract
                information from text.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Government and defense"
            body={(
              <p>
                Plasticity works with government agencies to extract
                meaning from unstructured data, explore entities and
                relationships in text, uncover disinformation in
                publicly available sources, and much more.
              </p>
            )}
            footer={(
              <CTAButton
                text="Learn more"
                variant="button"
                to={__government.path}
              />
            )}
          />
          <Link
            to={__government.path}
            decoration={false}
          >
            <LazyImage
              src={`${imagesFolder}/government/customers.png`}
              placeholderSrc={`${imagesFolder}/government/customers_low.png`}
              alt="Plasticity government customers"
            />
          </Link>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Companies and teams"
            body={(
              <p>
                Companies of all sizes use Plasticity to parse text,
                whether it&apos;s to find similarities in product reviews,
                automatically respond to customer support tickets,
                categorize feedback, or improve search. We also work
                directly with teams to customize our products for
                on-premise deployments.
              </p>
            )}
            footer={(
              <CTAButton
                text="Learn more"
                variant="button"
                to={__commercial.path}
              />
            )}
          />
          <Link
            to={__commercial.path}
            decoration={false}
          >
            <LazyImage
              src={`${imagesFolder}/commercial/customers.png`}
              placeholderSrc={`${imagesFolder}/commercial/customers_low.png`}
              alt="Plasticity commercial customers"
            />
          </Link>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Developers and applications"
            body={(
              <p>
                Add state-of-the-art natural language processing
                abilities to your application. Our easy-to-use
                JSON API works across programming languages, has
                99.9% uptime, and handles any workload you throw its
                way.
              </p>
            )}
            footer={(
              <CTAButton
                text="Learn more"
                variant="button"
                to={__developers.path}
              />
            )}
          />
          <Link
            to={__developers.path}
            decoration={false}
          >
            <LazyImage
              src={`${imagesFolder}/developers/platforms.png`}
              placeholderSrc={`${imagesFolder}/developers/platforms_low.png`}
              alt="Plasticity developer platforms"
            />
          </Link>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="white"
      flavor={FLAVORS.home}
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Use cases"
            title="Full-stack approach to natural language processing"
            body={(
              <p>
                We provide natural language products that fit anywhere
                in your stack. Whether it&apos;s a low-level task like
                part-of-speech tagging or a high-level objective
                like correlating content to detect patterns, we
                have products and API endpoints that plug in to
                get you started.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout columns="2,2">
          <RowLayout>
            <Copy
              useAccent
              variant="subsection"
              title="API endpoints"
              body={(
                <p>
                  Our API endpoints are easy to use and work
                  with any programming language via simple
                  HTTP requests. Have natural language processing
                  up and running in your app in minutes.
                </p>
              )}
            />
            <Copy
              variant="detail"
              title="Sapien Language Engine"
              body={(
                <p>
                  Core natural language understanding API that
                  extracts entities, relationships, and context
                  from text.
                </p>
              )}
              footer={(
                <CTAButton
                  text="Learn more"
                  variant="link"
                  to={__apiSapien.path}
                />
              )}
            />
            <Copy
              variant="detail"
              title="Cortex Knowledge Graph"
              body={(
                <p>
                  A graph of real word concepts, people, places,
                  and things that you can query in natural language.
                </p>
              )}
              footer={(
                <CTAButton
                  text="Learn more"
                  variant="link"
                  to={__apiCortex.path}
                />
              )}
            />
            <Copy
              variant="detail"
              title="Lingua Dialog Engine"
              body={(
                <p>
                  Parses text for intent and slots, then provides a
                  human-like natural language response.
                </p>
              )}
              footer={(
                <CTAButton
                  text="Learn more"
                  variant="link"
                  to={__apiLingua.path}
                />
              )}
            />
          </RowLayout>
          <GridLayout>
            <FeatureCard
              tilt
              body="Named entity recognition with accompanying metadata"
            />
            <FeatureCard
              tilt
              body="Part-of-speech tagging for complex sentences"
            />
            <FeatureCard
              tilt
              span={2}
              body="Open information extraction with nested subject-verb-object triples"
            />
            <FeatureCard
              tilt
              body="Open information extraction with nested triples"
            />
            <FeatureCard
              tilt
              body="Word transformation for nouns, verbs, adjectives, and adverbs"
            />
            <FeatureCard
              tilt
              body="Syntax dependency parsing"
            />
            <FeatureCard
              tilt
              body="First and last name detection"
            />
            <FeatureCard
              tilt
              body="Knowledge graph of 240+ million facts"
            />
            <FeatureCard
              tilt
              body="Complex, multi-part questions or inference questions"
            />
            <FeatureCard
              tilt
              body="Intent parsing and slot filling"
            />
            <FeatureCard
              tilt
              span={2}
              body="Query for information about entities and their properties in natural language"
            />
            <FeatureCard
              tilt
              body="Multi-turn conversations with context management"
            />
          </GridLayout>
        </ColumnLayout>
        <ColumnLayout columns="1">
          <Copy
            useAccent
            variant="subsection"
            title="Products and solutions"
            body={(
              <p>
                Off-the-shelf products and custom-built solutions
                to match your organization&apos;s exact use case.
              </p>
            )}
            footer={(
              <ColumnLayout columns="2,1,1">
                <Copy
                  variant="detail"
                  title="Disinformation Toolkit"
                  body={(
                    <p>
                      Search and filter through publicly available
                      information, like news and social media, to
                      find coordinated bot behavior and disinformation.
                      Easily create reports from your findings and
                      share with others.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="See government products"
                      variant="link"
                      to={__government.path}
                    />
                  )}
                />
                <Copy
                  variant="detail"
                  title="On-premise APIs"
                  body={(
                    <p>
                      Deploy any of Plasticity&apos;s APIs behind your
                      organization&apos;s cloud with our on-premise option.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="Deploy on-premise"
                      variant="link"
                      to={__scheduleDemo.path}
                    />
                  )}
                />
                <Copy
                  variant="detail"
                  title="Something else?"
                  body={(
                    <p>
                      Not finding what your use case? Get in touch
                      about a custom solution that meets your exact
                      needs.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="Request a solution"
                      variant="button"
                      to={__scheduleDemo.path}
                    />
                  )}
                />
              </ColumnLayout>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="dark"
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Why Plasticity"
            title="Powerfully accurate, blazingly fast, and incredibly simple"
          />
        </ColumnLayout>
        <ColumnLayout columns="1,1,1,1">
          <Copy
            variant="detail"
            title="State-of-the-art accuracy"
            body={(
              <p>
                Plasticity tops NLP accuracy benchmarks for open
                information extraction, coreference resolution, and
                slot filling.
              </p>
            )}
            footer={(
              <Link
                to={xxBenchmarkSapien.path}
                target="_blank"
                decoration={false}
              >
                <CTAButton
                  text="Benchmarks"
                  variant="link"
                />
              </Link>
            )}
          />
          <Copy
            variant="detail"
            title="Built for speed"
            body={(
              <p>
                Designed from the ground up to parse text at 6,000
                sentences per second, over 80x faster than other
                solutions.
              </p>
            )}
            footer={(
              <CTAButton
                text="Try it out"
                variant="link"
                to={__demo.path}
              />
            )}
          />
          <Copy
            variant="detail"
            title="Cloud or on-premise"
            body={(
              <p>
                Developers can use Plasticity&apos;s cloud NLP APIs
                directly in their applications, or we can deploy
                custom solutions on-premise.
              </p>
            )}
            footer={(
              <CTAButton
                text="Request on-premise"
                variant="link"
                to={__scheduleDemo.path}
              />
            )}
          />
          <Copy
            variant="detail"
            title="Get started in minutes"
            body={(
              <p>
                Integrates in minutes and works in any programming
                language. Add NLP to your app with just a few lines of code.
              </p>
            )}
            footer={(
              <CTAButton
                text="Explore the docs"
                variant="link"
                to={__docs.path}
              />
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="light"
      flavor={FLAVORS.home}
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="section"
            title="Getting started is easy"
            body={(
              <p>
                Add natural language understanding to your application
                in just a few minutes by signing up for a developer
                API account today. Schedule a demo with our sales team
                to discuss your specific needs and use cases&mdash;we&apos;re
                here to help.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demo.path}
                />
              </>
            )}
          />
          <Card>
            <CodeBlock
              language="python"
              code={
    `from plasticity import Plasticity

plasticity = Plasticity()

result = plasticity.sapien.core.post("""
Play let it be by The Beatles.
""", ner=True)`
              }
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Home;
