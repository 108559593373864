import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const List = ({ columns, children }) => (
  <div className={styles.outer}>
    <ul
      className={styles.list}
      data-columns={columns}
    >
      {children}
    </ul>
  </div>
);

List.defaultProps = {
  columns: 1,
  children: null,
};

List.propTypes = {
  columns: PropTypes.oneOf([1, 2, 3, 4]),
  children: PropTypes.node,
};

export default List;
