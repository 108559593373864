import React from 'react';
import PropTypes from 'prop-types';

import Section from 'components/Section';

import Title from 'components/DocLayout/Title';
import Header from 'components/DocLayout/Header';
import Subheader from 'components/DocLayout/Subheader';
import Body from 'components/DocLayout/Body';
import Table from 'components/DocLayout/Table';
import CodeBlock from 'components/DocLayout/CodeBlock';
import InlineCode from 'components/DocLayout/InlineCode';
import RequiredField from 'components/DocLayout/RequiredField';
import DefaultValue from 'components/DocLayout/DefaultValue';
import Example from 'components/DocLayout/Example';
import ComingSoon from 'components/DocLayout/ComingSoon';

import { FLAVORS, ACCENTS } from 'constants/themes';

const DocLayout = ({ children }) => (
  <Section
    padding="none"
    flavor={FLAVORS.docs}
    accent={ACCENTS.docs}
  >
    {children}
  </Section>
);

DocLayout.defaultProps = {
  children: null,
};

DocLayout.propTypes = {
  children: PropTypes.node,
};

DocLayout.Title = Title;
DocLayout.Header = Header;
DocLayout.Subheader = Subheader;
DocLayout.Body = Body;
DocLayout.Table = Table;
DocLayout.CodeBlock = CodeBlock;
DocLayout.InlineCode = InlineCode;
DocLayout.RequiredField = RequiredField;
DocLayout.DefaultValue = DefaultValue;
DocLayout.Example = Example;
DocLayout.ComingSoon = ComingSoon;
export default DocLayout;
