import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FieldRow = ({ columns, children }) => (
  <div
    className={styles.row}
    data-columns={columns}
  >
    {children}
  </div>
);

FieldRow.defaultProps = {
  children: null,
  columns: 1,
};

FieldRow.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.oneOf([
    1,
    2,
    3,
    4,
  ]),
};

export default FieldRow;
