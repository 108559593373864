import React from 'react';

export const SapienLine = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.077 14.703H8.91L7.74 18H4l6.353-16h3.259L20 18h-3.74l-1.183-3.297zm-5.216-2.67h4.266l-2.145-5.989-2.121 5.989zM4 20h16v2H4z" />
  </svg>
);

export const CortexLine = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 10.625c-.283 0-.559.08-.794.232a1.388 1.388 0 00-.526.617 1.328 1.328 0 00-.08.794c.054.267.19.512.39.704.2.193.455.324.732.377.277.053.564.025.825-.079.261-.104.484-.28.641-.506.157-.226.24-.492.24-.764 0-.364-.15-.714-.419-.972A1.46 1.46 0 0012 10.625zM21.561 6.5c-.485-.838-1.808-2.18-5.19-1.8C15.41 2.5 13.947 1 12 1c-1.948 0-3.41 1.499-4.37 3.7-3.383-.375-4.706.962-5.191 1.8-.731 1.262-.67 3.142 1.122 5.5-1.792 2.358-1.853 4.238-1.122 5.5 1.3 2.25 4.538 1.875 5.19 1.8C8.59 21.5 10.053 23 12 23c1.948 0 3.41-1.498 4.37-3.7.653.075 3.89.45 5.191-1.8.731-1.262.67-3.142-1.122-5.5 1.792-2.358 1.853-4.238 1.122-5.5zM4.828 16.125c-.179-.312-.006-1.063.67-2.073.306.28.63.553.976.822.073.59.179 1.165.302 1.724-1.1.037-1.78-.188-1.948-.473zm1.646-6.999c-.346.269-.67.543-.977.822-.675-1.01-.848-1.761-.67-2.073.151-.263.732-.494 1.692-.494.078 0 .173.016.257.016-.129.575-.23 1.15-.302 1.73zM12 3.75c.424 0 .993.58 1.513 1.6-.503.162-1.005.344-1.513.554-.508-.21-1.01-.392-1.513-.553.52-1.02 1.089-1.601 1.513-1.601zm0 16.5c-.424 0-.993-.58-1.513-1.6A19.855 19.855 0 0012 18.095c.508.21 1.01.392 1.513.553-.52 1.02-1.089 1.601-1.513 1.601zm0-4.813a3.671 3.671 0 01-1.984-.579A3.47 3.47 0 018.7 13.315a3.318 3.318 0 01-.203-1.986 3.4 3.4 0 01.978-1.76c.5-.48 1.135-.808 1.828-.94a3.701 3.701 0 012.064.195c.652.26 1.21.7 1.602 1.266.393.566.602 1.23.602 1.91 0 .912-.376 1.786-1.046 2.43A3.643 3.643 0 0112 15.439zm7.172.688c-.162.285-.848.51-1.948.473.123-.559.23-1.134.302-1.724.346-.269.67-.543.977-.822.675 1.01.848 1.761.67 2.073zm-.67-6.177c-.306-.279-.63-.553-.976-.822a18.359 18.359 0 00-.302-1.729c.084 0 .179-.016.257-.016.96 0 1.54.23 1.691.494.179.312.006 1.063-.67 2.073z" />
  </svg>
);

export const LinguaLine = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.378 14.24c-.26.686-.478 1.32-.674 1.96 1.067-.766 2.335-1.252 3.798-1.433 2.792-.346 5.274-2.17 6.53-4.464l-1.619-1.6 1.57-1.557 1.111-1.101c.478-.473 1.017-1.347 1.587-2.605-6.214.954-10.02 4.721-12.304 10.8zM17.556 8.7l1.11 1.099c-1.11 3.3-4.444 6.6-8.888 7.15-2.966.367-4.818 2.384-5.558 6.05H2C3.111 16.4 5.333 1 22 1c-1.111 3.297-2.22 5.496-3.33 6.597L17.556 8.7z" />
  </svg>
);
