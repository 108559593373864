import React from 'react';

import BlogPostLayout from 'components/BlogPostLayout';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Link from 'components/Link';
import CTAButton from 'components/CTAButton';

import styles from './styles.module.scss';

export const meta = {
  date: new Date('Dec 11 2019'),
  title: 'Political Disinformation Campaign on YouTube',
  shortDescription: 'Uncovering a political disinformation campaign on YouTube with 8M+ views',
  longDescription: 'Plasticity\'s natural language processing and AI tools helped find a new, sophisticated YouTube political troll campaign with 8M+ views one year out from the 2020 U.S. presidential election.',
  category: 'engineering',
  tags: [
    'report',
    'government',
    'disinformation',
  ],
};

const imageFolder = `${process.env.PUBLIC_URL}/blog/posts/political-disinformation-campaign-on-youtube/images`;

const PoliticalDisinformationCampaignOnYoutube = () => (
  <BlogPostLayout {...meta}>
    <BlogPostLayout.Header>
      Background
    </BlogPostLayout.Header>
    <BlogPostLayout.Body>
      The New York Times
      {' '}
      <Link to="https://www.nytimes.com/2018/02/18/world/europe/russia-troll-factory.html">reported on the Internet Research Agency (IRA)</Link>
      ,
      a firm believed to be funded by the
      Russian state government, hiring &ldquo;trolls&rdquo; to post
      divisive content to social media sites like Facebook,
      Twitter, YouTube, Reddit, and Tumblr around American
      political issues to sow political discord. These
      efforts were also detailed in the bipartisan report
      by the
      {' '}
      <Link to="https://www.npr.org/2019/10/08/768319934/senate-report-russians-used-used-social-media-mostly-to-target-race-in-2016">Senate Intelligence Committee</Link>
      .
      In 2018, Twitter published an
      {' '}
      <Link to="https://github.com/fivethirtyeight/russian-troll-tweets/">extensive dataset</Link>
      {' '}
      of content posted by and associated with the IRA&apos;s activity
      on the platform allowing researchers to study their behavior (
      <Link to="http://pwarren.people.clemson.edu/Linvill_Warren_TrollFactory.pdf">Linvill and Warren</Link>
      ,
      {' '}
      <Link to="https://arxiv.org/pdf/1901.11162.pdf">Im et. al</Link>
      ,
      {' '}
      <Link to="https://www.symantec.com/blogs/threat-intelligence/twitterbots-propaganda-disinformation">Cleary and Symantec</Link>
      ).
    </BlogPostLayout.Body>
    <BlogPostLayout.Body>
      While often Twitter and Facebook are the focus of the
      media with regard to these types of troll campaigns,
      in this post we describe how our tools helped us find
      a new, sophisticated YouTube political troll campaign
      with 8M+ views one year out from the 2020 U.S. presidential
      election. Our tools aim to help detect similar campaigns
      by networks of bot, troll, and propaganda accounts for
      {' '}
      <Link to="https://www.cia.gov/news-information/featured-story-archive/2010-featured-story-archive/open-source-intelligence.html">OSINT (open source intelligence)</Link>
      {' '}
      purposes.
    </BlogPostLayout.Body>
    <BlogPostLayout.Body>
      Plasticity is currently contracted to work with the U.S.
      Air Force to conduct research and build solutions for
      military intelligence analysts. The software described
      in the post is connected to that effort, but the data,
      analysis, and any conclusions reached are of our own
      and do not represent the official views of the U.S.
      Air Force, the Department of Defense, or the U.S.
      federal government.
    </BlogPostLayout.Body>

    <BlogPostLayout.Subheader>
      Responsible Disclosure
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Body>
      Upon discovering the disinformation campaign described
      in this post, we performed responsible disclosure to
      Google / YouTube to allow them to review and suspend
      the accounts in accordance with their policies and put
      in counter-measures to stop the campaign.
    </BlogPostLayout.Body>
    {/* TODO: timeline */}

    <BlogPostLayout.Subheader>
      Media Coverage
    </BlogPostLayout.Subheader>
    <RowLayout>
      <ColumnLayout columns="2,2">
        <div>
          <BlogPostLayout.Body>
            Plasticity&apos;s work was covered by CNN. CNN broke
            the story on our report online and on their TV
            network.
          </BlogPostLayout.Body>
          <CTAButton
            to="https://www.cnn.com/2019/12/13/tech/youtube-fake-accounts-viral/index.html"
            variant="button"
            text="Read the CNN investigative article"
          />
        </div>
        <BlogPostLayout.Video src="https://www.youtube-nocookie.com/embed/QtSTPeMOFQQ?modestbranding=1&autohide=1" />
      </ColumnLayout>
    </RowLayout>

    <BlogPostLayout.Header>
      What We Found
    </BlogPostLayout.Header>
    <BlogPostLayout.Body>
      On October 30, 2017, YouTube released a statement on
      their findings during the 2016 election:
    </BlogPostLayout.Body>
    <BlogPostLayout.Quote
      text="We found 18 channels likely associated with this campaign that made videos publicly available, in English and with content that appeared to be political... there were 1108 such videos uploaded, representing 43 hours of content... These videos generally had very low view counts; only around 3 percent had more than 5,000 views."
      attribution="Kent Walker"
      attributionTitle="SVP and General Counsel, Google"
      attributionSource="https://www.blog.google/topics/public-policy/security-and-disinformation-us-2016-election/"
    />
    <BlogPostLayout.Body>
      We have found evidence of a more significant campaign
      with accounts linked to the Russia and Ukraine region,
      much larger than the one previously found, driving
      higher engagement with users and thriving on YouTube.
      We show some of the data our intelligence tools
      collected below.
    </BlogPostLayout.Body>

    <BlogPostLayout.Subheader>
      Fake YouTube News Channels
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Slider>
      <div>
        <a href="#channel01">
          <img
            src={`${imageFolder}/channels/01.png`}
            className={styles['channel-image']}
            data-index="01"
            alt="Channel 01"
          />
        </a>
      </div>
      <div>
        <a href="#channel02">
          <img
            src={`${imageFolder}/channels/02.png`}
            className={styles['channel-image']}
            data-index="02"
            alt="Channel 02"
          />
        </a>
      </div>
      <div>
        <a href="#channel03">
          <img
            src={`${imageFolder}/channels/03.png`}
            className={styles['channel-image']}
            data-index="03"
            alt="Channel 03"
          />
        </a>
      </div>
      <div>
        <a href="#channel04">
          <img
            src={`${imageFolder}/channels/04.png`}
            className={styles['channel-image']}
            data-index="04"
            alt="Channel 04"
          />
        </a>
      </div>
      <div>
        <a href="#channel05">
          <img
            src={`${imageFolder}/channels/05.png`}
            className={styles['channel-image']}
            data-index="05"
            alt="Channel 05"
          />
        </a>
      </div>
      <div>
        <a href="#channel06">
          <img
            src={`${imageFolder}/channels/06.png`}
            className={styles['channel-image']}
            data-index="06"
            alt="Channel 06"
          />
        </a>
      </div>
      <div>
        <a href="#channel07">
          <img
            src={`${imageFolder}/channels/07.png`}
            className={styles['channel-image']}
            data-index="07"
            alt="Channel 07"
          />
        </a>
      </div>
      <div>
        <a href="#channel08">
          <img
            src={`${imageFolder}/channels/08.png`}
            className={styles['channel-image']}
            data-index="08"
            alt="Channel 08"
          />
        </a>
      </div>
      <div>
        <a href="#channel09">
          <img
            src={`${imageFolder}/channels/09.png`}
            className={styles['channel-image']}
            data-index="09"
            alt="Channel 09"
          />
        </a>
      </div>
      <div>
        <a href="#channel10">
          <img
            src={`${imageFolder}/channels/10.png`}
            className={styles['channel-image']}
            data-index="10"
            alt="Channel 10"
          />
        </a>
      </div>
      <div>
        <a href="#channel11">
          <img
            src={`${imageFolder}/channels/11.png`}
            className={styles['channel-image']}
            data-index="11"
            alt="Channel 11"
          />
        </a>
      </div>
      <div>
        <a href="#channel12">
          <img
            src={`${imageFolder}/channels/12.png`}
            className={styles['channel-image']}
            data-index="12"
            alt="Channel 12"
          />
        </a>
      </div>
      <div>
        <a href="#channel13">
          <img
            src={`${imageFolder}/channels/13.png`}
            className={styles['channel-image']}
            data-index="13"
            alt="Channel 13"
          />
        </a>
      </div>
      <div>
        <a href="#channel14">
          <img
            src={`${imageFolder}/channels/14.png`}
            className={styles['channel-image']}
            data-index="14"
            alt="Channel 14"
          />
        </a>
      </div>
      <div>
        <a href="#channel15">
          <img
            src={`${imageFolder}/channels/15.png`}
            className={styles['channel-image']}
            data-index="15"
            alt="Channel 15"
          />
        </a>
      </div>
      <div>
        <a href="#channel16">
          <img
            src={`${imageFolder}/channels/16.png`}
            className={styles['channel-image']}
            data-index="16"
            alt="Channel 16"
          />
        </a>
      </div>
      <div>
        <a href="#channel17">
          <img
            src={`${imageFolder}/channels/17.png`}
            className={styles['channel-image']}
            data-index="17"
            alt="Channel 17"
          />
        </a>
      </div>
      <div>
        <a href="#channel18">
          <img
            src={`${imageFolder}/channels/18.png`}
            className={styles['channel-image']}
            data-index="18"
            alt="Channel 18"
          />
        </a>
      </div>
      <div>
        <a href="#channel19">
          <img
            src={`${imageFolder}/channels/19.png`}
            className={styles['channel-image']}
            data-index="19"
            alt="Channel 19"
          />
        </a>
      </div>
      <div>
        <a href="#channel20">
          <img
            src={`${imageFolder}/channels/20.png`}
            className={styles['channel-image']}
            data-index="20"
            alt="Channel 20"
          />
        </a>
      </div>
    </BlogPostLayout.Slider>

    <BlogPostLayout.Subheader>
      Doctored Video Thumbnails and Fake News Headlines
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Body>
      Video thumbnails are doctored to show Democratic and
      Republican leadership in outrageous and violent
      scenarios, such as being locked in a jail cell or
      hanged by a noose. The headlines are also edited to
      say things like &ldquo;HANG HIM UP!&rdquo;, which a reputable
      news organization would never do.
    </BlogPostLayout.Body>
    <BlogPostLayout.Slider>
      <div>
        <a href="#channel01">
          <img
            src={`${imageFolder}/thumbnails/01.jpg`}
            className={styles['thumbnail-image']}
            data-index="01"
            alt="Thumbnail 01"
          />
        </a>
      </div>
      <div>
        <a href="#channel02">
          <img
            src={`${imageFolder}/thumbnails/02.jpg`}
            className={styles['thumbnail-image']}
            data-index="02"
            alt="Thumbnail 02"
          />
        </a>
      </div>
      <div>
        <a href="#channel03">
          <img
            src={`${imageFolder}/thumbnails/03.jpg`}
            className={styles['thumbnail-image']}
            data-index="03"
            alt="Thumbnail 03"
          />
        </a>
      </div>
      <div>
        <a href="#channel04">
          <img
            src={`${imageFolder}/thumbnails/04.jpg`}
            className={styles['thumbnail-image']}
            data-index="04"
            alt="Thumbnail 04"
          />
        </a>
      </div>
      <div>
        <a href="#channel05">
          <img
            src={`${imageFolder}/thumbnails/05.jpg`}
            className={styles['thumbnail-image']}
            data-index="05"
            alt="Thumbnail 05"
          />
        </a>
      </div>
      <div>
        <a href="#channel06">
          <img
            src={`${imageFolder}/thumbnails/06.jpg`}
            className={styles['thumbnail-image']}
            data-index="06"
            alt="Thumbnail 06"
          />
        </a>
      </div>
      <div>
        <a href="#channel07">
          <img
            src={`${imageFolder}/thumbnails/07.jpg`}
            className={styles['thumbnail-image']}
            data-index="07"
            alt="Thumbnail 07"
          />
        </a>
      </div>
      <div>
        <a href="#channel08">
          <img
            src={`${imageFolder}/thumbnails/08.jpg`}
            className={styles['thumbnail-image']}
            data-index="08"
            alt="Thumbnail 08"
          />
        </a>
      </div>
      <div>
        <a href="#channel09">
          <img
            src={`${imageFolder}/thumbnails/09.jpg`}
            className={styles['thumbnail-image']}
            data-index="09"
            alt="Thumbnail 09"
          />
        </a>
      </div>
      <div>
        <a href="#channel10">
          <img
            src={`${imageFolder}/thumbnails/10.jpg`}
            className={styles['thumbnail-image']}
            data-index="10"
            alt="Thumbnail 10"
          />
        </a>
      </div>
      <div>
        <a href="#channel11">
          <img
            src={`${imageFolder}/thumbnails/11.jpg`}
            className={styles['thumbnail-image']}
            alt="Thumbnail sty"
            data-index="11"
          />
        </a>
      </div>
      <div>
        <a href="#channel12">
          <img
            src={`${imageFolder}/thumbnails/12.jpg`}
            className={styles['thumbnail-image']}
            data-index="12"
            alt="Thumbnail 12"
          />
        </a>
      </div>
      <div>
        <a href="#channel13">
          <img
            src={`${imageFolder}/thumbnails/13.jpg`}
            className={styles['thumbnail-image']}
            data-index="13"
            alt="Thumbnail 13"
          />
        </a>
      </div>
      <div>
        <a href="#channel14">
          <img
            src={`${imageFolder}/thumbnails/14.jpg`}
            className={styles['thumbnail-image']}
            data-index="14"
            alt="Thumbnail 14"
          />
        </a>
      </div>
      <div>
        <a href="#channel15">
          <img
            src={`${imageFolder}/thumbnails/15.jpg`}
            className={styles['thumbnail-image']}
            data-index="15"
            alt="Thumbnail 15"
          />
        </a>
      </div>
      <div>
        <a href="#channel16">
          <img
            src={`${imageFolder}/thumbnails/16.jpg`}
            className={styles['thumbnail-image']}
            data-index="16"
            alt="Thumbnail 16"
          />
        </a>
      </div>
      <div>
        <a href="#channel17">
          <img
            src={`${imageFolder}/thumbnails/17.jpg`}
            className={styles['thumbnail-image']}
            data-index="17"
            alt="Thumbnail 17"
          />
        </a>
      </div>
      <div>
        <a href="#channel18">
          <img
            src={`${imageFolder}/thumbnails/18.jpg`}
            className={styles['thumbnail-image']}
            data-index="18"
            alt="Thumbnail 18"
          />
        </a>
      </div>
      <div>
        <a href="#channel19">
          <img
            src={`${imageFolder}/thumbnails/19.jpg`}
            className={styles['thumbnail-image']}
            data-index="19"
            alt="Thumbnail 19"
          />
        </a>
      </div>
      <div>
        <a href="#channel20">
          <img
            src={`${imageFolder}/thumbnails/20.jpg`}
            className={styles['thumbnail-image']}
            data-index="20"
            alt="Thumbnail 20"
          />
        </a>
      </div>
      <div>
        <a href="#channel21">
          <img
            src={`${imageFolder}/thumbnails/21.jpg`}
            className={styles['thumbnail-image']}
            data-index="21"
            alt="Thumbnail 21"
          />
        </a>
      </div>
      <div>
        <a href="#channel22">
          <img
            src={`${imageFolder}/thumbnails/22.jpg`}
            className={styles['thumbnail-image']}
            data-index="22"
            alt="Thumbnail 22"
          />
        </a>
      </div>
      <div>
        <a href="#channel23">
          <img
            src={`${imageFolder}/thumbnails/23.jpg`}
            className={styles['thumbnail-image']}
            data-index="23"
            alt="Thumbnail 23"
          />
        </a>
      </div>
    </BlogPostLayout.Slider>

    <BlogPostLayout.Subheader>
      Inflammatory Blog and Meme Content
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Slider>
      <div>
        <a href="#blog01">
          <img
            src={`${imageFolder}/blogs/01.jpg`}
            className={styles['blog-image']}
            data-index="01"
            alt="Blog 01"
          />
        </a>
      </div>
      <div>
        <a href="#blog02">
          <img
            src={`${imageFolder}/blogs/02.jpg`}
            className={styles['blog-image']}
            data-index="02"
            alt="Blog 02"
          />
        </a>
      </div>
      <div>
        <a href="#blog03">
          <img
            src={`${imageFolder}/blogs/03.jpg`}
            className={styles['blog-image']}
            data-index="03"
            alt="Blog 03"
          />
        </a>
      </div>
      <div>
        <a href="#blog04">
          <img
            src={`${imageFolder}/blogs/04.jpg`}
            className={styles['blog-image']}
            data-index="04"
            alt="Blog 04"
          />
        </a>
      </div>
      <div>
        <a href="#blog05">
          <img
            src={`${imageFolder}/blogs/05.jpg`}
            className={styles['blog-image']}
            data-index="05"
            alt="Blog 05"
          />
        </a>
      </div>
      <div>
        <a href="#blog06">
          <img
            src={`${imageFolder}/blogs/06.jpg`}
            className={styles['blog-image']}
            data-index="06"
            alt="Blog 06"
          />
        </a>
      </div>
      <div>
        <a href="#blog07">
          <img
            src={`${imageFolder}/blogs/07.jpg`}
            className={styles['blog-image']}
            data-index="07"
            alt="Blog 07"
          />
        </a>
      </div>
      <div>
        <a href="#blog08">
          <img
            src={`${imageFolder}/blogs/08.jpg`}
            className={styles['blog-image']}
            data-index="08"
            alt="Blog 08"
          />
        </a>
      </div>
      <div>
        <a href="#blog09">
          <img
            src={`${imageFolder}/blogs/09.jpg`}
            className={styles['blog-image']}
            data-index="09"
            alt="Blog 09"
          />
        </a>
      </div>
      <div>
        <a href="#blog10">
          <img
            src={`${imageFolder}/blogs/10.jpg`}
            className={styles['blog-image']}
            data-index="10"
            alt="Blog 10"
          />
        </a>
      </div>
      <div>
        <a href="#blog11">
          <img
            src={`${imageFolder}/blogs/11.jpg`}
            className={styles['blog-image']}
            data-index="11"
            alt="Blog 11"
          />
        </a>
      </div>
      <div>
        <a href="#blog12">
          <img
            src={`${imageFolder}/blogs/12.jpg`}
            className={styles['blog-image']}
            data-index="12"
            alt="Blog 12"
          />
        </a>
      </div>
      <div>
        <a href="#blog13">
          <img
            src={`${imageFolder}/blogs/13.jpg`}
            className={styles['blog-image']}
            data-index="13"
            alt="Blog 13"
          />
        </a>
      </div>
    </BlogPostLayout.Slider>

    <BlogPostLayout.Subheader>
      Promotion by YouTube on YouTube Auto-generated Topic Channels
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Body>
      <Link to="https://support.google.com/youtube/answer/2579942?hl=en">YouTube Auto-generated Topic Channels</Link>
      {' '}
      are channels that are automatically created by
      YouTube&apos;s algorithms to collect videos on certain
      topics (TV series, people, events, etc.).
    </BlogPostLayout.Body>
    <BlogPostLayout.Body>
      For example, even though CNN posts videos of all of
      their anchors to a single YouTube channel, YouTube
      automatically creates separate, official-looking
      channels for each anchor&apos;s show like
      {' '}
      <Link to="https://www.youtube.com/channel/UCh1t4qMhrZNyWzN3k0tTwzQ">The Lead with Jake Tapper</Link>
      {' '}
      that aggregates videos of Jake Tapper.
    </BlogPostLayout.Body>
    <BlogPostLayout.Body>
      According to YouTube, they help &ldquo;boost your channel&apos;s
      search and discovery potential on YouTube.&rdquo; We found
      videos from this campaign promoted on some of these
      YouTube topic channels. Anyone who subscribes to these
      YouTube topic channels will be pushed content from
      this campaign, effectively increasing their audience
      and reach.
    </BlogPostLayout.Body>
    <BlogPostLayout.Slider>
      <div>
        <a href="#topic-channel01">
          <img
            src={`${imageFolder}/topic-channels/01.png`}
            className={styles['topic-channel-image']}
            data-index="01"
            alt="Topic Channel 01"
          />
        </a>
      </div>
      <div>
        <a href="#topic-channel02">
          <img
            src={`${imageFolder}/topic-channels/02.png`}
            className={styles['topic-channel-image']}
            data-index="02"
            alt="Topic Channel 02"
          />
        </a>
      </div>
      <div>
        <a href="#topic-channel03">
          <img
            src={`${imageFolder}/topic-channels/03.png`}
            className={styles['topic-channel-image']}
            data-index="03"
            alt="Topic Channel 03"
          />
        </a>
      </div>
      <div>
        <a href="#topic-channel04">
          <img
            src={`${imageFolder}/topic-channels/04.png`}
            className={styles['topic-channel-image']}
            data-index="04"
            alt="Topic Channel 04"
          />
        </a>
      </div>
      <div>
        <a href="#topic-channel05">
          <img
            src={`${imageFolder}/topic-channels/05.png`}
            className={styles['topic-channel-image']}
            data-index="05"
            alt="Topic Channel 05"
          />
        </a>
      </div>
    </BlogPostLayout.Slider>

    <BlogPostLayout.Subheader>
      Suspected Troll Commenter User Profiles
    </BlogPostLayout.Subheader>
    {/* TODO: image spinner */}

    <BlogPostLayout.Subheader>
      Credibility with YouTube Viewers
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Body>
      While this content might appear to be obviously
      edited to savvy viewers, there are still a proportion
      of users commenting on these videos that do not
      question the content&apos;s source and even believe they
      are being uploaded from sources like CNN:
    </BlogPostLayout.Body>
    <BlogPostLayout.Quote
      text="after I posted my comments I noticed the channel was not CNN and am now going to unsubscribe to the channel in question. Thanks for your eagle eye and back up. Much appreciated. It gives CNN a bad reputation. Wonder how to stop it?"
      attribution="YouTube comment by CaliforniaGirl"
    />

    <BlogPostLayout.Subheader>
      Attribution
    </BlogPostLayout.Subheader>
    <BlogPostLayout.Body>
      While the content posted by this campaign is certainly
      troubling and the outrageous tone they strike seems to
      have malicious intent, due to the anonymous nature of
      the internet it is difficult to make a definitive
      statement about the actors behind this campaign
      without access to the private metadata behind these
      accounts.
    </BlogPostLayout.Body>
    <BlogPostLayout.Body>
      It seems to be a &ldquo;professional&rdquo; operation given the
      number of hacked accounts they have access to, the
      amount of human effort involved in the campaign, the
      likely automated tools developed to help create the
      doctored images, and the frequency at which content
      is uploaded (every hour or few hours). Moreover, most
      channels don&apos;t seem to be monetized or have financial
      incentive to be driving views to their channels.
    </BlogPostLayout.Body>
    <BlogPostLayout.Body>
      Regardless of the source, the content should be taken
      down as it is disinformation, and the campaign should
      be blocked from creating further accounts.
    </BlogPostLayout.Body>

    <BlogPostLayout.Header>
      The Numbers
    </BlogPostLayout.Header>
  </BlogPostLayout>
);

export default PoliticalDisinformationCampaignOnYoutube;
