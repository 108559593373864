/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import Link from 'components/Link';

import { __privacy } from 'constants/routes';
import { ACCENTS } from 'constants/themes';
import {
  EX1_PRIVACY_EMAIL,
  EX1_DPO_EMAIL,
  EX1_PHONE,
  EX1_ADDRESS,
} from 'constants/contact';

import styles from './styles.module.scss';

const PrivacyPolicy = () => (
  <>
    <SiteHeader
      theme="white"
      accent={ACCENTS.terms}
    />
    <Section
      padding="sm"
      theme="white"
    >
      <div className={styles.policy}>
        <h1>Privacy Policy</h1>
        <div className={styles.updated}>Effective: December 17, 2020</div>

        <p>
          Thank you for choosing to be a part of the Executive 1 Holding Company, LLC family of companies, including EX1, HumanTouch, LLC, CORASCloud, Inc., Plasticity Inc., and any newly acquired companies (collectively, &ldquo;<span className={styles.term}>EX1</span>,&rdquo; &ldquo;<span className={styles.term}>we</span>,&rdquo; &ldquo;<span className={styles.term}>us</span>,&rdquo; or &ldquo;<span className={styles.term}>our</span>&rdquo;).  We are committed to protecting your personal information (&ldquo;<span className={styles.term}>Personal Data</span>&rdquo;) and your right to privacy.  If you have any questions about this Privacy Policy (this &ldquo;<span className={styles.term}>Policy</span>&rdquo;), or our practices with regards to your Personal Data, please contact us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link>.
        </p>

        <p>
          When you visit our various websites (each a &ldquo;<span className={styles.term}>Site</span>&rdquo; and collectively, the &ldquo;<span className={styles.term}>Sites</span>&rdquo;), and more generally, use any of our products (the &ldquo;<span className={styles.term}>Products</span>&rdquo;) or services (the &ldquo;<span className={styles.term}>Services</span>&rdquo;), which may include the Sites, we appreciate that you are trusting us with your Personal Data.  We take your privacy very seriously.  In this Policy, we seek to explain to you, in the clearest way possible, what information we collect, how we use it, and what rights you have in relation to it.  We hope you take the time to read through it carefully.  If there are any terms of this Policy that you disagree with, please discontinue the use of our Products and Services immediately.
        </p>

        <p>
          This Policy applies to all information collected through our Sites, Products and Services, as well as any related services, sales, marketing or events, regardless of your access (e.g., via browser, email), or (b) whether you are a user or a visitor to our Sites.
        </p>

        <p>
          <b>
            Please read this Policy carefully as it will help you understand what we do with the information we collect.
          </b>
        </p>

        <p>
          This Policy does not apply to information collected by any third party, including through any third-party application or content that links to, or is accessible from, our Sites.  This Policy applies with respect to your use of any EX1 Site, Product or Service, regardless of (a) your method of access, or (b) whether you are a user or a visitor.
        </p>

        <p>
          Presently, CORASCloud, Inc. (&ldquo;<span className={styles.term}>CORAS</span>&rdquo;) and Plasticity Inc. (&ldquo;<span className={styles.term}>Plasticity</span>&rdquo;) are the only entities in the EX1 family of companies that have or may have customers in the European Economic Area (&ldquo;<span className={styles.term}>EEA</span>&rdquo;) and Switzerland.  Consequently, at this time, the sections of this Policy related to processing of Personal Data for EEA and Switzerland residents in accordance with the General Data Protection Regulation (<span className={styles.term}>GDPR</span>) apply to CORAS/Plasticity only.  If/when our other affiliated entities gain EEA customers, those entities will process information in accordance with this Policy.
        </p>

        <p>
          We may revise this Policy at any time by updating this posting. Use of the Sites, Products or Services after such revisions are posted will signify your agreement with this Policy.  You should visit this page periodically to review this Policy and any revisions.
        </p>

        <p>
          <b>
            <u>
              Table of Contents:
            </u>
          </b>
        </p>

        <ol className={styles.toc}>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#information`}
            >
              What information do we collect?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#obtain`}
            >
              How do we obtain personal data?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#use`}
            >
              How do we use your personal data?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#shared`}
            >
              Will your information be shared with anyone else?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#credit-card`}
            >
              How do we handle protection of your credit card information?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#duration`}
            >
              How long do we keep your personal data?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#international-transfers`}
            >
              International transfers
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#opt-out`}
            >
              Can I opt-out of direct marketing?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#cookies`}
            >
              Do we use cookies and other tracking technologies?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#third-party`}
            >
              What is our stance on third party websites?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#accuracy`}
            >
              Personal data accuracy, privacy and security
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#children`}
            >
              Children&apos;s privacy protection
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#ca-rights`}
            >
              Do California residents have specific privacy rights?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#ca-categories`}
            >
              What categories of information do we collect from CA residents?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#ca-enforce`}
            >
              Do California residents have specific rights they can enforce in relation to personal data?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#eea-rights`}
            >
              Do EEA and Switzerland residents have specific privacy rights?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#verification`}
            >
              Do we have a verification process regarding enforcement of specific rights in California, the EEA, and Switzerland?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#udpates`}
            >
              Do we make updates to this policy?
            </Link>
          </li>
          <li>
            <Link
              decoration={false}
              to={`${__privacy.path}#contact`}
            >
              How can you contact us about this policy?
            </Link>
          </li>
        </ol>

        <ol className={styles.headings}>
          <li>
            <h2 id="information">
              What information do we collect?
            </h2>
          </li>
        </ol>

        <p>
          We collect Personal Data that you voluntarily provide to us when you use our Sites, register for our Products and/or Services, express an interest in obtaining information about our Products or Services, when you participate in activities regarding our Products or Services, or when you otherwise contact us.
        </p>

        <p>
          The Personal Data that we collect depends on the context of your interactions with us and the Sites, Products and Services, the choices you make, and the features that you use.
        </p>

        <p>
          We do not sell, share, or rent any content, data, or other such materials you may otherwise actively provide to us through use of our Sites, Products and Services, we promise.
        </p>

        <p>
          When you visit our Sites, you are not required to actively provide any information, but the analytics software we use may collect some Personal Data.  When you contact us via the chat function, sign up to download or receive information, or sign up to receive and use Products and Services, you are required to provide some Personal Data.
        </p>

        <p>
          <b><span className={styles.header}>Personal Data Collected <u>Directly</u>.</span></b>
          We may collect&mdash;directly from you&mdash;Personal Data such as:
        </p>

        <table>
          <tbody>
            <tr>
              <th>
                Identifiers
              </th>
              <td>
                <ul>
                  <li>Contact details, e.g., name, company name, job title, address, telephone number, fax number, e-mail address, country of residence, etc.</li>
                  <li>Signatures.</li>
                  <li>Contact preferences and interests.</li>
                  <li>Accommodations and other event-related preferences.</li>
                  <li>Any information or content you have permitted a social media network to share with us.</li>
                  <li>Postings on blogs, forums, wikis and other social media applications/services.</li>
                  <li>Photographs/videos submitted by you or taken at one of our events. </li>
                  <li>Reviews about our Products or Services.</li>
                  <li>Details you share when contacting us via email.</li>
                  <li>Shared information concerning another individual. If you provide us with Personal Data of another person (e.g., a potential employee/referral), you are responsible for ensuring that such person is made aware of the information contained in this Policy and represent that the person has given you his/her documented authority or consent for sharing the Personal Data with us and permitting us to use the Personal Data in accordance with this Policy.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>
                Professional or Employment-Related Information
              </th>
              <td>
                <ul>
                  <li>Current or past job history or performance evaluations.</li>
                  <li>Education records maintained by an educational institution or party acting on its behalf, such as grades, transcripts, student disciplinary records, or similar information.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>
                Sensitive, Special Treatment or Protected Characteristics
              </th>
              <td>
                <p>
                  Profiles reflecting a person&apos;s preferences, characteristics, trends, predispositions, behavior, etc.
                </p>
                <p>
                  We do not typically seek to collect the below sensitive Personal Data through our Sites or from users.  We will obtain your explicit consent before we collect, use or otherwise process the below sensitive Personal Data in accordance with applicable data protection, GDPR and ePrivacy regulatory requirements.
                </p>
                <ul>
                  <li>Generally, race, ethnic origin, and actual or suspected criminal convictions, offenses or activities.</li>
                  <li>For California customers, age, race, color, ancestry, national origin, citizenship, marital status, veteran or military status.  For customers in the EEA, race or ethnicity.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <b><span className={styles.header}>Personal Data Collected <u>Automatically</u>.</span></b>
          We automatically collect certain information when you visit, use or navigate the Products or Services.  This information does not reveal your specific identity (e.g., name or contact information) and is primarily needed to maintain the security and operation of our Sites, Products and Services, and for our internal analytics and reporting purposes.
        </p>

        <table>
          <tbody>
            <tr>
              <th>
                Log and Usage Data
              </th>
              <td>
                <p>
                  Log and usage data is service-related, diagnostic, usage and performance information that our servers automatically collect when you access our Products and Services, and which we record in our log files.  Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings, settings and information about your activity on our Sites,  (e.g., date/time stamps associated with your usage, pages and files viewed, searches and features you use/resources you access or download, referring/exit URLs, clickstream data, and device event information (e.g., system activity, error reports, and hardware settings.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Device Data
              </th>
              <td>
                <p>
                  We collect device data such as information about your computer, phone, tablet or other device you use to access the Products and Services.  Depending on the device used, this data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system and system configuration information.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Location Data
              </th>
              <td>
                <p>
                  We collect location data such as information about your device&apos;s location, which can be either precise or imprecise.  How much information we collect depends on the type and settings of the device you use to access the Products and Services.  E.g., we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address).  You can opt out of allowing us to collect this information either by refusing access to this information or by disabling the Location setting on your device.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Cookies/Beacons
              </th>
              <td>
                <p>
                  Cookies (small text files stored in a user&apos;s browser, referred to as &ldquo;<span className={styles.term}>Cookies</span>&rdquo;), beacons (electronic images that allow us to count users who have accessed particular content and to access certain Cookies, referred to as &ldquo;<span className={styles.term}>Beacons</span>&rdquo;), and tags through the Sites and through Cookies and other tracking technology to provide you with a personalized online experience.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Marketing Information
              </th>
              <td>
                <p>
                  To enhance our ability to provide relevant marketing, offers and services to you and to update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, and other third parties.  This information includes mailing addresses, phone numbers, intent data (or user behavior), IP addresses, social media profiles, social media URLs and custom profiles, for purposes of targeted advertising and event promotion.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Except for certain information that is required by law, your decision to provide Personal Data to us is voluntary. Therefore, you may decline to provide us with your Personal Data.  Note, however, that if you opt out, you may not be able to use certain aspects of our Products and Services.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="obtain">
              How do we obtain personal data?
            </h2>
          </li>
        </ol>

        <p>
          Personal Data may be collected from you either directly or indirectly when you: (a) order our Products using a Site, (b) use our Products from a Site, (c) access our Services, (c) participate in surveys or submit reviews, (d) submit our online forms or communicate with us on email, (e) upload or post any comments/content to our Sites, on social media, or blogs, (f) interact with us on social media, or (g) sign up for our mailing lists, register for events we host or sponsor, submit information as part of certain online services, or otherwise provide us information through the Sites.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="use">
              How do we use your personal data?
            </h2>
          </li>
        </ol>

        <p>
          We may use the Personal Data we collect and receive where one (1) or more of the following justifications exist:
        </p>

        <table>
          <tbody>
            <tr>
              <th>
                Consent
              </th>
              <td>
                <p>
                  To obtain your documented consent to our processing of your Personal Data.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Managing Our Relationship With You.
              </th>
              <td>
                <p>
                  To fulfill your request for Product orders, support or Services under an existing or potential contract with you; to facilitate your conducting business with us; or to perform a transaction with you.  Where a transaction involves our suppliers or strategic alliances, this may include sharing information with other parts of EX1, EX1&apos;s business partners or alliances, clients, financial institutions and postal or government authorities involved in fulfillment (subject to confidentiality obligations that may exist).
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Requests
              </th>
              <td>
                <p>
                  To provide information or Services requested by you.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Business Management/ Operations
              </th>
              <td>
                <p>
                  To ensure the proper functioning of our business operations and administration of our general business, accounting, record-keeping and legal functions.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Monitoring Your Use of Our Systems
              </th>
              <td>
                <p>
                  To monitor user activities on our systems to ensure users are complying with applicable laws and regulations and aren&apos;t performing activities that would negatively affect our reputation.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Social Media Environment
              </th>
              <td>
                <p>
                  To enable online sharing and collaboration among members who have registered to use them; to protect our assets and the assets of our clients; to protect our brand on social media; to understand sentiment, intent, mood and market trends and our stakeholders&apos; needs to improve our Products and Services through key-word searches, conversation stream monitoring and analysis; and to gain insights in conversation trends over a specified period, but not to identifying an individual.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Protecting or Improving the Security and Functioning of Our Sites, Networks and Information
              </th>
              <td>
                <p>
                  To ensure that you receive an excellent user experience and/or to maintain the safety, security, and integrity of our Sites (e.g., fraud monitoring and prevention), Products, Services, information, tools, systems, databases and other technology assets and business.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Audit the Downloading of Information From Our Sites
              </th>
              <td>
                <p>
                  To get to know our Site visitors&apos; preferences and improve services accordingly.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Analytics and Improving Our Sites
              </th>
              <td>
                <p>
                  To better understand how you access and use our Sites, Products and Services, and for analytical purposes to evaluate our Products and Services; to ensure the proper functioning our business operations; to improve our Products, Services, and business operations; to develop services and features; and to provide a better user experience.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Anonymous Information
              </th>
              <td>
                <p>
                  To assess, improve and develop our business, Products and Services, and for research and analytics purposes.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Historical, Statistical or Research and Development
              </th>
              <td>
                <p>
                  To analyze Personal Data to better understand historical or statistical trends subject to appropriate data protection safeguards.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Online Account Registration or Administration
              </th>
              <td>
                <p>
                  To administer online accounts as part of a contract; to enable account features; to customize your view of our Sites or to personalize information you receive from us; to register you for a service or program; and to send you Product, Service and new feature information or information about changes to our terms, conditions and policies.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Targeted Advertising
              </th>
              <td>
                <p>
                  To develop and display personalized content and advertising (and work with 3rd parties to do so) tailored to your interests and/or location and to measure its effectiveness.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Marketing Communications
              </th>
              <td>
                <p>
                  To keep you informed about our Products, Services, updates, offers, events, programs, products, tools, and solutions by post, email, SMS, phone and fax; to develop aggregate analysis and business intelligence and, where required by law, we will ask for your consent at the time we collect your data to conduct any of these types of marketing. We will provide an option to unsubscribe or opt-out of further communication on any electronic marketing communication sent to you or you may opt out by contacting us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link>.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Event, Conference or Similar Communications
              </th>
              <td>
                <p>
                  To facilitate your participation in a private or public forum, event or conference.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Contest or Prize Administration
              </th>
              <td>
                <p>
                  To fulfill your participation in prize promotions, contests and other promotional offers that we may administrate.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Content Creation/Production Activities
              </th>
              <td>
                <p>
                  To use your Personal Data for video, TV, film, marketing, advertising or other related content creation, production and distribution activities where you are involved based on prior consent and releases.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Recruitment
              </th>
              <td>
                <p>
                  To ensure that we recruit appropriate employees, send relevant information about careers and opportunities, and to analyze the effectiveness of our recruitment efforts and resources in connection with a job application or inquiry.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Managing Our Employment Relationship With You
              </th>
              <td>
                <p>
                  To assess performance or to terminate an employment contract to which our employees are a party.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Protection of Vital Interests
              </th>
              <td>
                <p>
                  To prevent or act regarding Policy violation, suspected fraud, situations involving potential threats to the safety of any person, and illegal activities.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Complying with Legal Obligations
              </th>
              <td>
                <p>
                  To comply or fulfill our legal proceedings, employment, labor, tax or similar legal requirements.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Law Enforcement Requests and Harm Prevention
              </th>
              <td>
                <p>
                  To comply with a law, regulation, legal process, court order or by any rule of law or governmental request; to protect the safety of any person; to protect the rights of those who use our Sites, Products and Services; to prevent or detect crime; to apprehend or prosecute offenders.  Nothing in this Policy is intended to limit any legal defenses or objections that you may have to a third party, including a governmental request, to disclose your Personal Data.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Protection of Legal Rights and Prevention of Misuse
              </th>
              <td>
                <p>
                  To protect our Sites and business operations; to prevent and detect fraud, unauthorized activities and access, and other misuse; to establish, exercise or defend legal claims; where we believe necessary to investigate, to prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety or legal rights of any person or third party; or to violations of this Policy.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Affiliates and Change of Ownership
              </th>
              <td>
                <p>
                  To facilitate merger, acquisition, reorganization, sale of assets or similar function.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Testimonials
              </th>
              <td>
                <p>
                  To post testimonials that may contain Personal Data.  Prior to posting any testimonial, we will obtain your consent to use your name and content of the testimonial.  Should you wish to update or delete a testimonial, please contact us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link>.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                User-to-User Communications
              </th>
              <td>
                <p>
                  To enable user-to-user communications with each user&apos;s consent.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                Requesting Feedback
              </th>
              <td>
                <p>
                  To obtain feedback and to contact you about the use of our Products and Services.
                </p>
              </td>
            </tr>
            <tr>
              <th>
                User Inquiries
              </th>
              <td>
                <p>
                  To respond to your inquiries and solve any potential issues you might have with the use of our Products or Services.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          We will process your Personal Data for the above referenced purposes based on your prior consent, to the extent such consent is mandatory under applicable laws.
        </p>

        <p>
          If you are asked to click on/check &ldquo;I accept,&rdquo; &ldquo;I agree&rdquo; or similar buttons/checkboxes/functionalities, we will consider this step as providing your consent for us to process your Personal Data, only in the countries where such consent is required by regulations. In all other countries, such action will be considered as a mere acknowledgement.
        </p>

        <p>
          We will not collect Personal Data for materially different, unrelated, or incompatible purposes without providing you with notice or, as applicable, obtaining your consent, unless it is in your own or another person&apos;s vital interest (e.g. medical emergency) to do so.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="shared">
              Will your information be shared with anyone else?
            </h2>
          </li>
        </ol>

        <ol className={styles.enumeration}>
          <li>
            <span className={styles.header}><u>Generally</u>.</span><i>Personal Data collected in the course of our activities and on our Sites may be shared with:</i>
            <ol className={styles['sub-enumeration']}>
              <li>Our subsidiaries or affiliates, clients and strategic alliances on a need-to-know and authorized basis.</li>
              <li>Third parties, such as vendors, suppliers, and partners.  We may share some of your Personal Data with these third parties so they can provide their services, but we don&apos;t allow them to share or use any of your Personal Data for any other purpose.</li>
            </ol>
            <p>
              To ensure that your Personal Data is processed, used, stored, and transferred securely and, in connection with EU law, we use third parties that provide sufficient guarantees to implement appropriate technical and organizational measures to protect your Personal Data.
            </p>
            <p>
              Please note, the terms and privacy policies of the third parties we use may apply to you as well, in particular: Google Analytics, Webflow, SEMrush, Square and Stripe (in the case of Plasticity only). Your acceptance of applicable third party terms is required to access our Sites, Products and Services.  We strongly recommend that you read their privacy policies and terms and conditions of use to understand how they collect, use, and share information.  Their standards may differ from ours.  We do not endorse such third parties unless expressly stated otherwise.
            </p>
            <p>
              <u>DISCLAIMER</u>: This overview of third parties is a snapshot. We strive to keep this list as up-to-date as reasonably possible.  However, it is possible that not all current third party providers are listed.
            </p>
            {/*
              NOTE: this list will continue from the previous ordered list
              because a .continue className has been specified, which prevents
              reseting the counter.
            */}
            <ol className={[styles['sub-enumeration'], styles.continue].join(' ')}>
              <li>Prospective/actual purchasers of, or sellers to, EX1 on a need-to-know basis in the event of a sale, merger, joint venture, reorganization, assignment or other transfer or disposition of all or any portion of our business.</li>
              <li>With government agencies pursuant to a judicial proceeding, court order, or legal process.</li>
            </ol>
            <p>
              We will not intentionally disclose (and will take reasonable steps to prevent the unauthorized or accidental disclosure of) your Personal Data to any third parties for their own direct marketing use.  We may use your Personal Data for our business purposes, e.g. internal research, technical development and demonstration.  This is not considered &ldquo;selling&rdquo; of your Personal Data.  EX1 has never disclosed or sold any Personal Data to third parties for a business or commercial purpose, and EX1 will not sell Personal Data in the future belonging to Site visitors, users and other consumers.
            </p>
          </li>

          <li>
            <span className={styles.header}><u>For CORAS only.</u></span>
            <ol className={styles['sub-enumeration']}>
              <li><span className={styles.header}><b>Account Holders.</b></span> Depending on the configuration and features selected by a CORAS user, CORAS may share your information with the account holder or other users associated with your account.  For example, an employer or task leader may have access to certain information about your use of the CORAS Products.</li>
              <li><span className={styles.header}><b>Other Users with Whom You Interact While Using CORAS.</b></span> CORAS is designed for users to post and share content, collaborate, and communicate with one another. Your information will be shared with other users you chose to interact with while using the CORAS Products.  If you are accessing CORAS&apos; Products because you were invited by a CORAS user, please remember that your information is subject to this Policy and to the user&apos;s own privacy policies and practices.  Please review the privacy policy of the CORAS user(s) with whom you interact on the CORAS Products and Services before providing any Personal Data.</li>
              <li><span className={styles.header}><b>Adding Additional Users.</b></span> When you add additional users to your account, we send them an invitation to join our site and request such users to provide us with certain additional contact information including such users&apos; email addresses. When you use such a service, you represent and warrant to us that you have an existing business or personal relationship with such additional user sufficient to avoid liability under any law that applies to unsolicited email. You will be deemed to be the sender of any such email (or other electronic communication) and we will be deemed to merely be a service provider facilitating your sending of the email (or other electronic communication).</li>
            </ol>
          </li>
        </ol>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="credit-card">
              How do we handle protection of your credit card information?
            </h2>
          </li>
        </ol>

        <p>
          We do not store any credit card information; we have partnered with Square Capital, LLC (&ldquo;<span className={styles.term}>Square</span>&rdquo;) and Stripe (in the case of Plasticity only) to collect payments, with each being a &ldquo;<span className={styles.term}>Payment Provider</span>&rdquo;).  Payment information is stored on each Payment Providers&apos; secure site and not available to us, other than name, email and referential transaction information. If we have issues processing an order, this information will be used to contact you.  Your credit card information collected via each Payment Provider&apos;s secure online payment system is governed by each Payment Provider&apos;s Privacy Notice.
        </p>

        <p>
          Please see Square&apos;s Privacy Notice for more information, available <Link to="https://squareup.com/us/en/legal/general/privacy">here</Link>, and for CA residents, please see Square&apos;s additional privacy disclosure <Link to="https://squareup.com/us/en/legal/general/california-privacy">here</Link>.  Square&apos;s Commercial Entity Agreement (or bank agreement) can be found <Link to="https://squareup.com/us/en/legal/general/cea">here</Link>.
        </p>

        <p>
          Please see Stripe&apos;s Global Privacy Policy for more information, available <Link to="https://stripe.com/privacy">here</Link>, and Stripe&apos;s EU-U.S. and Swiss-U.S. Privacy Shield Policy available <Link to="https://stripe.com/privacy-shield-policy">here</Link>.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="duration">
              How long do we keep your personal data?
            </h2>
          </li>
        </ol>
        <p>
          We retain Personal Data for as long as we reasonably consider it to be necessary for the purposes for which it was collected (unless a longer retention period is required by law), after which time we will securely delete or anonymize the information.  You can also request deletion of your Personal Data by contacting <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link>.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="international-transfers">
              International transfers
            </h2>
          </li>
        </ol>

        <p>
          Some of our disclosures may involve the transfer of Personal Data to countries or regions where the local law may grant you fewer rights than you have in your own country.  We have designed this Policy to provide a globally consistent level of protection for Personal Data all over the world.  This means that before we transfer Personal Data to those areas, we will take the necessary steps to ensure that your Personal Data will be given adequate protection as required by applicable data protection.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="opt-out">
              Can I opt-out of direct marketing?
            </h2>
          </li>
        </ol>

        <p>
          <u>Yes, you can opt out of direct marketing or promotional emails.</u>
        </p>

        <p>
          We may send periodic promotional emails to you, and where required by law, we will obtain your consent to do so. You may opt out of such communications at any time by following the opt-out instructions contained in any email, by emailing <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> or by postal mail using the contact information provided at the end of this Policy.   <u>For CORAS and Plasticity</u>, users can withdraw consent by adjusting their account profile settings in the Products.
        </p>

        <p>
          If you opt out of receiving emails containing information that we think may interest you, we may still send you emails about your account or any Products or Services you have requested or received from us.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="cookies">
              Do we use cookies and other tracking technologies?
            </h2>
          </li>
        </ol>

        <p>
          <i>Yes, we may use Cookies, Beacons and other technologies on our Sites in order to collect Personal Data, to remember your settings and for authentication purposes.</i>
        </p>

        <ol className={styles.enumeration}>
          <li>
            <span className={styles.header}><b>Cookies</b>.</span>  We may use Cookies to better display our Sites, to save you time, to provide better technical support, and to anonymously track Site, Product and Service usage.  For example, Cookies help us to: (a) keep track of whether you are signed in or have previously signed in; (b) remember your settings on the pages you visit; (c) display personalized content and targeted advertisements on our Sites as well as on other websites and services; (d) perform analytics, and measure traffic and usage trends, and better understand the demographics of our users; (e) diagnose and fix technology problems; and (f) otherwise enhance, our Sites.
          </li>
          <p>
            Most or all browsers permit you to disable or reject certain Cookies (which will prevent certain tracking).  However, if you set the browser you use to reject Cookies or otherwise disable them, you may not be able to use any or all the functionality at one or more of the Sites or Products or it may take additional time to utilize such functionality.  If you wish to use any such functionality that requires the use of Cookies at any of our Sites, then you must accept the use of Cookies for that Site, and thereby, give us your permission to link your data.
          </p>
          <li>
            <span className={styles.header}><b>Clear GIFs, Pixel Tags and Other Technologies</b>.</span>  We may use Beacons, web bugs or pixel tags in connection with our Products, Services and Sites to, among other things, track the activities of users of our Products and Services, help us manage content, and compile statistics about usage of our Products and Services. We and our third party service providers may also use clear GIFs in HTML emails to our customers, to help us track email response rates, to identify when our emails are viewed, and to track whether our emails are forwarded.
          </li>
          <li>
            <span className={styles.header}><b>Log Files</b>.</span>  Most browsers collect certain information, such as your IP address, device type, screen resolution, operating system version, and Internet browser type and version. This information is gathered automatically and stored in log files.
          </li>
          <li>
            <span className={styles.header}><b>Third Party Analytics</b>.</span>  We may use automated devices and applications to evaluate the use of our Sites, Products and Services.  We may use these tools to gather non-Personal Data about users to help us improve our services and user experiences. These analytics providers may use Cookies and other technologies to perform their services and may combine the information they collect about you on our Sites with other information they have collected for their own purposes. This Policy does not cover such uses of data by third parties.
          </li>
          <li>
            <span className={styles.header}><b>Do-not-track signals</b>.</span>  You may disable any tracking on our Sites (e.g., by disabling Cookies).
          </li>
        </ol>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="third-party">
              What is our stance on third party websites?
            </h2>
          </li>
        </ol>

        <p>
          <i>
            Our Sites, Products and Services may contain links to other websites run by third parties for your convenience and informational purposes only.
          </i>
        </p>

        <p>
          Our provision of third party links do not amount to our endorsement of these other websites, nor their content, owners, or practices.  We do not control or assume responsibility for the content, accuracy or practices of these other websites or third parties.  The inclusion of third party links does not suggest that the opinions expressed on the third-party site are representative of the views or opinions of EX1.  This Policy does not apply to those other websites, which are governed by their own terms and policies.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="accuracy">
              Personal data accuracy, privacy and security
            </h2>
          </li>
        </ol>

        <p>
          We intend to maintain Personal Data accuracy, completeness, current status, and security.  In the event of changes in your Personal Data, you may inform us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> to make sure that our information is up-to-date.  CORAS/Plasticity users may view and access their profile and make changes to the Personal Data stored on the CORAS/Plasticity Sites, Products and Services.  <u>Please note</u> that CORAS/Plasticity users <i>cannot</i> opt out of receiving Product and transactional emails related to their CORAS/Plasticity account.
        </p>

        <p>
          We will not accommodate a request to change your Personal Data if we believe the change would violate any law or legal requirement or cause the information to be incorrect.  In such instances, we will inform the user about the legal obligations that prevent us from fulfilling the request.
        </p>

        <p>
          We will maintain an audit history of any requests to access, correct or delete Personal Data to maintain a record of compliance with regulatory requirements.
        </p>

        <p>
          We implement commercially reasonable physical, administrative and technical safeguards to help us protect the confidentiality, security, and integrity of your Personal Data and prevent the loss, misuse, unauthorized access, unauthorized interception, or information alteration. For example, EX1 takes appropriate measures to make sure that the Personal Data you provide is stored on computer servers in controlled, secure environments.
        </p>

        <p>
          All of our partners, employees, consultants, workers and data processors (i.e., those who process your Personal Data on our behalf, for the purposes listed above), who have access to, and are associated with the processing of Personal Data, are obliged to respect the confidentiality of such Personal Data.
        </p>

        <p>
          We have implemented strong technical and organizational security measures designed to protect the security of Personal Data that we process, e.g., use of firewalls with intrusion detection, 24/7 monitoring, physical security through our hosting providers, encryption via SSL when using our SSL-based services, payment processing providers, and processing and authentication standards for password and username.
        </p>

        <p>
          Your choice to disclose Personal Data in an email submission or online form is voluntary.  Despite our safeguards and efforts to secure your Personal Data, no data transmission over the Internet is 100% secure.  We cannot promise or guarantee that hackers, cybercriminals, or other unauthorized 3rd parties will not be able to defeat our security and improperly collect, access, steal or modify your information.  Although we do our best to protect your Personal Data, transmission of Personal Data to and from our Sites, Products and Services is at your own risk.  You should only access our Sites, Products and Services from a secure environment.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="children">
              Children&apos;s privacy protection
            </h2>
          </li>
        </ol>

        <p>
          EX1 is committed to protecting children&apos;s privacy online.  Our Sites are not intentionally designed for or directed at children under the age of 13 in the U.S and 16 in California or in the EEA. We will not knowingly or intentionally collect, store, use, or share, Personal Data of children under the age of 13 in the U.S and 16 in California or in the EEA without prior documented parental or guardian consent.
        </p>

        <p>
          If you are under the age of 13 in the U.S and 16 in California or EEA, please do not provide any Personal Data, even if prompted by the Sites to do so. If you are under the age of 13 in the U.S and 16 in California or EEA and you believe you have provided Personal Data to us, please ask your parent(s) or guardian(s) to notify us and we will delete all such Personal Data.
        </p>

        <p>
          If we become aware that we have inadvertently received Personal Data from a user under the age of 13 in the U.S, 16 in California or in the EEA, we will delete that data from our records.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="ca-rights">
              Do California residents have specific privacy rights?
            </h2>
          </li>
        </ol>

        <p>
          <i>
            Yes, if you are a CA resident, you are granted specific rights regarding access to your Personal Data.
          </i>
        </p>

        <p>
          CA Civil Code Section 1798.83, also &ldquo;Shine The Light&rdquo; law, permits our users who are CA residents to request and obtain from us, once a year, free of charge, information about categories of Personal Data (if any) that we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared Personal Data in the immediately preceding calendar year.  If you are a CA resident and would like to make such a request, please submit your request to us in writing at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link>.
        </p>

        <p>
          If you are under 18, reside in CA, and have a registered account with a Product or Service, you have the right to request removal of unwanted data that you publicly post on the Products or Services.  To request removal of such data, please contact us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> and include a statement that you reside in CA.  We will make sure that the data is not publicly displayed on the Products and Services, but please be aware that the data may not be completely or comprehensively removed from all of our systems (backups, etc.).
        </p>

        <p>
          A &ldquo;<span className={styles.term}>resident</span>&rdquo; of CA is defined under the CA Code of Regulations as someone who is in the State of CA for other than temporary or transitionary purposes, or someone who is domiciled in CA but is outside CA for temporary or transitionary purposes.  All other individuals are defined as &ldquo;<span className={styles.term}>non-residents</span>.&rdquo;
        </p>

        <p>
          If the definition of &ldquo;resident&rdquo; applies to you, then we must adhere to certain rights and obligations regarding your Personal Data.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="ca-categories">
              What categories of information do we collect from CA residents?
            </h2>
          </li>
        </ol>

        <p>
          <i>
            In the preceding twelve (12) months, we have collected the following categories of Personal Data:
          </i>
        </p>

        <table>
          <tbody>
            <tr>
              <th>Categories</th>
              <th>Collected</th>
            </tr>
            <tr>
              <td><span className={styles.header}>A.</span>  Identifiers (includes contact details such as name, alias, address, telephone or mobile number, unique personal identifier, online identifier, IP address, email address and account name).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>B.</span>  Personal Data Categories as Listed in the CA Customer Records Statute (includes name, contact information, education, employment status, employment history and financial information).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>C.</span>  Protected Classification Characteristics under CA or Federal Law (includes gender and date of birth).</td>
              <td>NO</td>
            </tr>
            <tr>
              <td><span className={styles.header}>D.</span>  Commercial Information (includes transaction information, purchase history, financial details and payment information).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>E.</span>  Biometric Information (fingerprints and voiceprints).</td>
              <td>NO</td>
            </tr>
            <tr>
              <td><span className={styles.header}>F.</span>  Internet or Other Similar Network Activity (includes browsing history, online behavior, and interactions with our and other websites, systems, and advertisements).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>G.</span>  Geolocation Data (device location).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>H.</span>  Audio, Electronic, Visual, Thermal, Olfactory, or Similar Information (includes images and audio, video or call recordings created in connection with our business activities).</td>
              <td>NO</td>
            </tr>
            <tr>
              <td><span className={styles.header}>I.</span>  Employment Information (business contact details in order to provide you your Products or Services at a business level, job title, work history and professional qualifications if you apply for a job with us).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>J.</span>  Education Information (student records and directory information).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td><span className={styles.header}>K.</span>  Inferences Drawn From Other Personal Data (e.g., using Cookies to create a profile or summary about an individual&apos;s preferences and characteristics).</td>
              <td>YES</td>
            </tr>
          </tbody>
        </table>

        <p>
          We may also collect Personal Data outside these categories where you interact with us in-person, online or by phone or mail in the context of:  (1) receiving help through our customer support channels, (2) participation in surveys or contests, or (3) delivery of our Products and Services and to respond to your inquiries.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="ca-enforce">
              Do California residents have specific rights they can enforce in relation to personal data?
            </h2>
          </li>
        </ol>

        <p>
          <i>
            Yes, CA residents also have the below additional specific rights regarding their Personal Data under the CA Consumer Privacy Act (&ldquo;<span className={styles.term}>CCPA</span>&rdquo;):
          </i>
        </p>

        <table>
          <tbody>
            <tr>
              <th>Categories</th>
              <th>Rights</th>
            </tr>
            <tr>
              <td>
                <b>
                  Right to be Informed:
                </b>
              </td>
              <td>
                <p>
                  Depending on the circumstances, you have a right to know:
                </p>
                <ol className={styles.enumeration}>
                  <li>Whether we collect and use your Personal Data;</li>
                  <li>The categories of Personal Data that we collect;</li>
                  <li>Purpose for which the collected Personal Data is used;</li>
                  <li>Whether we sell your Personal Data to third parties;</li>
                  <li>Categories of third parties to whom the Personal Data was sold or disclosed for a business purpose; and</li>
                  <li>Business or commercial purpose for selling Personal Data.</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  Request to Delete:
                </b>
              </td>
              <td>
                <p>
                  You have the right to request that we delete any of your Personal Data.   If you ask us to delete your Personal Data, we will respect your request and delete your Personal Data, subject to certain exceptions, e.g., our legal obligation to protect against illegal activities.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  Right to Non-Discrimination for the Exercise of Privacy Rights:
                </b>
              </td>
              <td>
                <p>
                  We will not discriminate against you if you exercise your privacy rights under the CCPA.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Please note that some of the above rights may be limited where we have an overriding legitimate interest or legal obligation to continue to process the Personal Data, or where the Personal Data may be exempt from disclosure due to applicable law.
        </p>

        <p>
          To exercise any of the above rights, please contact us a <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> and include a statement that you are a CA resident.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="eea-rights">
              Do EEA and Switzerland residents have specific privacy rights?
            </h2>
          </li>
        </ol>

        <p>
          <i>
            Yes, residents in the EEA or Switzerland may have rights that allow you greater access to, and control over, your Personal Data.
          </i>
        </p>

        <p>
          In the EEA and Switzerland, you have certain rights under applicable data protection laws.  These may include the right to: (a) request access to and obtain a copy of your Personal Data; (b) request correction  or erasure (or &ldquo;right to be forgotten&rdquo;) of Personal Data; (c) restrict the processing of your Personal Data; (d) in certain circumstances, data portability (receipt of Personal Data we have collected about you in a structured, machine readable format); (e) processing of Personal Data for a legitimate business purpose only; and (f) notification of a security breach incident without undue delay after we become aware of the incident.  In certain circumstances, you may have the right to object to the processing of your Personal Data.  To make such a request, please email us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> and include a statement that you are an EEA or Switzerland resident.  We will consider and act upon any request in accordance with applicable data protection laws.
        </p>

        <p>
          If we are relying on your consent to process your Personal Data, you have the right to withdraw your consent at any time.  Please note, however, that this will not reflect the lawfulness of the processing before the withdrawal, nor will it affect the processing of your Personal Data in reliance on lawful processing grounds other than consent.
        </p>

        <p>
          If you are a resident of the EEA and you believe we are unlawfully processing your Personal Data, you have the right to complain to your local data protection supervisory authority.  You can find their contact details here.
        </p>

        <p>
          If you are a resident of Switzerland, the contact details for the data protection authorities are available here.
        </p>

        <p>
          If you are a resident of the EEA or Switzerland and you have questions about or would like to exercise your privacy rights, please email us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> and include a statement that you are an EEA or Switzerland resident.
        </p>

        <p>
          Please note that some of the above rights may be limited where we have an overriding legitimate interest or legal obligation to continue to process the Personal Data, or where the Personal Data may be exempt from disclosure due to applicable law
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="verification">
              Do we have a verification process regarding enforcement of specific rights in California, the EEA, and Switzerland?
            </h2>
          </li>
        </ol>

        <p>
          Yes, upon receiving a request to enforce specific rights of consumers in CA, the EEA or Switzerland, we will need to verify your identity to determine that you are the same person about whom we have the information in our system.  These verification efforts require that you provide us with information so that we can match it with information that you have previously provided to us.
        </p>

        <p>
          We will only use Personal Data provided in your request to verify your identity or authority to make the request.  To the extent possible, we will avoid requesting additional information from you for the purposes of verification.  If, however, we cannot verify your identity from the information already maintained by us, we may ask that you provide us with additional information for the purposes of verifying your identity and for security/fraud prevention purposes.  We will delete such additionally provided information as soon as we finish verifying you.
        </p>

        <p>
          We do not charge individuals located in CA, the EEA or Switzerland a fee to process or respond to verifiable Personal Data requests unless it is excessive or manifestly unfounded to warrant a &ldquo;reasonable fee&rdquo; to cover our administrative costs of complying with the request.
        </p>

        <p>
          For verified CA and EEA/Switzerland requests for Personal Data, we will respond no later than 30 days and 45 days, respectively.  If we require more time to respond, we will inform you of the reason and extension period in writing before required response time.  If you have an account with us, we will deliver our written response to the registered email associated with the account.  If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. If applicable, we will explain the reasons we cannot comply with a request.  For data portability requests, we will select a method of providing your Personal Data in a readily useable format.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="udpates">
              Do we make updates to this policy?
            </h2>
          </li>
        </ol>

        <p>
          We may make changes to this Privacy Policy from time to time, to reflect changes in our practices. We also may make changes as required to comply with changes in applicable law or regulatory requirements.  Where we materially change this Policy, we will post a notice on our Sites, and where required by applicable law, obtain your consent to the changed Policy.
        </p>

        <ol className={[styles.headings, styles.continue].join(' ')}>
          <li>
            <h2 id="contact">
              How can you contact us about this policy?
            </h2>
          </li>
        </ol>

        <p>
          If you have questions or comments about this Policy, you may email us at <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link> or reach out to our Data Protection Officer at <Link to={`mailto:${EX1_DPO_EMAIL}`}>{EX1_DPO_EMAIL}</Link>, by phone at {EX1_PHONE} or by mail to:
        </p>

        <p>
          <span style={{ display: 'block' }}>ATTN: Legal</span>
          {EX1_ADDRESS.split('\n').map((addressLine, index) => (
            <span
              key={index}
              style={{ display: 'block' }}
            >
              {addressLine}
            </span>
          ))}
          <span style={{ display: 'block' }}>E-mail: <Link to={`mailto:${EX1_PRIVACY_EMAIL}`}>{EX1_PRIVACY_EMAIL}</Link></span>
        </p>

        <p>
          Please note that no permission is granted for you to use EX1&apos;s logos, icons, or content. You must obtain our prior written permission to post additional graphic or textual material along with your link to our Sites.
        </p>
      </div>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default PrivacyPolicy;
