import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAsyncCallback } from 'react-async-hook';
import {
  Button,
  Select,
  Textarea,
} from 'evergreen-ui';
import {
  get,
  findKey,
} from 'lodash';

import Section from 'components/Section';
import Copy from 'components/Copy';
import Form from 'components/Form';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import SapienPartsOfSpeechVisualizer from 'components/SapienPartsOfSpeechVisualizer';
import SapienLemmaVisualizer from 'components/SapienLemmaVisualizer';
import SapienDependencyParseVisualizer from 'components/SapienDependencyParseVisualizer';

import { makeApiRequest } from 'utils/api';
import { aaSapienDemoCore } from 'constants/routes';
import { CUSTOM, OPTIONS } from 'pages/demo/sapien/constants';
import { ACCENTS } from 'constants/themes';

import demoStyles from 'pages/demo/styles.module.scss';
import styles from './styles.module.scss';

const fetchResult = async (text) => makeApiRequest(aaSapienDemoCore.path, {
  text,
  ner: true,
});

const DemoSapienParsing = () => {
  const [textQueryParam, setTextQueryParam] = useQueryParam('text', StringParam);
  const [typeQueryParam, setTypeQueryParam] = useQueryParam('type', StringParam);
  const [dropdownValue, setDropdownValue] = useState(Object.keys(OPTIONS)[0]);
  const [textareaValue, setTextareaValue] = useState(Object.values(OPTIONS)[0]);
  const asyncFetchResult = useAsyncCallback(fetchResult);

  /**
   * Run on initial load to determine if there is supplied
   * text in the query params. If so, set the text and
   * the dropdown value and run the query.
   */
  useEffect(() => {
    if (textQueryParam) {
      const key = findKey(OPTIONS, (o) => (o === textQueryParam)) || CUSTOM;
      setDropdownValue(key);
      setTextareaValue(textQueryParam);
      asyncFetchResult.execute(textQueryParam);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectChange = (event) => {
    asyncFetchResult.reset();
    setTextQueryParam(undefined);
    setDropdownValue(event.target.value);
    setTextareaValue(OPTIONS[event.target.value]);
  };

  const onTextareaChange = (event) => {
    asyncFetchResult.reset();
    setTextQueryParam(undefined);
    setDropdownValue(CUSTOM);
    setTextareaValue(event.target.value);
  };

  const onSubmit = () => {
    asyncFetchResult.execute(textareaValue);
    setTextQueryParam(textareaValue);
  };

  const onTabChange = (tab) => {
    setTypeQueryParam(tab);
  };

  return (
    <Section
      padding="none"
      accent={ACCENTS.demo}
    >
      <Copy
        variant="section"
        caption="Sapien Language Engine"
        title="Low-level text parsing"
        body={(
          <p>
            Handle low-level NLP tasks including part-of-speech
            (POS) tagging, lemmatization, tokenization, and dependency
            parsing.
          </p>
        )}
        footer={(
          <>
            <Form>
              <Form.FieldRow>
                <Select
                  height={40}
                  width="100%"
                  value={dropdownValue}
                  onChange={onSelectChange}
                >
                  {Object.keys(OPTIONS).map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </Select>
                <Textarea
                  className={styles.textarea}
                  value={textareaValue}
                  onChange={onTextareaChange}
                  placeholder="Enter custom text..."
                />
              </Form.FieldRow>
              <Form.ButtonRow>
                <Button
                  height={40}
                  appearance="primary"
                  isLoading={asyncFetchResult.loading}
                  onClick={onSubmit}
                >
                  Run
                </Button>
              </Form.ButtonRow>
              <Form.ErrorRow error={asyncFetchResult.error} />
            </Form>
            {(asyncFetchResult.result)
              && (
              <div className={demoStyles['response-data']}>
                <Tabs
                  defaultTab={typeQueryParam}
                  onChange={onTabChange}
                >
                  <Tab
                    id="pos"
                    label="Parts of Speech"
                  >
                    <SapienPartsOfSpeechVisualizer
                      sentences={get(asyncFetchResult.result, 'data', [])}
                    />
                  </Tab>
                  <Tab
                    id="lemmatization"
                    label="Lemmatization"
                  >
                    <SapienLemmaVisualizer
                      sentences={get(asyncFetchResult.result, 'data', [])}
                    />
                  </Tab>
                  <Tab
                    id="dep"
                    label="Dependency Parse"
                  >
                    <SapienDependencyParseVisualizer
                      sentences={get(asyncFetchResult.result, 'data', [])}
                    />
                  </Tab>
                </Tabs>
              </div>
              )}
          </>
        )}
      />
    </Section>
  );
};

export default DemoSapienParsing;
