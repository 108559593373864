import React from 'react';
import PropTypes from 'prop-types';

import Copy from 'components/Copy';

import { get } from 'lodash';

import styles from './styles.module.scss';

const CortexRelationshipCard = ({
  subject, predicate, object, qualifiers,
}) => {
  let objectStringValue = (
    get(predicate, 'id') === 'PWPDESCRIPTION'
      ? get(subject, 'label')
      : get(object, 'label')
  );

  const subjectStringValue = get(subject, 'label');

  const imageUrl = (
    get(object, 'thumbnailImageURL')
    || get(subject, 'thumbnailImageURL')
    || null
  );

  const realDescription = (
    get(object, 'description')
    || get(subject, 'description')
    || null
  );

  const hasTime = get(qualifiers, '[0].object.type') === 'time';

  const propertyValue = `${hasTime ? objectStringValue : subjectStringValue} / ${get(predicate, 'label')}`;

  if (hasTime) {
    const duration = qualifiers.map((q) => get(q, 'object.label'));
    objectStringValue = duration.join(' - ');
  }

  return (
    <div className={styles.card}>
      {imageUrl
        && (
        <div
          className={styles['card-image']}
          style={{
            backgroundImage: `url('${imageUrl}')`,
          }}
        />
        )}
      <div className={styles['card-body']}>
        <Copy
          variant="subsection"
          caption={propertyValue}
          title={objectStringValue}
          body={realDescription}
          titleClassName={styles.title}
          bodyClassName={styles.description}
        />
      </div>
    </div>
  );
};

CortexRelationshipCard.propTypes = {
  subject: PropTypes.object.isRequired,
  predicate: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  qualifiers: PropTypes.array.isRequired,
};

export default CortexRelationshipCard;
