import PropTypes from 'prop-types';

export const THEME_DEFAULT_PROPS = {
  theme: 'white',
  flavor: null,
  accent: null,
};

export const THEME_PROP_TYPES = {
  theme: PropTypes.oneOf([
    'white',
    'light',
    'dark',
    'black',
  ]),
  flavor: PropTypes.oneOf([
    'midnight',
    'sunrise',
    'oceanic',
    'forest',
    'blue',
    'purple',
    'green',
  ]),
  accent: PropTypes.oneOf([
    'midnight',
    'sunrise',
    'oceanic',
    'forest',
    'blue',
    'purple',
    'green',
    'logo',
  ]),
};

export const FLAVORS = {
  home: 'midnight',
  government: 'blue',
  commercial: 'purple',
  developers: 'green',
  sapien: 'oceanic',
  cortex: 'sunrise',
  lingua: 'forest',
  docs: 'midnight',
};

export const ACCENTS = {
  home: 'logo',
  government: 'blue',
  commercial: 'purple',
  developers: 'green',
  sapien: 'oceanic',
  cortex: 'sunrise',
  lingua: 'forest',
  pricing: 'logo',
  demo: 'logo',
  docs: 'logo',
  blog: 'logo',
  terms: 'logo',
};
