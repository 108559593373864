import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export const HexagonCircle = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 928 901"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={[styles.background, className].join(' ')}
  >
    <path
      className={styles['flavor-4']}
      d="M286.904 44.366l44.254 165.692-121.366 121.171-165.62-44.52L-.084 121.017 121.283-.154l165.621 44.52z"
    />
    <circle
      className={styles['flavor-6']}
      cx={800.5}
      cy={773.5}
      r={127.5}
    />
  </svg>
);

HexagonCircle.defaultProps = {
  className: '',
};

HexagonCircle.propTypes = {
  className: PropTypes.string,
};

export const GridPentagon = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 928 901"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={[styles.background, className].join(' ')}
  >
    <circle
      className={styles['flavor-4']}
      cx={675}
      cy={15}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={723}
      cy={15}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={770}
      cy={15}
      r={15}
    />
    <circle
      className={styles['flavor-1']}
      cx={818}
      cy={15}
      r={15}
    />
    <circle
      className={styles['flavor-1']}
      cx={865}
      cy={15}
      r={15}
    />
    <circle
      className={styles['flavor-1']}
      cx={913}
      cy={15}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={675}
      cy={63}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={723}
      cy={63}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={770}
      cy={63}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={818}
      cy={63}
      r={15}
    />
    <circle
      className={styles['flavor-1']}
      cx={865}
      cy={63}
      r={15}
    />
    <circle
      className={styles['flavor-1']}
      cx={913}
      cy={63}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={675}
      cy={110}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={723}
      cy={110}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={770}
      cy={110}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={818}
      cy={110}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={865}
      cy={110}
      r={15}
    />
    <circle
      className={styles['flavor-1']}
      cx={913}
      cy={110}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={675}
      cy={158}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={723}
      cy={158}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={770}
      cy={158}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={818}
      cy={158}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={865}
      cy={158}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={913}
      cy={158}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={675}
      cy={205}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={723}
      cy={205}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={770}
      cy={205}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={818}
      cy={205}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={865}
      cy={205}
      r={15}
    />
    <circle
      className={styles['flavor-3']}
      cx={913}
      cy={205}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={675}
      cy={253}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={723}
      cy={253}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={770}
      cy={253}
      r={15}
    />
    <circle
      className={styles['flavor-5']}
      cx={818}
      cy={253}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={865}
      cy={253}
      r={15}
    />
    <circle
      className={styles['flavor-4']}
      cx={913}
      cy={253}
      r={15}
    />
    <path
      className={styles['flavor-4']}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131 709.679l-76.39 55.5 29.178 89.802h94.424l29.178-89.802-76.39-55.5zm124.588 39.84L131 659 6.412 749.519 54 895.981h154l47.588-146.462z"
    />
  </svg>
);

GridPentagon.defaultProps = {
  className: '',
};

GridPentagon.propTypes = {
  className: PropTypes.string,
};

export const Circles = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 928 901"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={[styles.background, className].join(' ')}
  >
    <path
      className={styles['flavor-4']}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M197.5 255C253.557 255 299 209.557 299 153.5C299 97.4431 253.557 52 197.5 52C141.443 52 96 97.4431 96 153.5C96 209.557 141.443 255 197.5 255ZM197.5 306C281.723 306 350 237.723 350 153.5C350 69.2766 281.723 1 197.5 1C113.277 1 45 69.2766 45 153.5C45 237.723 113.277 306 197.5 306Z"
    />
    <path
      className={styles['flavor-2']}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.5 321C137.332 321 168 290.332 168 252.5C168 214.668 137.332 184 99.5 184C61.6685 184 31 214.668 31 252.5C31 290.332 61.6685 321 99.5 321ZM99.5 351C153.9 351 198 306.9 198 252.5C198 198.1 153.9 154 99.5 154C45.1 154 1 198.1 1 252.5C1 306.9 45.1 351 99.5 351Z"
    />
    <path
      className={styles['flavor-2']}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M774.5 849C830.557 849 876 803.557 876 747.5C876 691.443 830.557 646 774.5 646C718.443 646 673 691.443 673 747.5C673 803.557 718.443 849 774.5 849ZM774.5 900C858.723 900 927 831.723 927 747.5C927 663.277 858.723 595 774.5 595C690.277 595 622 663.277 622 747.5C622 831.723 690.277 900 774.5 900Z"
    />
    <path
      className={styles['flavor-6']}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M849.5 863C875.734 863 897 841.734 897 815.5C897 789.266 875.734 768 849.5 768C823.266 768 802 789.266 802 815.5C802 841.734 823.266 863 849.5 863ZM849.5 893C892.302 893 927 858.302 927 815.5C927 772.698 892.302 738 849.5 738C806.698 738 772 772.698 772 815.5C772 858.302 806.698 893 849.5 893Z"
    />
  </svg>
);

Circles.defaultProps = {
  className: '',
};

Circles.propTypes = {
  className: PropTypes.string,
};
