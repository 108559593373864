import React from 'react';

import Section from 'components/Section';
import Logo from 'components/Logo';
import Link from 'components/Link';

import { THEME_DEFAULT_PROPS, THEME_PROP_TYPES } from 'constants/themes';
import {
  __,
  __government,
  __commercial,
  __developers,
  __apiSapien,
  __apiCortex,
  __apiLingua,
  __docs,
  __blog,
  __about,
  __contactUs,
  __scheduleDemo,
  __terms,
  __eula,
  __privacy,
  xxSitemap,
  xxTwitter,
  xxGithub,
} from 'constants/routes';
import { CAREERS_EMAIL } from 'constants/contact';

import styles from './styles.module.scss';

const SiteFooter = ({ theme, flavor, accent }) => (
  <Section
    theme={theme}
    flavor={flavor}
    accent={accent}
  >
    <nav className={styles['footer-layout']}>
      <div
        className={[
          styles.column,
          styles['logo-column'],
        ].join(' ')}
      >
        <section className={styles.section}>
          <h1>
            <Link
              to={__.path}
              decoration={false}
              className={styles['logo-link']}
            >
              <Logo variant="symbol" />
            </Link>
          </h1>
        </section>
        <section className={styles.section}>
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Plasticity Inc.
        </section>
      </div>

      <div
        className={[
          styles.column,
          styles['uses-column'],
        ].join(' ')}
      >
        <section className={styles.section}>
          <h1 className={styles['section-header']}>Industries</h1>
          <ul className={styles.list}>
            <li className={styles['list-item']}>
              <Link
                to={__government.path}
                decoration={false}
              >
                Government
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__commercial.path}
                decoration={false}
              >
                Commercial
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__developers.path}
                decoration={false}
              >
                Developers
              </Link>
            </li>
          </ul>
        </section>
        <section className={styles.section}>
          <h1 className={styles['section-header']}>Products</h1>
          <ul className={styles.list}>
            <li className={styles['list-item']}>
              <Link
                to={__apiSapien.path}
                decoration={false}
              >
                Sapien
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__apiCortex.path}
                decoration={false}
              >
                Cortex
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__apiLingua.path}
                decoration={false}
              >
                Lingua
              </Link>
            </li>
          </ul>
        </section>
      </div>

      <div
        className={[
          styles.column,
          styles['company-column'],
        ].join(' ')}
      >
        <section className={styles.section}>
          <h1 className={styles['section-header']}>Company</h1>
          <ul className={styles.list}>
            <li className={styles['list-item']}>
              <Link
                to={__about.path}
                decoration={false}
              >
                About
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={`mailto:${CAREERS_EMAIL}`}
                decoration={false}
              >
                Careers
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__blog.path}
                decoration={false}
              >
                Blog
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={xxTwitter.path}
                decoration={false}
              >
                Twitter
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={xxGithub.path}
                decoration={false}
              >
                GitHub
              </Link>
            </li>
          </ul>
        </section>
      </div>

      <div
        className={[
          styles.column,
          styles['resources-column'],
        ].join(' ')}
      >
        <section className={styles.section}>
          <h1 className={styles['section-header']}>Resources</h1>
          <ul className={styles.list}>
            <li className={styles['list-item']}>
              <Link
                decoration={false}
                to={__docs.path}
              >
                Documentation
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                decoration={false}
                to={__scheduleDemo.path}
              >
                Request demo
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__contactUs.path}
                decoration={false}
              >
                Contact
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__terms.path}
                decoration={false}
              >
                Terms
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__eula.path}
                decoration={false}
              >
                EULA
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={__privacy.path}
                decoration={false}
              >
                Privacy
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to={`${process.env.REACT_APP_URL}${xxSitemap.path}`}
                decoration={false}
              >
                Sitemap
              </Link>
            </li>
          </ul>
        </section>
        {/* <section className={styles.section}>
          <h1 className={styles['section-header']}>Alternatives</h1>
          <ul className={styles.list}>
            <li className={styles['list-item']}>
              <Link
                to="#"
                decoration={false}
              >
                Google Cloud NL
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to="#"
                decoration={false}
              >
                Allen NLP
              </Link>
            </li>
            <li className={styles['list-item']}>
              <Link
                to="#"
                decoration={false}
              >
                GPT-3
              </Link>
            </li>
          </ul>
        </section> */}
      </div>
    </nav>
  </Section>
);

SiteFooter.defaultProps = {
  ...THEME_DEFAULT_PROPS,
};

SiteFooter.propTypes = {
  ...THEME_PROP_TYPES,
};

export default SiteFooter;
