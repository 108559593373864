import React from 'react';

import CTAButton from 'components/CTAButton';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import { open as openCrisp } from 'components/Crisp';
import Link from 'components/Link';
import RowLayout from 'components/RowLayout';
import Section from 'components/Section';
import SiteFooter from 'components/SiteFooter';
import SiteHeader from 'components/SiteHeader';

import {
  CONTACT_EMAIL,
  SUPPORT_EMAIL,
  PHONE,
  PHONE_CLEAN,
  ADDRESS,
} from 'constants/contact';
import { ACCENTS } from 'constants/themes';

import styles from './styles.module.scss';

const ContactUs = () => (
  <>
    <SiteHeader
      theme="light"
      accent={ACCENTS.home}
    />
    <Section
      theme="dark"
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Contact us"
            title="Get in touch with Plasticity"
            body={(
              <p>
                For answers to your questions and information on how Plasticity
                can support you and your organization, please contact us using
                the appropriate channel below.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout columns="2,2">
          <Copy
            variant="subsection"
            title="General Inquiries"
            body={(
              <RowLayout size="sm">
                <Copy
                  variant="detail"
                  title="Email"
                  body={
                    <Link to={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>
                  }
                />
                <Copy
                  variant="detail"
                  title="Phone"
                  body={<Link to={`tel:${PHONE_CLEAN}`}>{PHONE}</Link>}
                />
                <Copy
                  variant="detail"
                  title="Address"
                  body={ADDRESS.split('\n').map((addressLine, index) => (
                    <div key={index}>{addressLine}</div>
                  ))}
                />
              </RowLayout>
            )}
          />
          <Copy
            variant="subsection"
            title="Support"
            body={(
              <RowLayout size="sm">
                <Copy
                  variant="detail"
                  title="Email"
                  body={
                    <Link to={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
                  }
                />
                <Copy
                  variant="detail"
                  title="Chat"
                  body={(
                    <CTAButton
                      variant="button"
                      text="Chat with us"
                      onClick={openCrisp}
                    />
                  )}
                />
              </RowLayout>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <div className={styles.map}>
      <iframe
        title={ADDRESS}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.9141239555!2d-77.21926178418029!3d38.92594027956619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64ac004cfca5d%3A0xebb899ca3a782f4e!2s7918%20Jones%20Branch%20Dr%2C%20McLean%2C%20VA%2022102!5e0!3m2!1sen!2sus!4v1607987454988!5m2!1sen!2sus"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
      />
    </div>
    <SiteFooter theme="light" />
  </>
);

export default ContactUs;
