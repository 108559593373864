import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { gradientDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from './styles.module.scss';

const CodeBlock = ({ language, code, className }) => (
  <div className={styles.outer}>
    <SyntaxHighlighter
      style={gradientDark}
      language={language}
      showLineNumbers
      lineNumberStyle={{
        paddingRight: '20px',
      }}
      codeTagProps={{
        className: styles.code,
      }}
      className={[
        styles.highlighter,
        className,
      ].join(' ')}
    >
      {code}
    </SyntaxHighlighter>
  </div>
);

CodeBlock.defaultProps = {
  className: '',
};

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CodeBlock;
