import React, { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Alert } from 'evergreen-ui';

import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import APIEmbed from 'components/APIEmbed';
import JSONViewer from 'components/JSONViewer';
import LoadingSpinner from 'components/LoadingSpinner';

import { convertToDemoEndpoint, makeApiRequest } from 'utils/api';

import styles from './styles.module.scss';

const fetchExampleData = async (url) => {
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const fetchApiResult = async (url, data) => makeApiRequest(url, data);

const Example = ({ id, url, methods }) => {
  const asyncFetchExampleData = useAsyncCallback(fetchExampleData);
  const asyncFetchApiResult = useAsyncCallback(fetchApiResult);
  const bucketUrl = (
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
      ? 'www.plasticity.ai-doc-examples'
      : 'www-staging.plasticity.ai-doc-examples'
  );
  const jsonUrl = `https://s3.amazonaws.com/${bucketUrl}/${id}.json`;

  /**
   * Fetch the example JSON object from S3 on component
   * render.
   */
  const onTabChange = (tabId) => {
    if (tabId === 'response') {
      if (!asyncFetchExampleData.result) {
        asyncFetchExampleData.execute(jsonUrl);
      }
    }
  };

  /**
   * Fetch the Plasticity API response for the url and
   * data that is specified in the example once it has
   * been fetched.
   */
  useEffect(() => {
    if (!asyncFetchExampleData.loading && asyncFetchExampleData.result) {
      const { url: apiUrl, postData } = asyncFetchExampleData.result;
      const demoUrl = convertToDemoEndpoint(apiUrl);
      const data = JSON.parse(postData.text);
      asyncFetchApiResult.execute(demoUrl, data);
    }
  }, [asyncFetchExampleData.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <Tabs onChange={onTabChange}>
        <Tab
          id="request"
          label="Request"
        >
          <APIEmbed
            id={id}
            sourceUrl={jsonUrl}
            url={url}
            methods={methods}
          />
        </Tab>
        <Tab
          id="response"
          label="Response"
        >
          {asyncFetchExampleData.error
            && (
            <Alert
              intent="danger"
              title={asyncFetchExampleData.error.message}
            />
            )}
          {asyncFetchApiResult.error
            && (
            <Alert
              intent="danger"
              title={asyncFetchApiResult.error.message}
            />
            )}
          {asyncFetchExampleData.loading || asyncFetchApiResult.loading
            ? <LoadingSpinner withContainer />
            : (
              <JSONViewer
                collapsed={3}
                src={asyncFetchApiResult.result || {}}
              />
            )}
        </Tab>
      </Tabs>
    </div>
  );
};

Example.defaultProps = {
  methods: APIEmbed.defaultProps.methods,
};

Example.propTypes = {
  id: APIEmbed.propTypes.id, // eslint-disable-line react/require-default-props
  url: APIEmbed.propTypes.url, // eslint-disable-line react/require-default-props
  methods: APIEmbed.propTypes.methods,
};

export default Example;
