import React from 'react';
import Tilt from 'react-tilt';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import GridLayout from 'components/GridLayout';
import Copy from 'components/Copy';
import Card from 'components/Card';
import FeatureCard from 'components/FeatureCard';
import Checklist from 'components/Checklist';
import CTAButton from 'components/CTAButton';
import { CortexLine } from 'components/Icons';

import { FLAVORS, ACCENTS } from 'constants/themes';
import { __scheduleDemo } from 'constants/routes';
import { __docsCortexStructuredNaturalLanguageQA } from 'pages/docs/routes';
import { __demoCortex } from 'pages/demo/routes';

import styles from './styles.module.scss';

const imagesFolder = `${process.env.PUBLIC_URL}/images/cortex`;

const Cortex = () => (
  <>
    <SiteHeader
      variant="overlay"
      theme="dark"
      accent={ACCENTS.cortex}
    />
    <Section
      theme="dark"
      gradient="partial"
      flavor={FLAVORS.cortex}
      accent={ACCENTS.cortex}
    >
      <RowLayout>
        <ColumnLayout columns="2,2">
          <Copy
            variant="hero"
            caption="Cortex Knowledge Graph"
            title="Structured information about the real world"
            body={(
              <p>
                Tap into 240+ million facts on over 20+ million
                entities using the Cortex Knowledge Graph. Query
                the graph in natural language or make requests
                for rich data on people, places, and things.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demoCortex.path}
                />
              </>
            )}
          />
          <Tilt options={{ max: 50, scale: 1.1 }}>
            <CortexLine className={styles['hero-icon']} />
          </Tilt>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="white"
      flavor={FLAVORS.cortex}
      accent={ACCENTS.cortex}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Real-world knowledge"
            title="Tap into information about people, places, and things"
            body={(
              <p>
                Cortex enriches your application with information
                about the real world. Use Cortex to determine
                the population of a city or find relationships
                from a diverse set of entities. Uncover this
                information using just a natural language question.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout columns="2,2">
          <Copy
            useAccent
            variant="subsection"
            title="Entities and their properties"
            body={(
              <p>
                Query Cortex for an individual entity or any of
                its hundreds of properties. Each entity has links
                back to its original source identifier, with useful
                metadata including clean descriptions, pictures,
                and more.
              </p>
            )}
            footer={(
              <RowLayout>
                <Checklist
                  columns={2}
                  data={[
                    'Entity lookups',
                    'Property lookups',
                    'Photos and descriptions',
                    'Entity categories',
                    'Factoid questions',
                    'Compound queries',
                    'Nested queries',
                  ]}
                />
                <GridLayout>
                  <FeatureCard
                    tilt
                    span={2}
                    body="When was Abraham Lincoln born and when was Thomas Jefferson president?"
                  />
                  <FeatureCard
                    tilt
                    span={1}
                    body="What is the area of California?"
                  />
                  <FeatureCard
                    tilt
                    span={1}
                    body="How tall is Michael Jordan?"
                  />
                  <FeatureCard
                    tilt
                    span={1}
                    body="Who is Arya Stark's father's wife?"
                  />
                  <FeatureCard
                    tilt
                    span={2}
                    body="Who directed Shawshank Redemption, Good Will Hunting, and Dead Poets Society?"
                  />
                  <FeatureCard
                    tilt
                    span={1}
                    body="What is the Empire State Building made of?"
                  />
                </GridLayout>
              </RowLayout>
            )}
          />
          <Copy
            useAccent
            variant="subsection"
            title="Difficult questions, rich answers"
            body={(
              <p>
                Cortex can handle challenging multi-part questions
                or questions that require multiple hops in the graph
                to answers. Each answer comes with additional metadata
                useful to your application.
              </p>
            )}
            footer={(
              <ColumnLayout columns="2,2">
                <Copy
                  variant="detail"
                  title="Ask in natural language"
                  body={(
                    <p>
                      No complicated graph query languages needed.
                      Query Cortex with natural language questions
                      or specific entities.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="Read the docs"
                      variant="link"
                      to={__docsCortexStructuredNaturalLanguageQA.path}
                    />
                  )}
                />
                <Copy
                  variant="detail"
                  title="Continuously updated"
                  body={(
                    <p>
                      Cortex performs weekly crawls over data sources
                      so you always have the most up to date information
                      readily available.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="Try it out"
                      variant="link"
                      to={__demoCortex.path}
                    />
                  )}
                />
              </ColumnLayout>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="light"
      accent={ACCENTS.cortex}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Use cases"
            title="Add underlying real-world knowledge to your application"
            body={(
              <p>
                Instantly answer questions from over 240+ million
                structured facts. The Cortex Knowledge Graph helps
                you access data on real-world people, places, and
                things and use that information to make your
                application more intelligent.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Show rich metadata on entities"
            body={(
              <p>
                Display information about people, places, and things
                in your application. You can use Cortex to find additional
                information about companies mentioned in a news article
                or people mentioned in a post, and show more information
                about them on hover.
              </p>
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/metadata.svg`}
              alt="Plasticity Cortex can provide metadata on entities"
              width="100%"
            />
          </Card>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="General knowledge for conversational interfaces"
            body={(
              <p>
                Instantly answer general knowledge questions in
                conversational interfaces. Or use our graph to
                find all items in a particular category&mdash;everything
                from vegetables to airports&mdash;to automatically generate
                more training data for your machine learning models.
              </p>
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/knowledge.svg`}
              alt="Plasticity Cortex provides general knowledge for chatbots"
              width="100%"
            />
          </Card>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Build business intelligence"
            body={(
              <p>
                Pull all information about a given company, including
                its number of employees, its CEO, what industry it&apos;s in,
                and more. Use this data to tailor sales pitches or conduct
                better market research.
              </p>
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/bi.svg`}
              alt="Plasticity Cortex provides business intelligence for market research"
              width="100%"
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="white"
      accent={ACCENTS.cortex}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="section"
            title="Getting started is easy"
            body={(
              <p>
                Start using the Cortex Knowledge Graph in your application
                in just a few minutes by signing up for a developer
                API account today. Schedule a demo with our sales team
                to discuss your specific needs and use cases&mdash;we&apos;re
                here to help.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demoCortex.path}
                />
              </>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Cortex;
