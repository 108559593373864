import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Header = ({ children }) => (
  <h1 className={styles.header}>{children}</h1>
);

Header.defaultProps = {
  children: null,
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
