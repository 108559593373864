import React from 'react';
import Tilt from 'react-tilt';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import Card from 'components/Card';
import CodeBlock from 'components/CodeBlock';
import Checklist from 'components/Checklist';
import CTAButton from 'components/CTAButton';
import Link from 'components/Link';
import { SapienLine } from 'components/Icons';

import { FLAVORS, ACCENTS } from 'constants/themes';
import { __scheduleDemo, xxBenchmarkSapien } from 'constants/routes';
import { __demoSapien } from 'pages/demo/routes';

import styles from './styles.module.scss';

const imagesFolder = `${process.env.PUBLIC_URL}/images/sapien`;

const Sapien = () => (
  <>
    <SiteHeader
      variant="overlay"
      theme="dark"
      accent={ACCENTS.sapien}
    />
    <Section
      theme="dark"
      gradient="partial"
      flavor={FLAVORS.sapien}
      accent={ACCENTS.sapien}
    >
      <RowLayout>
        <ColumnLayout columns="2,2">
          <Copy
            variant="hero"
            caption="Sapien Language Engine"
            title="Common natural language tasks with speed and accuracy"
            body={(
              <p>
                Understand the who, what, when, where, and why
                from unstructured data. Extract entities,
                relationships, and context from text using the
                Sapien Language Engine.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demoSapien.path}
                />
              </>
            )}
          />
          <Tilt options={{ max: 50, scale: 1.1 }}>
            <SapienLine className={styles['hero-icon']} />
          </Tilt>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="white"
      accent={ACCENTS.sapien}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="All-in-one"
            title="Everything you need to get started with natural language"
            body={(
              <p>
                Sapien handles the low-level natural language
                processing tasks needed for most applications.
                It is written for speed and accuracy, and it
                works on text across various domains and writing
                styles.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout columns="2,2">
          <Copy
            useAccent
            variant="subsection"
            title="Complete NLP infrastructure"
            body={(
              <p>
                Receive a wealth of text metadata with just
                one API call. Sapien performs a variety of
                important NLP tasks on unstructured text and
                returns the results in a structured JSON format.
              </p>
            )}
            footer={(
              <Checklist
                columns={2}
                data={[
                  'Named entity recognition',
                  'Syntax dependency parsing',
                  'Relation extraction',
                  'Part-of-speech tagging',
                  'Spelling correction',
                  'Tokenization',
                  'Lemmatization',
                  'Name detection',
                  'Noun pluralization',
                  'Verb conjugation',
                  'Adjective transformation',
                  'Adverb transformation',
                ]}
              />
            )}
          />
          <Copy
            useAccent
            variant="subsection"
            title="Accuracy and speed"
            body={(
              <p>
                Sapien tops benchmarks on accuracy and was
                designed from the ground up in C++ for speed
                on large-scale text data, unlike other Python
                and Java-based tools.
              </p>
            )}
            footer={(
              <ColumnLayout columns="2,2">
                <Copy
                  variant="detail"
                  title="State of the art"
                  body={(
                    <p>
                      Plasticity tops NLP accuracy benchmarks for open
                      information extraction, coreference resolution, and
                      slot filling.
                    </p>
                  )}
                  footer={(
                    <Link
                      to={xxBenchmarkSapien.path}
                      target="_blank"
                      decoration={false}
                    >
                      <CTAButton
                        text="Benchmarks"
                        variant="link"
                      />
                    </Link>
                  )}
                />
                <Copy
                  variant="detail"
                  title="Built for speed"
                  body={(
                    <p>
                      Designed from the ground up to parse text at 6,000
                      sentences per second, over 80x faster than other
                      solutions.
                    </p>
                  )}
                  footer={(
                    <CTAButton
                      text="Try it out"
                      variant="link"
                      to={__demoSapien.path}
                    />
                  )}
                />
              </ColumnLayout>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="light"
      flavor={FLAVORS.sapien}
      accent={ACCENTS.sapien}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Use cases"
            title="Power your applications with the Sapien Language Engine"
            body={(
              <p>
                Join hundreds of other companies using Sapien to build
                more human-like experiences in their products. Sapien
                can help uncover structure in data, perform better search,
                generate language, and more.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Extract meaning from data"
            body={(
              <p>
                Get structured information from unstructured text. Pull
                out triples (subject, predicate, object relations) from
                sentences in large quantities of data. Extract symptoms
                from medical notes, information from case briefs, entities
                in articles, or additional data hidden in text. Make all
                of it structured and searchable.
              </p>
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/meaning.svg`}
              alt="Plasticity Sapien converts unstructured text to structured data"
              width="100%"
            />
          </Card>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Smarter search"
            body={(
              <p>
                Add semantic understanding to your search so users can
                find results even if their search queries vary from your
                content. Instead of relying on keyword search, use
                natural language understanding to reduce the number of
                &ldquo;no results found&rdquo; messages.
              </p>
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/search.svg`}
              alt="Plasticity Sapien helps build smarter smart"
              width="100%"
            />
          </Card>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Natural language generation"
            body={(
              <p>
                Transform words into various linguistic forms to generate
                human-like natural language from keywords. Convert plural
                nouns into singular form, conjugate verbs, and turn words
                into a grammatically correct sentence.
              </p>
            )}
          />
          <Card>
            <CodeBlock
              language="python"
              code={
`from plasticity import Plasticity

plasticity = Plasticity()

a = plasticity.sapien.transform.post('eating', 'VerbPast')
print('eating ->', a) # eating -> ate

b = plasticity.sapien.transform.post('leaf', 'NounPlural')
print('leaf ->', b) # leaf -> leaves`
              }
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="white"
      accent={ACCENTS.sapien}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="section"
            title="Getting started is easy"
            body={(
              <p>
                Start using the Sapien Language Engine in your application
                in just a few minutes by signing up for a developer
                API account today. Schedule a demo with our sales team
                to discuss your specific needs and use cases&mdash;we&apos;re
                here to help.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demoSapien.path}
                />
              </>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Sapien;
