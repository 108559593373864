import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'evergreen-ui';

const ErrorRow = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div>
      <Alert
        intent="danger"
        title={error.message}
      />
    </div>
  );
};

ErrorRow.defaultProps = {
  error: null,
};

ErrorRow.propTypes = {
  error: PropTypes.object,
};

export default ErrorRow;
