import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';

import styles from './styles.module.scss';

const MenuListItem = ({
  icon, title, text, to, ...rest
}) => {
  const content = (
    <li
      className={[
        styles.item,
        title ? styles['has-title'] : '',
      ].join(' ')}
      {...rest}
    >
      {icon
        && (
        <span className={styles.icon}>
          {icon}
        </span>
        )}
      <div className={styles.body}>
        {title && <span className={styles.title}>{title}</span>}
        {text && <span className={styles.text}>{text}</span>}
      </div>
    </li>
  );

  return (
    to
      ? (
        <Link
          to={to}
          decoration={false}
          className={styles.link}
        >
          {content}
        </Link>
      )
      : content
  );
};

MenuListItem.defaultProps = {
  icon: null,
  title: '',
  text: '',
  to: '',
};

MenuListItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default MenuListItem;
