import React from 'react';
import PropTypes from 'prop-types';
import {
  RiBookOpenLine,
  RiCodeSSlashLine,
  RiCloseFill,
  RiGithubLine,
  RiGovernmentLine,
  RiPaletteLine,
  RiPenNibLine,
  RiPhoneLine,
  RiPriceTag3Line,
  RiShieldUserLine,
  RiStore2Line,
} from 'react-icons/ri';

import Card from 'components/Card';
import List from 'components/List';
import MenuListItem from 'components/MenuListItem';
import Icon from 'components/Icon';
import {
  SapienLine,
  CortexLine,
  LinguaLine,
} from 'components/Icons';

import {
  __government,
  __commercial,
  __developers,
  __apiSapien,
  __apiCortex,
  __apiLingua,
  __demo,
  __pricing,
  __docs,
  __blog,
  __openSource,
  __scheduleDemo,
  __contactUs,
} from 'constants/routes';
import { ACCENTS } from 'constants/themes';

import globalStyles from 'css/global.module.scss';
import styles from './styles.module.scss';

const SiteMobileMenu = ({ visible, onClose }) => (
  <div
    className={[
      styles.container,
      visible ? styles.show : '',
    ].join(' ')}
  >
    <Card
      className={[
        styles.card,
        globalStyles['theme-white'],
      ].join(' ')}
    >
      <section className={styles.section}>
        <h1 className={styles.header}>Use cases</h1>
        <List>
          <MenuListItem
            to={__government.path}
            title="Government"
            icon={(
              <Icon
                glyph={<RiGovernmentLine />}
                size="sm"
                accent={ACCENTS.government}
              />
              )}
            onClick={onClose}
          />
          <MenuListItem
            to={__commercial.path}
            title="Commercial"
            icon={(
              <Icon
                glyph={<RiStore2Line />}
                size="sm"
                accent={ACCENTS.commercial}
              />
              )}
            onClick={onClose}
          />
          <MenuListItem
            to={__developers.path}
            title="Developers"
            icon={(
              <Icon
                glyph={<RiCodeSSlashLine />}
                size="sm"
                accent={ACCENTS.developers}
              />
              )}
            onClick={onClose}
          />
        </List>
      </section>
      <section className={styles.section}>
        <h1 className={styles.header}>API Endpoints</h1>
        <List>
          <MenuListItem
            to={__apiSapien.path}
            title="Sapien Language Engine"
            icon={(
              <Icon
                glyph={<SapienLine />}
                size="sm"
                accent={ACCENTS.sapien}
              />
              )}
            onClick={onClose}
          />
          <MenuListItem
            to={__apiCortex.path}
            title="Cortex Knowledge Graph"
            icon={(
              <Icon
                glyph={<CortexLine />}
                size="sm"
                accent={ACCENTS.cortex}
              />
              )}
            onClick={onClose}
          />
          <MenuListItem
            to={__apiLingua.path}
            title="Lingua Dialogue Engine"
            icon={(
              <Icon
                glyph={<LinguaLine />}
                size="sm"
                accent={ACCENTS.lingua}
              />
              )}
            onClick={onClose}
          />
        </List>
      </section>
      <section className={styles.section}>
        <h1 className={styles.header}>Resources</h1>
        <List>
          <MenuListItem
            to={__demo.path}
            title="Demo"
            icon={<RiPaletteLine />}
            onClick={onClose}
          />
          <MenuListItem
            to={__pricing.path}
            title="Pricing"
            icon={<RiPriceTag3Line />}
            onClick={onClose}
          />
          <MenuListItem
            to={__docs.path}
            title="Documentation"
            icon={<RiBookOpenLine />}
            onClick={onClose}
          />
          <MenuListItem
            to={__openSource.path}
            title="Open source"
            icon={<RiGithubLine />}
            onClick={onClose}
          />
        </List>
      </section>
      <section className={styles.section}>
        <h1 className={styles.header}>Stay in touch</h1>
        <List>
          <MenuListItem
            to={__blog.path}
            title="Blog"
            icon={<RiPenNibLine />}
            onClick={onClose}
          />
          <MenuListItem
            to={__scheduleDemo.path}
            title="Request a demo"
            icon={<RiShieldUserLine />}
            onClick={onClose}
          />
          <MenuListItem
            to={__contactUs.path}
            title="Contact us"
            icon={<RiPhoneLine />}
            onClick={onClose}
          />
        </List>
      </section>
      <RiCloseFill
        className={styles.close}
        onClick={onClose}
      />
    </Card>
  </div>
);

SiteMobileMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SiteMobileMenu;
