import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import { SUPPORT_EMAIL } from 'constants/contact';

const GettingStarted = () => (
  <DocLayout>
    <DocLayout.Title>
      Getting Started
    </DocLayout.Title>
    <DocLayout.Body>
      Use the example code snippets to quickly get started
      prototyping. If you need any help, reach out to us at
      {' '}
      <Link to={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
      .
    </DocLayout.Body>
  </DocLayout>
);

export default GettingStarted;
