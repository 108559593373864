import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const TitleHelmet = ({ title }) => (
  <Helmet>
    <title>{title}</title>
    <meta
      name="title"
      content={title}
    />
    <meta
      property="og:title"
      content={title}
    />
    <meta
      name="twitter:title"
      content={title}
    />
  </Helmet>
);

TitleHelmet.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleHelmet;
