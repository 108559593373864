import React from 'react';
import Tilt from 'react-tilt';

import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import Checklist from 'components/Checklist';
import CTAButton from 'components/CTAButton';
import { LinguaLine } from 'components/Icons';

import { FLAVORS, ACCENTS } from 'constants/themes';
import { __scheduleDemo } from 'constants/routes';

import styles from './styles.module.scss';

const Lingua = () => (
  <>
    <SiteHeader
      variant="overlay"
      theme="dark"
      accent={ACCENTS.lingua}
    />
    <Section
      theme="dark"
      gradient="partial"
      flavor={FLAVORS.lingua}
      accent={ACCENTS.lingua}
    >
      <RowLayout>
        <ColumnLayout columns="2,2">
          <Copy
            variant="hero"
            caption="Lingua Dialogue Engine"
            title="Build more robust conversational interfaces"
            body={(
              <p>
                Train your own chatbot or conversational interface
                to handle automated messages. Lingua parses the intent,
                fills slots, provides responses, and even manages
                context in the conversation.
              </p>
            )}
            footer={(
              <CTAButton
                text="Request early access"
                variant="button"
                to={__scheduleDemo.path}
              />
            )}
          />
          <Tilt options={{ max: 50, scale: 1.1 }}>
            <LinguaLine className={styles['hero-icon']} />
          </Tilt>
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="light"
      accent={ACCENTS.lingua}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Chat automation"
            title="State-of-the-art intent classification and slot filling"
            body={(
              <p>
                Lingua helps make more human-like bots. It
                handles more complex queries like multi-parted
                questions, negation, and adjective-noun correlation,
                so you can minimize fallback messages.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout columns="2,2">
          <Copy
            useAccent
            variant="subsection"
            title="Handles the entire conversation"
            body={(
              <p>
                Beyond just intent and slot parsing, Lingua
                manages an entire multi-turn conversation&mdash;
                everything from follow-up questions to context
                about a user.
              </p>
            )}
            footer={(
              <Checklist
                columns={2}
                data={[
                  'Intent parsing',
                  'Slot filling',
                  'Context management',
                  'Word negation',
                  'Robust to paraphrases',
                  'Less training data',
                  'Adjective-noun correlation',
                  'Follow-ups and multi-turn dialogue',
                ]}
              />
            )}
          />
          <Copy
            useAccent
            variant="subsection"
            title="Integrate anywhere"
            body={(
              <p>
                Combines an easy-to-use training interface
                with the flexibility of handling messages
                directly in your app, no matter what language
                its written in.
              </p>
            )}
            footer={(
              <ColumnLayout columns="2,2">
                <Copy
                  variant="detail"
                  title="Any programming language"
                  body={(
                    <p>
                      Use the programming language of your choice
                      to send messages to the API and receive JSON
                      replies with additional context.
                    </p>
                  )}
                />
                <Copy
                  variant="detail"
                  title="Cloud-based training"
                  body={(
                    <p>
                      Add training examples in our cloud-hosted training
                      environment. Use Lingua&apos;s paraphrase automation to
                      minimze the number of examples.
                    </p>
                  )}
                />
              </ColumnLayout>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <Section
      theme="white"
      accent={ACCENTS.lingua}
    >
      <RowLayout>
        <ColumnLayout columns="2,2">
          <Copy
            variant="section"
            title="Join the beta"
            body={(
              <p>
                Request access to the Lingua Dialogue Engine beta program
                by scheduling time with our sales team to discuss your
                specific needs and use cases&mdash;we&apos;re excited to hear
                how you plan to use Lingua.
              </p>
            )}
            footer={(
              <CTAButton
                text="Request early access"
                variant="button"
                to={__scheduleDemo.path}
              />
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Lingua;
