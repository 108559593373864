import React from 'react';

import TitleHelmet from 'components/Helmets/TitleHelmet';
import DescriptionHelmet from 'components/Helmets/DescriptionHelmet';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import Card from 'components/Card';
import Checklist from 'components/Checklist';
import CTAButton from 'components/CTAButton';
import CommercialHero from 'components/CommercialHero';
import LazyImage from 'components/LazyImage';

import { FLAVORS, ACCENTS } from 'constants/themes';
import {
  __scheduleDemo,
  __demo,
} from 'constants/routes';

const imagesFolder = `${process.env.PUBLIC_URL}/images/commercial`;

const TITLE_HELMET = <TitleHelmet title="Commercial · Plasticity Natural Language Processing tools and solutions" />;
const DESCRIPTION_HELMET = <DescriptionHelmet description="Plasticity provides commercial companies with state-of-the-art natural language processing tools and solutions to convert unstructured text into structured data, build chatbots, monitor social media and news, and more." />;

const Commercial = () => (
  <>
    {TITLE_HELMET}
    {DESCRIPTION_HELMET}
    <SiteHeader
      variant="overlay"
      theme="light"
      accent={ACCENTS.commercial}
    />
    <Section
      theme="light"
      gradient="partial"
      flavor={FLAVORS.commercial}
      accent={ACCENTS.commercial}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="hero"
            title="Plasticity for Commercial"
            body={(
              <p>
                Companies large and small use Plasticity to parse
                text. Our APIs help build better search, create
                smarter chatbots, generate custom knowledge
                graphs of internal data, enrich business
                intelligence, and much more.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request a demo"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try our API"
                  variant="link"
                  to={__demo.path}
                />
              </>
            )}
          />
          <CommercialHero />
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Unstructured to structured */}
    <Section
      theme="dark"
      accent={ACCENTS.commercial}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Document analysis"
            title="Make sense of your business's unstructured data"
            body={(
              <p>
                We build systems that process large amounts of text
                to extract and relate entities. Plasticity can run
                over news, social media, or your internal documents,
                helping you structure any piece of text. It even
                works on text from different industries, since it&apos;s
                based on semantics and not industry-specific training
                examples.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Convert text to a structured form"
            body={(
              <p>
                Take any body of text and convert it to relationship
                triples, which can be stored and queried. Use Plasticity
                to improve search on your website or within documents.
                Allow your users or employees to ask natural language
                questions about your data.
              </p>
            )}
            footer={(
              <Checklist
                columns={1}
                data={[
                  'Extract all entities from a document',
                  'Find open-domain relationships among entities',
                  'Build your own custom knowledge graph',
                  'Query documents in natural language',
                  'Summarize large documents visually',
                ]}
              />
            )}
          />
          <img
            src={`${imagesFolder}/unstructured.svg`}
            alt="Plasticity converts unstructured text to structured data"
            width="100%"
          />
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Chatbots */}
    <Section
      theme="white"
      accent={ACCENTS.commercial}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Conversational interfaces"
            title="Smarter automated chat for customer service"
            body={(
              <p>
                Respond to an influx of customer service messages
                intelligently. Whether you&apos;re categorizing support
                tickets, automating chat responses, surfacing relevant helpbase
                articles, or tagging resolutions, Plasticity&apos;s products
                can help your business achieve better automated support
                results.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Human-like message understanding"
            body={(
              <p>
                Most chatbot platforms require enormous amounts of
                labeled training data to be effective, and yet still
                fail to handle minor variations in phrasing. Plasticity
                takes a semantic approach, which means you&apos;ll be able
                to handle negations, multi-parted requests, and paraphrases
                with fewer training examples right out of the box.
              </p>
            )}
            footer={(
              <Checklist
                columns={1}
                data={[
                  'State-of-the-art intent parsing and slot filling',
                  'Requires fewer training examples',
                  'Handles complex, multi-parted requests',
                  'Preserves context and chat history',
                  'Works with any messaging system, even custom ones',
                ]}
              />
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/messaging.svg`}
              alt="Plasticity helps companies automate responses to messages"
              width="100%"
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Brand insights */}
    <Section
      theme="light"
      accent={ACCENTS.commercial}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Brand insights"
            title="Monitor news and social media for your brand or your competitor's"
            body={(
              <p>
                Plasticity helped the Wall Street Journal automatically
                scan social media to turn tweets into structured data.
                They used this data to quickly report on negative
                activity and sentiment towards political campaigns.
                Our product helps businesses find brand mentions and
                derive insights.
              </p>
            )}
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="More than just keyword search"
            body={(
              <p>
                Go beyond traditional keyword search and find
                content that doesn&apos;t specifically mention your
                brand or products. Plasticity&apos;s semantic approach
                allows you to analyze the sentiment of particular
                entities, handle negated terms, and achieve more
                accurate results.
              </p>
            )}
            footer={(
              <Checklist
                columns={1}
                data={[
                  'Determine document and entity sentiment',
                  'Find all brand, company, or product mentions',
                  'Handles paraphrases, misspellings, or related terms',
                  'Better handling of negation for sentiment analysis',
                  'Uncover bot activity',
                ]}
              />
            )}
          />
          <Card>
            <img
              src={`${imagesFolder}/brands.svg`}
              alt="Plasticity helps brands keep a pulse on social media"
              width="100%"
            />
          </Card>
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Get started */}
    <Section
      theme="white"
      accent={ACCENTS.commercial}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="section"
            caption="Custom solutions"
            title="Don't see your use case? Reach out about custom enterprise solutions"
            body={(
              <p>
                Schedule a conversation with our commercial sales team
                to learn more about how Plasticity&apos;s applications and
                custom solutions can meet your company&apos;s data needs.
              </p>
            )}
            footer={(
              <CTAButton
                text="Request a demo"
                variant="button"
                to={__scheduleDemo.path}
              />
            )}
          />
          <LazyImage
            src={`${imagesFolder}/customers.png`}
            placeholderSrc={`${imagesFolder}/customers_low.png`}
            alt="Plasticity commercial customers"
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Commercial;
