import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import CTAButton from 'components/CTAButton';

import { useDidMountEffect } from 'utils/hooks';

import styles from './styles.module.scss';

const Tabs = ({
  children, defaultTab, onChange, ...rest
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab || get(React.Children.toArray(children), '[0].props.id'));

  /**
   * Handle changes to the activeTab, but don't run
   * onChange when the component is first rendering.
   */
  useDidMountEffect(() => {
    onChange(activeTab);
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={styles.tabs}
      {...rest}
    >
      <ol className={styles.list}>
        {React.Children.map(children, (child) => {
          const {
            id,
            label,
          } = child.props;
          return (
            <CTAButton
              key={id}
              text={label}
              arrow={false}
              variant={activeTab === id ? 'button' : 'link'}
              className={styles.button}
              onClick={() => setActiveTab(id)}
            />
          );
        })}
      </ol>
      <div className={styles.content}>
        {React.Children.map(children, (child) => {
          const { id } = child.props;
          return (
            <div style={{ display: id === activeTab ? 'block' : 'none' }}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  children: null,
  defaultTab: '',
  onChange: () => {},
};

Tabs.propTypes = {
  children: PropTypes.node,
  defaultTab: PropTypes.string,
  onChange: PropTypes.func,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

export default Tabs;
