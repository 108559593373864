import React from 'react';

import DocLayout from 'components/DocLayout';
import Link from 'components/Link';

import { aaCortexStructured } from 'constants/routes';

const RequestFormat = () => {
  const getRequest = `${aaCortexStructured.path}/?query=How%20tall%20is%20Michael%20Jordan?&pretty=true`;

  return (
    <DocLayout>
      <DocLayout.Title>
        API Request Format
      </DocLayout.Title>
      <DocLayout.Body>
        The Plasticity developer HTTP APIs are JSON APIs. POST
        requests should be sent with a JSON-formatted body.
      </DocLayout.Body>
      <DocLayout.Body>
        For convenience, some APIs accept both GET and POST
        requests, which means another way to access an API
        is by using a GET request like so:
      </DocLayout.Body>
      <DocLayout.Body>
        <Link
          to={getRequest}
          target="_blank"
        >
          {getRequest}
        </Link>
      </DocLayout.Body>
      <DocLayout.Body>
        This makes it easy to test the API endpoints in your
        browser without having to write any code, or by using
        a tool like cURL or Postman.
      </DocLayout.Body>

      <DocLayout.Subheader>
        Request Header Format
      </DocLayout.Subheader>
      <DocLayout.Table
        headers={[
          'Header',
          'Value',
        ]}
        rows={[
          [
            <DocLayout.InlineCode>Content-Type</DocLayout.InlineCode>,
            <DocLayout.InlineCode>application/json</DocLayout.InlineCode>,
          ],
        ]}
      />

      <DocLayout.Subheader>
        Request Body Format
      </DocLayout.Subheader>
      <DocLayout.CodeBlock
        language="json"
        data={
`{
  "param1": "value1",
  "param2": "value2",
  "param3": "value3"
}`
        }
      />
    </DocLayout>
  );
};

export default RequestFormat;
