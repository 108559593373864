import React from 'react';
import Tilt from 'react-tilt';

import TitleHelmet from 'components/Helmets/TitleHelmet';
import DescriptionHelmet from 'components/Helmets/DescriptionHelmet';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import Icon from 'components/Icon';
import CTAButton from 'components/CTAButton';
import DevelopersHero from 'components/DevelopersHero';
import LazyImage from 'components/LazyImage';
import {
  SapienLine,
  CortexLine,
  LinguaLine,
} from 'components/Icons';

import { FLAVORS, ACCENTS } from 'constants/themes';
import {
  __apiSapien,
  __apiCortex,
  __apiLingua,
  __openSource,
  __scheduleDemo,
  __demo,
  __docs,
} from 'constants/routes';
import {
  __demoSapien,
  __demoCortex,
} from 'pages/demo/routes';

import styles from './styles.module.scss';

const imagesFolder = `${process.env.PUBLIC_URL}/images/developers`;

const TITLE_HELMET = <TitleHelmet title="Developers · Plasticity Natural Language Processing tools and solutions" />;
const DESCRIPTION_HELMET = <DescriptionHelmet description="Plasticity provides developers with state-of-the-art natural language processing APIs to handle tokenization, parts of speech tagging, dependency parsing, relation extraction, named entity recognition, and more." />;

const Developers = () => (
  <>
    {TITLE_HELMET}
    {DESCRIPTION_HELMET}
    <SiteHeader
      variant="overlay"
      theme="light"
      accent={ACCENTS.developers}
    />
    <Section
      theme="light"
      gradient="partial"
      flavor={FLAVORS.developers}
      accent={ACCENTS.developers}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="hero"
            title="Plasticity for Developers"
            body={(
              <p>
                Developer APIs that are designed to fit anywhere
                in your stack, no matter the language or application.
                Whether you&apos;re building an entire application or
                need support for a low-level NLP task, our
                APIs can get you started in just a few minutes.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Read the docs"
                  variant="link"
                  to={__docs.path}
                />
              </>
            )}
          />
          <DevelopersHero />
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Endpoints */}
    <Section
      theme="light"
      flavor={FLAVORS.developers}
      accent={ACCENTS.developers}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Endpoints"
            title="API endpoints for everything natural language processing"
          />
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Sapien Language Engine"
            body={(
              <p>
                Sapien handles the low-level natural language
                processing tasks needed for most applications.
                It is written for speed and accuracy, and it
                works on text across various domains and writing
                styles. You can use Sapien for NLP tasks like
                named entity recognition, syntax dependency
                parsing, part-of-speech tagging, open information
                extraction, natural language generation, and more.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Learn more"
                  variant="button"
                  to={__apiSapien.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demoSapien.path}
                />
              </>
            )}
          />
          <div className={styles['api-icon-container']}>
            <Tilt options={{ max: 40, scale: 1.05 }}>
              <Icon
                glyph={<SapienLine />}
                accent={ACCENTS.sapien}
                className={styles['api-icon']}
              />
            </Tilt>
          </div>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Cortex Knowledge Graph"
            body={(
              <p>
                Cortex enriches your application with information
                about the real world, such as the population of a
                city or the author of a novel. Instead of writing
                complicated graph queries, you can query for this
                information using just a natural language question
                or make a request for a specific entity or property.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Learn more"
                  variant="button"
                  to={__apiCortex.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demoCortex.path}
                />
              </>
            )}
          />
          <div className={styles['api-icon-container']}>
            <Tilt options={{ max: 40, scale: 1.05 }}>
              <Icon
                glyph={<CortexLine />}
                accent={ACCENTS.cortex}
                className={styles['api-icon']}
              />
            </Tilt>
          </div>
        </ColumnLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            useAccent
            variant="subsection"
            title="Lingua Dialogue Engine"
            body={(
              <p>
                Lingua automates and manages conversational dialogue
                flows. It performs intent classification and slot
                filling, handles more complex queries like multi-parted
                questions, negation, and adjective-noun correlation.
                Lingua also manages the conversation&apos;s context, so you
                can correctly respond to follow-up requests.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Learn more"
                  variant="button"
                  to={__apiLingua.path}
                />
                <CTAButton
                  text="Request early access"
                  variant="link"
                  to={__scheduleDemo.path}
                />
              </>
            )}
          />
          <div className={styles['api-icon-container']}>
            <Tilt options={{ max: 40, scale: 1.05 }}>
              <Icon
                glyph={<LinguaLine />}
                accent={ACCENTS.lingua}
                className={styles['api-icon']}
              />
            </Tilt>
          </div>
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Open source */}
    <Section
      theme="dark"
      accent={ACCENTS.developers}
      id={__openSource.hash}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="section"
            caption="Open source"
            title="Check out our open source projects to help in your AI/ML pipelines"
          />
        </ColumnLayout>
        <ColumnLayout columns="1,1,1,1">
          <Copy
            variant="detail"
            title="Magnitude"
            body={(
              <p>
                A fast, simple vector embedding utility library
                to quickly load and use vector embeddings in ML
                models.
              </p>
            )}
            footer={(
              <CTAButton
                text="View on GitHub"
                variant="link"
                to="https://github.com/plasticityai/magnitude"
              />
            )}
          />
          <Copy
            variant="detail"
            title="SuperSQLite"
            body={(
              <p>
                A supercharged SQLite library for Python. Drop-in
                replacement that offers remote streaming, full text
                search, and more.
              </p>
            )}
            footer={(
              <CTAButton
                text="View on GitHub"
                variant="link"
                to="https://github.com/plasticityai/supersqlite"
              />
            )}
          />
          <Copy
            variant="detail"
            title="Coldbrew"
            body={(
              <p>
                Run Python in JavaScript. Coldbrew is Python compiled
                into JavaScript using Emscripten and WebAssembly.
              </p>
            )}
            footer={(
              <CTAButton
                text="View on GitHub"
                variant="link"
                to="https://github.com/plasticityai/coldbrew"
              />
            )}
          />
          <Copy
            variant="detail"
            title="Plasticity Python"
            body={(
              <p>
                A Python module for using the Plasticity API, making
                the initial set up easy.
              </p>
            )}
            footer={(
              <CTAButton
                text="View on GitHub"
                variant="link"
                to="https://github.com/plasticityai/plasticity-python"
              />
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>

    {/* Get started */}
    <Section
      theme="white"
      accent={ACCENTS.developers}
    >
      <RowLayout>
        <ColumnLayout
          columns="2,2"
          align="center"
        >
          <Copy
            variant="section"
            title="Getting started is easy"
            body={(
              <p>
                Add natural language understanding to your application
                in just a few minutes by signing up for a developer
                API account today. Schedule a demo with our sales team
                to discuss your specific needs and use cases&mdash;we&apos;re
                here to help.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Request access"
                  variant="button"
                  to={__scheduleDemo.path}
                />
                <CTAButton
                  text="Try it out"
                  variant="link"
                  to={__demo.path}
                />
              </>
            )}
          />
          <LazyImage
            src={`${imagesFolder}/platforms.png`}
            placeholderSrc={`${imagesFolder}/platforms_low.png`}
            alt="Plasticity developer platforms"
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default Developers;
