import React from 'react';

import Card from 'components/Card';
import SentenceFactVisualizer from 'components/SentenceFactVisualizer';
import { Circles } from 'components/Backgrounds';

import styles from './styles.module.scss';

const SPANS = [
  'You', // 0
  ' ', // 1
  'can join', // 2
  ' ', // 3
  'a class', // 4
  ' ', // 5
  'to receive', // 6
  ' ', // 7
  'Remind messages', // 8
  ' ', // 9
  'via', // 10
  ' ', // 11
  'text', // 12
  ',', // 13
  ' ', // 14
  'email', // 15
  ',', // 16
  ' ', // 17
  'or', // 18
  ' ', // 19
  'push notification', // 20
  '.', // 21
];

const FACTS = {
  'You can join a class': [0, 2, 4],
  'You can join a class to receive Remind messages': [0, 2, 4, 6, 8],
  'You can join a class to receive Remind messages via text': [0, 2, 4, 6, 8, 10, 12],
  'You can join a class to receive Remind messages via email': [0, 2, 4, 6, 8, 10, 15],
  'You can join a class to receive Remind messages via push notification': [0, 2, 4, 6, 8, 10, 20],
};

const CommercialHero = () => (
  <div className={styles.container}>
    <Card className={styles.hero}>
      <SentenceFactVisualizer
        className={styles.visualizer}
        facts={FACTS}
        spans={SPANS}
      />
    </Card>
    <Circles className={styles.background} />
  </div>
);

export default CommercialHero;
