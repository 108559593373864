import React from 'react';

import Card from 'components/Card';
import CodeBlock from 'components/CodeBlock';
import { HexagonCircle } from 'components/Backgrounds';

import styles from './styles.module.scss';

const DevelopersHero = () => (
  <div className={styles.container}>
    <HexagonCircle className={styles.background} />
    <Card>
      <CodeBlock
        language="python"
        code={
`from plasticity import Plasticity

plasticity = Plasticity()

result = plasticity.sapien.core.post("""
Play let it be by The Beatles.
""", ner=True)

for sentence_group in result.data:
for sentence in sentence_group.alternatives:
    print(sentence.graph)`
          }
      />
    </Card>
  </div>
);

export default DevelopersHero;
