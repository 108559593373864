import React from 'react';

import styles from './styles.module.scss';

const DefaultValue = () => (
  <span className={styles.default}>
    (default)
  </span>
);

export default DefaultValue;
