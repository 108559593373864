import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './styles.module.scss';

const Slider = ({ children }) => (
  <div className={styles.slider}>
    <div className={styles['slider-inner']}>
      <SlickSlider
        dots
        infinite
        slidesToShow={3}
        slidesToScroll={3}
      >
        {children}
      </SlickSlider>
    </div>
  </div>
);

Slider.defaultProps = {
  children: null,
};

Slider.propTypes = {
  children: PropTypes.node,
};

export default Slider;
