import React from 'react';

import styles from './styles.module.scss';

const Gradient = (props) => (
  <svg
    viewBox="0 0 1152 700"
    preserveAspectRatio="xMidYMax slice"
    fill="none"
    {...props}
  >
    {/* <svg viewBox="0 0 1152 700" preserveAspectRatio="none" fill="none"> */}
    <g clipPath="url(#prefix__clip0)">
      <path
        className={styles.fill}
        d="M0 0h1152v700H0z"
      />
      <path
        transform="rotate(54.986 373.546 -481)"
        fill="url(#prefix__paint0_radial)"
        d="M373.546-481h1322.24V526.98H373.546z"
      />
      <path
        d="M-535.589-366L552.762 384.872-198.5 1032.5-1108 463.681-535.589-366z"
        fill="url(#prefix__paint1_radial)"
      />
      <path
        transform="rotate(-24.294 247 296.004)"
        fill="url(#prefix__paint2_radial)"
        d="M247 296.004h1322.24v1007.98H247z"
      />
      <path
        d="M363.597-457l577.15 806.822L459 1440.5-431.823 111.993 363.597-457z"
        fill="url(#prefix__paint3_radial)"
      />
      <path
        d="M-20.201 615.771L759.04 1.893 1437.3 672.771 585 1384-20.202 615.771z"
        fill="url(#prefix__paint4_radial)"
      />
      <path
        d="M-154 268.671l828.221-652.462 720.889 713.045-905.869 755.936L-154 268.671z"
        fill="url(#prefix__paint5_radial)"
      />
      <path
        d="M-938 429.5L247.302-52l563.524 835.743-1096.302 739.217L-938 429.5z"
        fill="url(#prefix__paint6_radial)"
      />
      <path
        d="M0 0h1152v133H0z"
        fill="url(#prefix__paint7_linear)"
      />
    </g>
    <defs>
      <radialGradient
        id="prefix__paint0_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 503.99 -661.12 0 1034.67 22.99)"
      >
        <stop className={styles.stop3} />
        <stop
          offset={1}
          className={styles.stop3}
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        id="prefix__paint1_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-286.20205 414.8425 -544.17799 -375.43128 -277.619 424.276)"
      >
        <stop className={styles.stop3} />
        <stop
          offset={1}
          className={styles.stop3}
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        id="prefix__paint2_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 503.99 -661.12 0 908.12 799.995)"
      >
        <stop className={styles.stop2} />
        <stop
          offset={1}
          className={styles.stop2}
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        id="prefix__paint3_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(144.422 126.76 311.858) scale(513.19 799.399)"
      >
        <stop className={styles.stop2} />
        <stop
          offset={1}
          className={styles.stop2}
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        id="prefix__paint4_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-105.12739 289.87363 -451.53834 -163.75772 834 605)"
      >
        <stop className={styles.stop4} />
        <stop
          offset={1}
          className={styles.stop4}
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        id="prefix__paint5_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-111.7351 308.09344 -479.9186 -174.05028 753.892 257.224)"
      >
        <stop className={styles.stop3} />
        <stop
          offset={1}
          className={styles.stop3}
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        id="prefix__paint6_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(278.6755 257.34984 -337.58327 365.55759 -16 896)"
      >
        <stop className={styles.stop1} />
        <stop
          offset={1}
          className={styles.stop1}
          stopOpacity={0}
        />
      </radialGradient>
      {/* <linearGradient
          id="prefix__paint7_linear"
          x1={576}
          y1={0}
          x2={576}
          y2={133}
          gradientUnits="userSpaceOnUse"
        >
          <stop className={styles.theme} stopOpacity={1} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient> */}
      <clipPath id="prefix__clip0">
        <path
          fill="#fff"
          d="M0 0h1152v700H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Gradient;
