import React from 'react';
import PropTypes from 'prop-types';

import FieldRow from 'components/Form/FieldRow';
import ButtonRow from 'components/Form/ButtonRow';
import ErrorRow from 'components/Form/ErrorRow';
import SuccessRow from 'components/Form/SuccessRow';

import styles from './styles.module.scss';

const Form = ({ children, ...rest }) => (
  <form
    className={styles.form}
    {...rest}
  >
    {children}
  </form>
);

Form.defaultProps = {
  children: null,
};

Form.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object, // eslint-disable-line react/require-default-props
};

Form.FieldRow = FieldRow;
Form.ButtonRow = ButtonRow;
Form.ErrorRow = ErrorRow;
Form.SuccessRow = SuccessRow;
export default Form;
