import React from 'react';

import TitleHelmet from 'components/Helmets/TitleHelmet';
import NotFoundHelmet from 'components/Helmets/NotFoundHelmet';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';
import Section from 'components/Section';
import RowLayout from 'components/RowLayout';
import ColumnLayout from 'components/ColumnLayout';
import Copy from 'components/Copy';
import CTAButton from 'components/CTAButton';

import { FLAVORS, ACCENTS } from 'constants/themes';
import {
  __,
  __contactUs,
} from 'constants/routes';

const NOT_FOUND_HELMET = <NotFoundHelmet />;
const TITLE_HELMET = <TitleHelmet title="404 · Plasticity NLP APIs" />;

const NotFound = () => (
  <>
    {NOT_FOUND_HELMET}
    {TITLE_HELMET}
    <SiteHeader
      theme="light"
      accent={ACCENTS.home}
    />
    <Section
      offset="lower"
      theme="light"
      flavor={FLAVORS.home}
      accent={ACCENTS.home}
    >
      <RowLayout>
        <ColumnLayout columns="3,1">
          <Copy
            variant="hero"
            caption="404"
            title="Page not found"
            body={(
              <p>
                Oops! It looks like the page you&apos;re looking for
                doesn&apos;t exist. If you believe this is an error, please
                contact us.
              </p>
            )}
            footer={(
              <>
                <CTAButton
                  text="Go home"
                  variant="button"
                  to={__.path}
                />
                <CTAButton
                  text="Contact us"
                  variant="link"
                  to={__contactUs.path}
                />
              </>
            )}
          />
        </ColumnLayout>
      </RowLayout>
    </Section>
    <SiteFooter theme="dark" />
  </>
);

export default NotFound;
