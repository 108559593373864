import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight } from 'react-icons/fi';

import styles from './styles.module.scss';

const Copy = ({
  variant, useAccent, icon, caption, title, body, footer, className,
  captionClassName, titleClassName, bodyClassName, footerClassName,
}) => (
  <section
    className={[
      styles.copy,
      styles[variant],
      useAccent ? styles.accent : '',
      className,
    ].join(' ')}
  >
    {(caption || title)
        && (
        <header className={styles.header}>
          {icon
            && (
            <div className={styles.icon}>
              {icon}
            </div>
            )}
          {caption
            && (
            <h2
              className={[
                styles.caption,
                captionClassName,
              ].join(' ')}
            >
              {caption}
            </h2>
            )}
          {variant === 'detail'
            && <FiChevronRight className={styles.chevron} />}
          {title
            && (
            <h1
              className={[
                styles.title,
                titleClassName,
              ].join(' ')}
            >
              {title}
            </h1>
            )}
        </header>
        )}
    {body
        && (
        <div
          className={[
            styles.body,
            bodyClassName,
          ].join(' ')}
        >
          {body}
        </div>
        )}
    {footer
        && (
        <footer
          className={[
            styles.footer,
            footerClassName,
          ].join(' ')}
        >
          {footer}
        </footer>
        )}
  </section>
);

Copy.defaultProps = {
  variant: 'section',
  useAccent: false,
  icon: null,
  caption: null,
  title: null,
  body: null,
  footer: null,
  className: '',
  captionClassName: '',
  titleClassName: '',
  bodyClassName: '',
  footerClassName: '',
};

Copy.propTypes = {
  variant: PropTypes.oneOf(['section', 'subsection', 'detail', 'hero']),
  useAccent: PropTypes.bool,
  icon: PropTypes.node,
  caption: PropTypes.node,
  title: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  className: PropTypes.string,
  captionClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
};

export default Copy;
